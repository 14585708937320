import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import _endpoints from '../../../../app/constant/endpoints/_endpoints';
import {
  TabBox,
  TabsBoxs,
} from '../../../../components/molecules/tab-box/TabBox';
import AllRequests_Details from './tabs/all-request-tab/AllRequests_Details';
import appConstants from '../../../../app/constant/constants/appConstants';

const AllRequests = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [query, setQuery] = useState(null);

  const handleTabChange = (tab) => {
    navigate('/all-requests', {
      state: { type: tab },
    });
  };

  useEffect(() => {
    if (location.state) setQuery(location.state);
  }, [location.state]);
  return (
    <TabsBoxs
      defaultActiveTab={location.state?.type ?? 0}
      onTabChange={handleTabChange}
    >
      <TabBox label="Assigned to me" type={appConstants.TYPE.ATTENTION}>
        <AllRequests_Details
          api={_endpoints.request.getMyRequests}
          type={0}
          locationState={location?.state}
          query={query}
          setQuery={setQuery}
        />
      </TabBox>
      <TabBox label="All requests" type={appConstants.TYPE.REQUEST}>
        <AllRequests_Details
          api={_endpoints.request.getRequestByType}
          type={1}
          locationState={location?.state}
          query={query}
          setQuery={setQuery}
        />
      </TabBox>
    </TabsBoxs>
  );
};

export default AllRequests;
