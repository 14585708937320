import React, { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import styles from './Restrictions.module.scss';
import { getSvgByType } from '../../../../../../../../app/helpers/forms/GetSvgByType';
import appConstants from '../../../../../../../../app/constant/constants/appConstants';
import RestrictionsDetailsForm from './restrictions-details-form/RestrictionsDetailsForm';
import NewTable from '../../../../../../../molecules/new-table/NewTable';
import _endpoints from '../../../../../../../../app/constant/endpoints/_endpoints';
import { usePostMutation } from '../../../../../../../../services/apiCall';
import {
  errorMessage,
  successMessage,
} from '../../../../../../../hoc/toast/Toast';

import Checkbox from '../../../../../../../atoms/forms/checkbox/Checkbox';
import Loader from '../../../../../../../molecules/table/atoms/loader/Loader';

const postApi = _endpoints.restrictions.inOutRequest;

const Restrictions = ({
  data,
  items,
  refetchData,
  isLoading,
  label,
  isCredit,
  isTerms,
}) => {
  let tableData = null;
  const [post] = usePostMutation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formType, setFormType] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const [isAllowed, setIsAllowed] = useState(items?.in_Out ?? false);
  const [toggleStates, setToggleStates] = useState({
    in: false,
    out: false,
    condition: false,
  });
  if (isCredit && !isTerms) {
    tableData = {
      ...data,
      tableView: data.tableCreditView,
    };
  } else if (isCredit && isTerms) {
    tableData = {
      ...data,
      tableView: data.tableTermCreditView,
    };
  } else {
    tableData = data;
  }

  useEffect(() => {
    if (items?.in_Out !== undefined && items?.in_Out !== null) {
      setToggleStates({
        in: items.in_Out === 1,
        out: items.in_Out === 0,
        condition: items.in_Out === 2,
      });
    }
  }, [items]);

  const handleModalOpen = (item = null, type = 'create') => {
    setRowData(item);
    setFormType(type);
    setIsModalOpen(true);
  };
  const onSuccess = () => {
    setRefetch(!refetch);
    setIsModalOpen(false);

    if (refetchData) {
      refetchData();
    }
  };

  const fetchRowData = (item, type) => {
    setRowData(item);
    setFormType(type);
  };

  const handleModal = (type) => {
    setFormType(type);
    setIsModalOpen(!isModalOpen);
    setRowData(null);
  };

  const debouncedHandleToggleChange = useCallback(
    debounce(async (toggleState) => {
      setIsPosting(true);
      try {
        const result = await post({
          apiUrl: `${postApi}?DefinitionId=${items?.id}&inout=${toggleState}`,
        });

        if (result.error) {
          errorMessage('Error while updating restriction');
          refetchData();
          setIsPosting(false);
        } else {
          successMessage('Restriction updated successfully');
          refetchData();
          setIsPosting(false);
        }
      } catch (error) {
        setIsPosting(false);

        refetchData();
        errorMessage('Error while updating restriction');
      }
    }, 500),
    [post, items?.id, refetchData]
  );
  const handleCheckboxChange = (key) => {
    const updatedStates = {
      in: false,
      out: false,
      condition: false,
      [key]: !toggleStates[key],
    };
    setToggleStates(updatedStates);
    const selectedState = Object.keys(updatedStates).find(
      (state) => updatedStates[state] === true
    );
    const toggleStateValue =
      selectedState === 'in' ? 1 : selectedState === 'out' ? 0 : 2;
    debouncedHandleToggleChange(toggleStateValue);
  };

  return (
    <div className={styles['restrictions']}>
      {isPosting ? <Loader backdrop /> : null}
      <div className={styles['attention-container']}>
        <div className={styles['checkboxes']}>
          <Checkbox
            label="In"
            ischecked={toggleStates.in}
            onChange={() => handleCheckboxChange('in')}
            isDisabled={isPosting}
          />
          <Checkbox
            label="Out"
            ischecked={toggleStates.out}
            onChange={() => handleCheckboxChange('out')}
            isDisabled={isPosting}
          />
          <Checkbox
            label="Condition"
            ischecked={toggleStates.condition}
            onChange={() => handleCheckboxChange('condition')}
            isDisabled={isPosting}
          />
        </div>

        <div className={styles['attention']}>
          <img src={getSvgByType(appConstants.TYPE.ATTENTION)} alt="" />
          {toggleStates?.in ? (
            <p>
              Allow Students only if their {label} meets the following
              conditions
            </p>
          ) : toggleStates?.out ? (
            <p>
              Restrict Students only if their {label} meets the following
              conditions
            </p>
          ) : (
            <p>Allows students if condition applies</p>
          )}
        </div>
      </div>
      <NewTable
        data={tableData}
        filters={{ hasSearch: true }}
        form={
          <RestrictionsDetailsForm
            isModalOpen={isModalOpen}
            setIsModalOpen={handleModalOpen}
            handleClose={() => setIsModalOpen(false)}
            onSuccess={onSuccess}
            data={rowData}
            type={formType}
            items={items}
            isCredit={isCredit}
            isTerm={isTerms}
          />
        }
        isLoading={isLoading}
        isModalOpen={isModalOpen}
        refetch={refetch}
        setIsModalOpen={() => handleModal('create')}
        actions={{
          hasCreate: {},
          hasDelete: {},
        }}
        deleteApi={_endpoints.restrictions.deleteRestricationDetail}
        onDeleteSuccess={refetchData}
        modalConfig={{
          buttonLabel: 'add new',
          modalTitle:
            formType === 'edit'
              ? `${items?.restrictionType.restrictionName}`
              : `add ${items?.restrictionType.restrictionName}`,
        }}
      />
    </div>
  );
};

export default Restrictions;
