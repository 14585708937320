import React, { useState } from 'react';
import Container from '../../../hoc/container/Container';
import NewTable from '../../../molecules/new-table/NewTable';
import _endpoints from '../../../../app/constant/endpoints/_endpoints';
import RequestHistoryPopup from './factory/export-form/RequestHistoryPopup';
import Modal from '../../../hoc/modal/Modal';
import styles from './RequestHistory_Temp.module.scss';
import PendingRequestPopup from '../../../../features/authenticated/students/pending-requests/factory/PendingRequestPopup';
const RequestHistory_TEMP = () => {
  const [rowData, setRowData] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleRowClick = (row) => {
    setIsOpenModal(true);
    setRowData(row);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setRowData({});
  };

  return (
    <>
      <Container>
        <NewTable
          api={_endpoints.student.getRequestHistory}
          rowKey="id"
          onRowClick={handleRowClick}
        />
      </Container>
      <Modal
        onClose={() => setIsOpenModal(false)}
        isOpen={isOpenModal}
        title={rowData?.applicationType}
        withoutLine
        classname={styles.modal}
      >
        <PendingRequestPopup data={rowData} />
      </Modal>
    </>
  );
};

export default RequestHistory_TEMP;
