import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { successMessage } from '../../../../hoc/toast/Toast';
import { usePostMutation } from '../../../../../services/apiCall';
import _endpoints from '../../../../../app/constant/endpoints/_endpoints';
import { useDispatch } from 'react-redux';
import { updateUserDetails } from '../../../../../app/globals/storeSlices/userSlice';

const apiUrl = _endpoints.users.postUser;

const validationSchema = Yup.object({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
});

const useForm = ({ onSuccess, user, type }) => {
  const dispatch = useDispatch();

  const [key, setKey] = useState(0);
  const [post, { isLoading, isSuccess }] = usePostMutation();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (type === 'create') delete values.userId;

    try {
      await post({ apiUrl, data: values }).unwrap();
      resetForm();
      setKey((prev) => prev + 1);

      successMessage('User details updated successfully!');
      if (onSuccess) onSuccess();
      dispatch(
        updateUserDetails({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
        })
      );
    } catch (error) {
      // console.error('Submission error:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      userId: user?.userId,
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
    },
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return { isLoading, isSuccess, formik, key };
};

export default useForm;
