import React, { useEffect, useState } from 'react';
import styles from './EmailTemplate.module.scss';
import Table from '../../../molecules/table/Table';
import _endpoints from '../../../../app/constant/endpoints/_endpoints';
import Button from '../../../atoms/forms/button/Button';
import appConstants from '../../../../app/constant/constants/appConstants';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../../../../app/constant/routes/Routes';
import { useDispatch } from 'react-redux';
import { setTitle } from '../../../../app/globals/pageTitleSlice';
import NewTable from '../../../molecules/new-table/NewTable';

const api = _endpoints.notification.getNotifications;

const EmailTemplate = ({ locationState, setQuery, query }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [localQuery, setLocalQuery] = useState();
  const handleNewTempClick = (item) => {
    dispatch(setTitle({ isBack: true, title: 'email template' }));
    if (item?.notificationId)
      navigate(`${routes.REGISTRAR.EMAIL_TEMPLATE}/${item.notificationId}`, {
        state: {
          query,
        },
      });
    else
      navigate(routes.REGISTRAR.EMAIL_TEMPLATE, {
        state: {
          query,
        },
      });
  };
  useEffect(() => {
    setLocalQuery(locationState?.query);
  }, [locationState]);

  const getQuery = (queryState) => {
    setQuery(queryState);
  };

  return (
    <div>
      <NewTable
        api={api}
        rowKey="notificationId"
        rightContent={
          <Button
            variant={appConstants.VARIANT.DANGER}
            label={appConstants.LABEL.NEW_TEMPLATE}
            type={appConstants.TYPE.ADD}
            className={styles.button}
            onClick={handleNewTempClick}
          />
        }
        actions={{
          hasEdit: {
            func: (item) => {
              handleNewTempClick(item);
            },
          },
          hasDelete: {},
        }}
        deleteApi={_endpoints.email.deleteEmailTemp}
        deleteMethod="del"
        initialQuery={localQuery}
        getQuery={getQuery}
      />
    </div>
  );
};

export default EmailTemplate;
