export default {
  INPUT: {
    DEFAULT_PLACEHOLDER: 'enter value',
  },
  PLACEHOLDER: {
    ENTER_NAME: 'enter name',
    ENTER_RESTRICTION_NAME: 'enter restriction name',
    ENTER_ATTACHMENT: 'enter attachment',
    SELECT_SIGNATURE: 'select e-signature',
    ENTER_EMAIL_NAME: 'enter email name',
    DEFAULT_PLACEHOLDER: 'select an option',
    SELECT_APPEAL: 'select appeal',
    SELECT_MOBILE_NUMBER: 'select mobile number',
    SELECT_TERM: 'select term',
    MOBILE_NUMBER: 'enter mobile number',
    SPECIFY_OTHER_REASON: 'please specify reasons',
    ENTER_COMMENT: 'Enter comment',
    ENTER_CODE: 'Enter code',
    ENTER_REASON: 'Enter reason',
    ENTER_PASSWORD: 'Enter password',
    ENTER_EMAIL: 'Enter email',
    ENTER_ID: 'Enter id',
    NAME: 'name',
    EMAIL: 'email',
    PASSWORD: 'password',
    ENTER_SEQUENCE: 'Enter sequence',
    ENTER_REJECTED_REQUEST_LOG: 'Enter rejected request log',
    SELECT_APPROVER: 'select approver',
    SELECT_DATE: 'select date',
    SELECT_APPLICATION: 'select application',
    EMAIL_TYPE: 'select email type',
    SELECT_RECIPIENT: 'select recipient',
    ENTER_SUBJECT: 'Enter subject',
    ENTER_CONTENT: 'Enter content',
    REASON_FOR_GRADE_APPEAL: 'reason for grade appeal',
    SELECT_TRANSFER: 'select transfer',
    SELECT_ROLE: 'select role',
    ENTER_DEPARTMENT: 'Enter department',
    SEARCH: 'search',
    FIRST_NAME: 'first name',
    LAST_NAME: 'last name',
    MIDDLE_NAME: 'middle name',
    ENTER_ROLE: 'Enter role',
  },
  DATE: {
    DEFAULT_PLACEHOLDER: 'select date',
  },
  LABEL: {
    STUDENT_ID: 'student id',
    ADD_NEW: 'add new',
    NEW_RESTRICTION: 'new restriction',
    RESTRICTION_NAME: 'restriction name',
    RESTRICTIONS: 'restrictions',
    EMAIL_SIGNATURE: 'EMAIL signature',
    SPECIAL_APPROVAL: 'special approval',
    ATTACHMENT: 'attachment',
    USERNAME: 'username',
    PASSWORD: 'password',
    EMAIL: 'email',
    ALL_REQUESTS: 'all requests',
    ALL_APPLICATIONS: 'all applications',
    ADMIN_PANEL: 'admin panel',
    REQUEST_HISTORY: 'request history',
    PENDING_REQUESTS: 'Request',
    APPLICATION: 'application',
    APPEAL_TERM: 'appeal term',
    NATURE_OF_APPEAL: 'nature of appeal',
    MOBILE: 'mobile',
    UPLOAD_FILE: 'upload file',
    SAMPLE_LETTER: 'sample letter',
    SUBMIT: 'submit',
    OTHER_REASON: 'other reasons',
    COMMENT: 'comment',
    SEARCH: 'search',
    COURSE_CODE: 'course code',
    REASON_COURSE_OVERLOAD: 'reason for course overload',
    USERS: 'USERS',
    APPROVER_FLOW: 'approval flow',
    TERMS: 'terms',
    TERM: 'term',
    PROGRAM_VERSIONS: 'program versions',
    COURSES: 'COURSES',
    STUDENT_GROUP: 'student group',
    TIME_EMAIL: 'email',
    REPLACE_INSTRUCTOR: 'replace instructor',
    NEW_USER: 'new user',
    NAME: 'name',
    NEW_APPROVER: 'new approver',
    APPROVER_SEQUENCE: 'approver sequence',
    APPROVER_ROLE: 'approver role',
    REJECTED_REQUEST_LOG: 'rejected request log',
    SETUP_TERM: 'setup term',
    NEW_COURSE: 'new course',
    EMAIL_TYPE: 'email type',
    TO: 'to',
    SUBJECT: 'subject',
    EMAIL_CONTENT: 'email content',
    EXPORT: 'export',
    REASON_FOR_GRADE_APPEAL: 'reason for grade appeal',
    ALL_APPLICATIONS_LOG: 'all applications log',
    Emails_LOG: 'Emails log',
    VERIFY: 'verify',
    REJECT_REQUEST: 'reject request',
    REJECT: 'reject',
    TRANSFER_REQUEST_TO: 'transfer request to',
    EDIT: 'edit',
    ADD: 'add',
    DELETE: 'delete',
    REPLACE_FILE: 'replace  file',
    ROLE: 'role',
    ROLES: 'roles',
    PRINT: 'print',
    FIRST_NAME: 'first name',
    LAST_NAME: 'last name',
    MIDDLE_NAME: 'middle name',
    NEW_ROLE: 'new role',
    ROLE_NAME: 'role name',
    SETTINGS: 'settings',
    NEXT: 'next',
    EMAIL_TEMPLATE: 'email template',
    HISTORY: 'history',
    SCHEDULED: 'scheduled',
    NEW_TEMPLATE: 'new template',
    SEND_EMAIL: 'send email',
  },
  TYPE: {
    SIGN: 'sign',
    SIGNOUT: 'signOut',
    REASON: 'reason',
    BOARD: 'board',
    RESTRICTIONS: 'restrictions',
    USER_CHECK: 'USER_CHECK',
    SEND: 'send',
    DATE: 'date',
    CALENDAR: 'calendar',
    EMAIL: 'email',
    PASSWORD: 'password',
    ALL_APPLICATIONS: 'ALL_APPLICATIONS',
    ALL_REQUESTS: 'all-requests',
    ADMIN_PANEL: 'ADMIN_PANEL',
    REQUEST_HISTORY: 'request-history',
    SEARCH: 'search',
    EXCLAMATION_MARK: 'EXCLAMATION_MARK',
    FOLDER: 'FOLDER',
    GRADUATIONHAT: 'graduation-hat',
    EMAILMESSAGE: 'email-message',
    DEGREE: 'degree',
    PHONE: 'phone',
    CAMPUS: 'campus',
    APPLICATION: 'application',
    MOBILE: 'mobile',
    APPEAL: 'appeal',
    ATTACHMENTS: 'attachments',
    ATTACHMENTS_PAPER: 'attachment-paper',
    SAMPLE_LETTER: 'sample letter',
    CHECK: 'check',
    DOCS: 'docs',
    TEXT_AREA: 'textarea',
    NOTE: 'note',
    FILTER_ARROW: 'filter-arrow',
    DOWN_ARROW: 'down-arrow',
    USERS: 'USERS',
    APPROVER_FLOW: 'APPROVER_FLOW',
    TERMS: 'TERMS',
    PROGRAM_VERSIONS: 'PROGRAM_VERSIONS',
    COURSES: 'COURSES',
    STUDENT_GROUP: 'STUDENT_GROUP',
    TIME_EMAIL: 'TIME_EMAIL',
    REPLACE_INSTRUCTOR: 'REPLACE_INSTRUCTOR',
    RIGHT_LEFT_ARROW: 'right-left-arrow',
    BURGER_MENU: 'berger-menu',
    ADD: 'add',
    DEPARTMENT: 'department',
    SAVE: 'save',
    CANCEL: 'cancel',
    DELETE: 'delete',
    EDIT: 'EDIT',
    VIEW: 'view',
    EDIT_PROFILE: 'edit profile',
    APPROVER_ROLE: 'approver role',
    REQUEST_LOG: 'Request log',
    FILE: 'file',
    PROFILE: 'profile',
    SUBJECT: 'subject',
    EXPORT: 'export',
    redX: 'redx',
    THIN_ARROW: 'thin-arrow',
    PROFILE_MALE: 'profile-male',
    PROFILE_FEMALE: 'profile-female',
    THREE_DOTS: 'three-dots',
    THUMB_DOWN: 'thumb-down',
    FILTER: 'filter',
    REC: 'rec',
    THREE_DOTS_SQUARED: 'three-dots',
    COL_VISIBILITY: 'col-visibility',
    ROLE_TAB: 'role-tab',
    USER_TAB: 'user-tab',
    EXIT: 'exit',
    PRINT: 'print',
    ROLE: 'role',
    SETTINGS: 'SETTINGS',
    PLUS_SIGN: 'plus-sign',
    MAIL_EMPTY: 'mail-empty',
    SCHEDULED: 'scheduled',
    SIGNITURE: 'signiture',
    CALENDAR_EMPTY: 'calendar-empty',
    SPECIAL_APPROVAL: 'SPECIAL_APPROVAL',
    BACKBUTTON: 'back-button',
    ATTENTION: 'attention',
    ENUM: 'enum',
    BACK_ARROW: 'back-arrow',
    SEND_EMAIL: 'send-email',
    REQUEST: 'REQUEST',
  },
  VARIANT: {
    MD: 'md',
    DANGER: 'danger',
    DEFAULT: 'default',
    SMALL: 'small',
  },
  RULES_RGULATION: {
    TITLE: 'rules & regulations',
  },
  APPLICATIONS_LIST: {
    ACADEMIC_STANDING_APPEAL: 'Academic Standing Appeal',
    COURSE_INCOMPLETE: 'Course Incomplete',
    GRADE_CHANGE_REMOVAL_OF_I_GRADE: 'Grade Change/Removal of I Grade',
    COURSE_WITHDRAWAL: 'Course Withdrawal',
    GRADE_APPEAL: 'Grade Appeal',
    COURSE_WAITLIST: 'Course Waitlist',
    COURSE_OVERLOAD: 'Course Overload',
  },
  APPEAL_EXPORT_FORM: {
    TITLE:
      'Do you consider that the appeal is resolved, You cannot make any changes further',
  },
  NOTIFY_ALL_PREVIOUS_APPROVERS: 'notify all previous approvers',
  NOTIFY_NEXT_APPROVER: 'notify next approver',
  EMAIL_STUDENT: 'email student',
  SELECT_TEMPLATE: 'select template',
  LOG: 'log',
  ENTER_LOG_TEXT: 'Enter log text',
  reasons: 'reasons',
  CREATE_APPROVER: 'create approver',
  EDIT_APPROVER: 'edit approver',
  NEW_APPROVER: 'new approver',
  CREATE_ROLE: 'create role',
  EDIT_ROLE: 'edit role',
  CREATE_NEW_ROLE: 'create new role',
  LOADING: 'loading ...',
  NO_DATA: 'no data found',
  DEFAULT_EMPTY_STATE: 'no data found',
  ASSIGN_ROLE: 'assign role',
  REQUEST_LOG: 'Request log',
  PREVIOUS_APPEAL: 'previous appeal',
  DOCUMENTS: 'documents',
  SEND_EMAIL: 'send email',
  APPEAL_STATUS: 'appeal status',
  PENDING_REQUEST: 'pending request',
  ATTACHMENTS: 'attachments',
  RE_APPLY: 're-apply',
  REPLY: 'reply',
  APPEAL_RESOLVED: 'appeal resolved',
  APPEAL: 'appeal',
  SETUP_TERM: 'setup term',
  ADD_NEW_TERM: 'add new term',
  APPROVED_REQUEST_LOG: 'approved request log',
  SAVE: 'save',
  DEPARTMENT: 'department',
  ADD_NEW_USER: 'add new user',
  ADD_USER: 'add user',
  EDIT_USER: 'edit user',
  STATUS: 'status',
  GRADE_APPEAL: 'grade appeal',
  REASON_FOR: 'reason for',
  COURSE_WAITLIST: 'course waitlist',
  COURSE_OVERLOAD: 'course overload',
  ATTACH_DOC: 'attach supporting documents',
  ATTACH_DOC_LIMIT: 'supported: pdf, jpg, tiff, doc & docx Max size: 4.5MB',
  COURSE_SELECTION: 'course selection',
  CHOOSE_COURSE: 'choose course',
  APPLICATION: 'application',
  SCHOLARSHIP: 'Scholarship',
  STUDENT_YEAR: 'Student Year',
  DUMMY_NAME: 'Mariam Al-Zahabi',
  CREDIt_EARNED: 'Credit Earned',
  GPA: 'GPA',
  COURSE_WITHDRAWAL: 'course withdrawal',
  COURSE_INCOMPLETE: 'course incomplete',

  SEARCH_APPLICATION: 'search application',
  CANCEL: 'cancel',
  I_AGREE: 'i agree',
  APPLY_NOW: 'apply now',
  DEFAULT_SVG_ALT: 'default svg alt',
  AUD_PROFILE_IMAGE: 'aud profile image',
  ACADEMIC_STANDING_APPEAL: 'academic standing appeal',
  AUD_LOGO: 'adu logo',
  WELCOME_TO_UNIPORTAL: 'Welcome to Uniportal',
  FORGOT_PASSWORD_Q: 'forgot password ?',
  HOME: 'home',
  EMAIL: 'email',
  LOGIN: 'login',
  Profile: 'My Profile',
  ALL_APPLICATIONS: {
    APPLY_FOR_ACADEMIC_STANDING_APPEAL: 'Apply for academic standing appeal',
  },
};
