import React from 'react';
import styles from './Tag.module.scss';

const colors = [
  { Status: 'Pending', Color: '#7F7F7F' },
  { Status: 'Verified By Registrar', Color: '#469E2B' },
  { Status: 'Verified By Instructor', Color: '#469E2B' },
  { Status: 'Verified By Dean', Color: '#469E2B' },
  { Status: 'Completed', Color: '#0167B1' },
  { Status: 'Resolved', Color: '#0167B1' },
  { Status: 'Canceled', Color: '#F3223C' },
  { Status: 'Rejected', Color: '#F3223C' },
];

const getStatusColor = (statusName) => {
  const normalizedStatusName = statusName?.trim()?.toLowerCase();
  const status = colors.find(
    (item) => item.Status?.trim().toLowerCase() === normalizedStatusName
  );
  return status ? status.Color : '#469E2B';
};

const getStatusVariant = (variant) => {
  const firstWord = variant?.split(' ')[0]?.toLowerCase();
  switch (firstWord) {
    case 'approved':
      return 'green';
    case 'waiting':
      return 'yellow';
    case 'cancelled':
      return 'red';
    case 'rejected':
      return 'red';
    case 'verifcation':
      return 'grey';
    case 'final':
      return 'blue';
    case 'evaluating':
      return 'blue';
    default:
      return 'default';
  }
};

const Tag = ({ label, variant, isColored }) => {
  const statusVariant = getStatusVariant(variant);
  const statusColor = getStatusColor(label);

  return (
    <span
      className={`${styles.tag} ${styles[statusVariant]}`}
      style={{ backgroundColor: `${statusColor}20`, color: statusColor }}
    >
      <span
        className={`${styles.circle} ${styles[`${statusVariant}Circle`]}`}
        style={{ backgroundColor: statusColor }}
      ></span>
      {label}
    </span>
  );
};

export default Tag;
