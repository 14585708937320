import React, { useState } from 'react';
import styles from '../Components.module.scss';
import Accordion from '../../../../../../components/molecules/accordion/Accordion';
import appConstants from '../../../../../../app/constant/constants/appConstants';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
import { errorMessage } from '../../../../../../components/hoc/toast/Toast';
import { useFormik } from 'formik';
import Container from '../../../../../../components/hoc/container/Container';
import TextArea from '../../../../../../components/atoms/forms/text-area/TextArea';
import Button from '../../../../../../components/atoms/forms/button/Button';
import SuccessModal from '../../../../../../components/hoc/success-modal/SuccessModals';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { usePostMutation } from '../../../../../../services/apiCall';
import objectToFormData from '../../../../../../app/helpers/forms/objectToFormData';
import Checkbox from '../../../../../../components/atoms/forms/checkbox/Checkbox';
import RadioGroup from '../../../../../../components/atoms/forms/radio-group/RadioGroup';
import NewTable from '../../../../../../components/molecules/new-table/NewTable';
const coveredOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];
const RegistrarForm = ({ data, finalProcess }) => {
  const navigate = useNavigate();
  const [post] = usePostMutation();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [actionType, setActionType] = useState('');

  const getValidationSchema = (agency) => {
    let baseSchema = {
      comment: Yup.string().required('Required'),
      scholarship: Yup.boolean(),
      agency: Yup.string(),
    };
    if (agency?.startsWith('SCH')) {
      baseSchema.scholarship = baseSchema.scholarship.required('Required');
      baseSchema.agency = baseSchema.agency.required('Required');
    } else {
      baseSchema.scholarship = baseSchema.scholarship.notRequired();
      baseSchema.agency = baseSchema.agency.notRequired();
    }

    return Yup.object().shape(baseSchema);
  };
  const formik = useFormik({
    initialValues: {
      comment: '',
      scholarship: '',
      agency: '',
    },
    validationSchema: getValidationSchema(data?.student?.agency),
    onSubmit: (values) => {
      if (actionType == 'approve') {
        handleApprove();
      } else if (actionType == 'reject') {
        setWarningModalOpen(true);
      }
    },
  });

  const handleApprove = async () => {
    setIsApproving(true);
    setActionType('approve');

    const payload = {
      RequestId: data?.request.id,
      Comments: formik.values.comment,
      ...(formik.values.scholarship && {
        ScholarshipVerified: formik.values.scholarship,
      }),
      ...(formik.values.agency && {
        CoveredByAgency: formik.values.agency == 'yes' ? true : false,
      }),
    };

    const formData = objectToFormData(payload);

    try {
      await post({
        apiUrl: _endpoints.approver.approveRequest,
        data: formData,
        type: 'formData',
      }).unwrap();
      setIsSuccessModalOpen(true);
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } catch (error) {
      errorMessage('Something went wrong!');
    } finally {
      setIsApproving(false);
    }
  };

  const handleRejection = async () => {
    setIsRejecting(true);
    setActionType('reject');

    try {
      await post({
        apiUrl: _endpoints.approver.rejectRequest,
        data: {
          requestId: data?.request.id,
          comments: formik.values.comment,
        },
      }).unwrap();
      setIsSuccessModalOpen(true);
      setWarningModalOpen(false);
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } catch (error) {
      errorMessage('Something went wrong!');
    } finally {
      setIsRejecting(false);
    }
  };

  const closeModal = () => {
    setIsSuccessModalOpen(false);
    setWarningModalOpen(false);
  };

  return (
    <>
      <div className={styles['application-container']}>
        <Container>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.validateForm().then(() => formik.handleSubmit());
            }}
            className={styles.form}
          >
            <TextArea
              label={appConstants.LABEL.COMMENT}
              placeholder={appConstants.PLACEHOLDER.ENTER_COMMENT}
              type={appConstants.TYPE.NOTE}
              name="comment"
              value={formik.values.comment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.comment}
              touched={formik.touched.comment}
            />
            {data?.student?.agency?.startsWith('SCH') && (
              <div className={styles['moreOptions_cont']}>
                <div>
                  <Checkbox
                    label="Scholarship Verified with Vendor"
                    onChange={(checked) => {
                      formik.setFieldValue('scholarship', checked);
                    }}
                    ischecked={formik.values.scholarship}
                  />
                  {formik.errors.scholarship && (
                    <p className={styles['error-message']}>
                      {formik.errors.scholarship}
                    </p>
                  )}
                </div>

                <RadioGroup
                  label="Course Overload Covered by Agency"
                  name="agency"
                  selectedValue={formik.values.agency}
                  onChange={(e) =>
                    formik.setFieldValue('agency', e.target.value)
                  }
                  error={formik.errors.agency}
                  options={coveredOptions}
                />
              </div>
            )}

            {/* <Select
              placeholder="Select Payment"
              label="Scholarship Payment"
              type={appConstants.TYPE.FOLDER}
              name="payment"
              value={formik.values.payment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.payment && formik.errors.payment}
            /> */}
            <div className={styles['button-container']}>
              <Button
                label="Process"
                onClick={() => {
                  setActionType('approve');
                  formik.validateForm().then(() => formik.handleSubmit());
                }}
                isLoading={isApproving}
              />
            </div>
          </form>
        </Container>
        <SuccessModal
          isOpen={isSuccessModalOpen}
          isSuccess
          text={
            actionType === 'approve' ? 'Request Approved' : 'Request Rejected'
          }
          timeout={1000}
          subText={
            actionType === 'approve'
              ? 'Request has been reviewed and given approval.'
              : 'Request has been reviewed and rejected.'
          }
        />
        <SuccessModal
          isOpen={isWarningModalOpen}
          text="Reject Request"
          subText="Are you sure you want to reject this request?"
          onClose={closeModal}
          onSubmit={handleRejection}
          onApproveButton={{ text: 'Reject' }}
          isLoading={isRejecting}
        />
      </div>
    </>
  );
};

export default RegistrarForm;
