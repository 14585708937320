import React from 'react';
import styles from '../NewSetupTermForm.module.scss';
import DateSelect from '../../../../../../../atoms/forms/select/date/DateSelect';
import * as Yup from 'yup';

export const form6ValidationSchema = Yup.object().shape({
  gradeChange_StartDate: Yup.date().required(
    'grade change start date is required'
  ),
});

export const form6InitialValues = {
  gradeChange_StartDate: '',
};
export const renderFieldsForId6 = (formik) => {
  const handleDateChange = (name, value) => {
    formik.setFieldValue(name, value);
  };

  return (
    <div className={styles['date-grid']}>
      <DateSelect
        label="Grade Change Start Date"
        type="date"
        placeholder="Select date"
        name="gradeChange_StartDate"
        onChange={handleDateChange}
        onBlur={formik.handleBlur}
        value={formik.values.gradeChange_StartDate}
        error={
          formik.touched.gradeChange_StartDate &&
          formik.errors.gradeChange_StartDate
        }
        touched={formik.touched.gradeChange_StartDate}
        classname={styles.date}
      />
    </div>
  );
};
