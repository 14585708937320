import React from 'react';
import styles from '../NewSetupTermForm.module.scss';
import DateSelect from '../../../../../../../atoms/forms/select/date/DateSelect';
import * as Yup from 'yup';

export const form5ValidationSchema = Yup.object().shape({
  asA_StartDate: Yup.date().required('ASA start date is required'),
  asA_EndDate: Yup.date()
    .required('ASA end date is required')
    .min(Yup.ref('asA_StartDate'), 'End date must be after start date'),
});

export const form5InitialValues = {
  asA_StartDate: '',
  asA_EndDate: '',
};

export const renderFieldsForId0 = (formik) => {
  const handleDateChange = (name, value) => {
    formik.setFieldValue(name, value);
  };
  return (
    <div className={styles['date-grid']}>
      <DateSelect
        label="start date"
        type="date"
        placeholder="Select date"
        name="asA_StartDate"
        onChange={handleDateChange}
        onBlur={formik.handleBlur}
        value={formik.values.asA_StartDate}
        error={formik.errors.asA_StartDate}
        touched={formik.touched.asA_StartDate}
        classname={styles.date}
      />
      <DateSelect
        label="end date"
        type="date"
        placeholder="Select date"
        name="asA_EndDate"
        onChange={handleDateChange}
        onBlur={formik.handleBlur}
        value={formik.values.asA_EndDate}
        error={formik.errors.asA_EndDate}
        touched={formik.touched.asA_EndDate}
        classname={styles.date}
        startDate={formik.values.asA_StartDate}
      />
    </div>
  );
};
