import React, { useState } from 'react';
import styles from '../Components.module.scss';
import Accordion from '../../../../../../components/molecules/accordion/Accordion';
import appConstants from '../../../../../../app/constant/constants/appConstants';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
import { errorMessage } from '../../../../../../components/hoc/toast/Toast';
import { useFormik } from 'formik';
import Container from '../../../../../../components/hoc/container/Container';
import SimpleTable from '../../../../../../components/molecules/simple-table/SimpleTable';
import TextArea from '../../../../../../components/atoms/forms/text-area/TextArea';
import Button from '../../../../../../components/atoms/forms/button/Button';
import SuccessModal from '../../../../../../components/hoc/success-modal/SuccessModals';
import * as Yup from 'yup';
import { getSvgByType } from '../../../../../../app/helpers/forms/GetSvgByType';
import Title from '../../../../../../components/atoms/title/Title';
import { useNavigate } from 'react-router-dom';
import { usePostMutation } from '../../../../../../services/apiCall';
import objectToFormData from '../../../../../../app/helpers/forms/objectToFormData';
import DocumentView from '../../../../../../components/atoms/document-view/DocumentView';
import RejectionForm from '../RejectionForm';

const DeanForm = ({ onSubmit, data }) => {
  const navigate = useNavigate();
  const [post] = usePostMutation();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [actionType, setActionType] = useState('');

  const formik = useFormik({
    initialValues: {
      comment: '',
    },
    validationSchema: Yup.object({
      comment: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      if (actionType == 'approve') {
        handleApprove();
      } else if (actionType == 'reject') {
        setWarningModalOpen(true);
      }
    },
  });

  const handleApprove = async () => {
    setIsApproving(true);
    setActionType('approve');

    const payload = {
      RequestId: data?.request.id,
      Comments: formik.values.comment,
    };

    const formData = objectToFormData(payload);

    try {
      await post({
        apiUrl: _endpoints.approver.approveRequest,
        data: formData,
        type: 'formData',
      }).unwrap();
      setIsSuccessModalOpen(true);
      setTimeout(() => {
        navigate(-1);
      }, 2000);
    } catch (error) {
      errorMessage('Something went wrong!');
    } finally {
      setIsApproving(false);
    }
  };

  const handleRejection = async (reason) => {
    setIsRejecting(true);
    setActionType('reject');

    try {
      await post({
        apiUrl: _endpoints.approver.rejectRequest,
        data: {
          requestId: data?.request.id,
          comments: formik.values.comment,
          reason: reason,
        },
      }).unwrap();
      setIsSuccessModalOpen(true);
      setWarningModalOpen(false);
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } catch (error) {
      errorMessage('Something went wrong!');
    } finally {
      setIsRejecting(false);
    }
  };

  const closeModal = () => {
    setIsSuccessModalOpen(false);
    setWarningModalOpen(false);
  };

  return (
    <>
      <div className={styles['requests-container']}>
        <Container>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.validateForm().then(() => formik.handleSubmit());
            }}
            className={styles.form}
          >
            <TextArea
              label={appConstants.LABEL.COMMENT}
              placeholder={appConstants.PLACEHOLDER.ENTER_COMMENT}
              type={appConstants.TYPE.NOTE}
              name="comment"
              value={formik.values.comment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.comment}
              touched={formik.touched.comment}
            />

            <div className={styles['button-container']}>
              <Button
                label="Approve"
                type="thumbsUp"
                variant="green"
                onClick={(event) => {
                  event.preventDefault();
                  setActionType('approve');
                  formik.handleSubmit();
                }}
                isLoading={isApproving}
              />
              <Button
                label={appConstants.LABEL.REJECT_REQUEST}
                type={appConstants.TYPE.THUMB_DOWN}
                variant={appConstants.VARIANT.DANGER}
                onClick={(event) => {
                  event.preventDefault();
                  setActionType('reject');
                  formik.handleSubmit();
                }}
              />
            </div>

            <SuccessModal
              isOpen={isSuccessModalOpen}
              isSuccess
              text={
                actionType === 'approve'
                  ? 'Request Approved'
                  : 'Request Rejected'
              }
              timeout={1000}
              subText={
                actionType === 'approve'
                  ? 'Request has been reviewed and given approval.'
                  : 'Request has been reviewed and rejected.'
              }
            />
            <RejectionForm
              isWarningModalOpen={isWarningModalOpen}
              closeModal={closeModal}
              isRejecting={isRejecting}
              handleRejection={handleRejection}
            />
          </form>
        </Container>
      </div>
    </>
  );
};
export default DeanForm;
