import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styles from './RequestDetail.module.scss';
import StudentProfileWrapper from '../../../../components/hoc/students/student-profile-wrapper/StudentProfileWrapper';
import { Tab, Tabs } from '../../../../components/molecules/tab/Tab';
import appConstants from '../../../../app/constant/constants/appConstants';
import Table from '../../../../components/molecules/table/Table';
import {
  tableData,
  tableHeader,
} from '../../../../app/constant/dummy-data/table';
import PendingRequestsTab from './components/Components';
import Container from '../../../../components/hoc/container/Container';
import _endpoints from '../../../../app/constant/endpoints/_endpoints';
import { useGetQuery } from '../../../../services/apiCall';
import Loader from '../../../../components/molecules/table/atoms/loader/Loader';
import EmailsLog from './components/EmaillsLog/EmailsLog';
import NewTable from '../../../../components/molecules/new-table/NewTable';
import AllApplicationsLog from './components/ApplicationLogs/AllApplicationsLog';

const api = _endpoints.request.getRequestById;

const RequestDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const { query, activeApplication, type } = location.state || {};
  const params = [`/${id}`];

  const [initialLoading, setInitialLoading] = useState(true);

  const { data, isLoading, refetch } = useGetQuery({ api, params });
  useEffect(() => {
    refetch();
  }, [id]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialLoading(false);
    }, 700);

    return () => clearTimeout(timer);
  }, []);


  return (
    <StudentProfileWrapper
      headerData={data}
      fallbackState={{
        to: '/all-requests',
        state: 3,
        query: { query, activeApplication, type },
      }}
      isLoading={isLoading}
    >
      <Tabs tabClassname={styles.tab} variant="container">
        <Tab
          label={appConstants.LABEL.PENDING_REQUESTS}
          type={appConstants.TYPE.EXCLAMATION_MARK}
        >
          {isLoading || initialLoading ? (
            <Loader />
          ) : (
            <PendingRequestsTab data={data} query={query} />
          )}
        </Tab>
        <Tab
          label={appConstants.LABEL.ALL_APPLICATIONS_LOG}
          type={appConstants.TYPE.FOLDER}
        >
          <Container>
            <AllApplicationsLog
              key={data}
              data={data}
              // isLoading={isLoading}
              refetch={refetch}
            />
          </Container>
        </Tab>
        <Tab label={appConstants.LABEL.Emails_LOG} type="emailTab">
          <EmailsLog emailHistory={data?.emailHistory} />
        </Tab>
      </Tabs>
    </StudentProfileWrapper>
  );
};

export default RequestDetail;
