import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import styles from './DropdownButton.module.scss';
import { getSvgByType } from '../../../app/helpers/forms/GetSvgByType';
import Checkbox from '../forms/checkbox/Checkbox';
import appConstants from '../../../app/constant/constants/appConstants';
import Tooltip from '../../molecules/tootltip/Tooltip';

const DropdownButton = ({
  items,
  type,
  onClose,
  isMulty = false,
  disabled,
  handleCheckboxChange,
  tooltipContent,
}) => {
  const [isPortalOpen, setPortalOpen] = useState(false);
  const [animationClass, setAnimationClass] = useState('');
  const [dropdownWidth, setDropdownWidth] = useState(0);
  const btnRef = useRef();
  const portalRef = useRef();
  const portalRoot = document.getElementById('portal-root') || document.body;

  const getPortalPosition = () => {
    const rect = btnRef?.current?.getBoundingClientRect();
    return {
      top: rect?.bottom + window.scrollY,
      left: rect?.left - dropdownWidth + window.scrollX,
      position: 'absolute',
    };
  };

  const openPortal = () => {
    if (disabled) return;
    setPortalOpen(true);
    setAnimationClass('entering');
    setTimeout(() => {
      if (portalRef.current) {
        setDropdownWidth(portalRef.current.offsetWidth);
      }
    }, 0);
  };

  const closePortal = () => {
    setAnimationClass('exiting');
    setTimeout(() => {
      setPortalOpen(false);
      setAnimationClass('');
      if (onClose) onClose();
    }, 300);
  };

  const handleClickOutside = (event) => {
    if (
      portalRef.current &&
      !portalRef.current.contains(event.target) &&
      !btnRef.current.contains(event.target)
    ) {
      closePortal();
    }
  };

  const handleScroll = () => {
    closePortal();
  };

  useEffect(() => {
    if (isPortalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      window.addEventListener('scroll', handleScroll, { passive: true });
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isPortalOpen]);

  const portalContent = (
    <div
      ref={portalRef}
      className={`${styles['portal-content']} ${styles[animationClass]}`}
      style={getPortalPosition()}
    >
      {items?.map((item, index) => (
        <div
          className={styles.ss}
          key={index}
          onClick={!isMulty ? closePortal : undefined}
        >
          {type === appConstants.TYPE.COL_VISIBILITY ? (
            <div className={styles.check}>
              <Checkbox
                label={item.label}
                ischecked={item.checked}
                onChange={() => handleCheckboxChange(item)}
              />
            </div>
          ) : (
            <div className={styles.btn} onClick={item.function}>
              <img
                src={getSvgByType(item.type)}
                alt=""
                className={styles.image}
              />
              <p>{item.label}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );

  return (
    <div className={styles['portal-container']} ref={btnRef}>
      <Tooltip content={tooltipContent}>
        <span className={styles['expend-btn']} onClick={openPortal}>
          <img src={getSvgByType(type)} alt="options" />
        </span>
      </Tooltip>

      {isPortalOpen && ReactDOM.createPortal(portalContent, portalRoot)}
    </div>
  );
};

export default DropdownButton;
