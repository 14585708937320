import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Application.module.scss';
import Button from '../../../../../../atoms/forms/button/Button';
import TextArea from '../../../../../../atoms/forms/text-area/TextArea';
import Container from '../../../../../../hoc/container/Container';
import appConstants from '../../../../../../../app/constant/constants/appConstants';
import { routes } from '../../../../../../../app/constant/routes/Routes';
import gloablStyle from '../../../../../../../globals.module.scss';
import Select from '../../../../../../atoms/forms/select/select/Select';
import _endpoints from '../../../../../../../app/constant/endpoints/_endpoints';
import { useGetQuery } from '../../../../../../../services/apiCall';
import NewTable from '../../../../../../molecules/new-table/NewTable';
import CourseDetails from '../../../commonComponents/CourseDetails/CourseDetails';
import useForm from './hook';
import SuccessModal from '../../../../../../hoc/success-modal/SuccessModals';

const termsApi = _endpoints.dropdown.GetTermsForCourseOverload;
const courseCodeApi = _endpoints.dropdown.GetCourseByTerm;
const defaultId = '00000000-0000-0000-0000-000000000000';
const Application = ({ title }) => {
  const navigate = useNavigate();
  const [isNextStep, setIsNextStep] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState({
    show: false,
    message: '',
    subMessage: '',
    isSuccess: false,
  });
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [onSearch, setOnSearch] = useState(false);

  const [filter, setFilter] = useState({
    term: null,
    courseCode: null,
  });

  const [initialApiCall, setInitialApiCall] = useState(
    `${_endpoints.courses.GetSectionByTermAndCourse}/${defaultId}/${defaultId}`
  );

  const { data: termsData, isLoading: termsIsLoading } = useGetQuery({
    api: termsApi,
  });

  const { data: courseCodeData, isLoading: courseCodeIsLoading } = useGetQuery(
    {
      api: `${courseCodeApi}?TermId=${filter?.term}`,
    },
    { skip: !filter.term }
  );
  const courseCodeOptions = courseCodeData?.map(({ course }) => ({
    value: course.id,
    label: `${course.courseCode}-${course.description}`,
  }));

  const handleRowClick = (row) => {
    setSelectedCourse(row);
  };

  const handleBackClick = () => {
    setSelectedCourse(null);

    setIsNextStep(false);
  };

  const handleSubmitApplication = () => {
    navigate(routes.STUDENTS.ALL_APPLICATIONS);
  };

  const handleChange = (name, value) => {
    if (name === 'term') {
      setFilter((prev) => ({
        ...prev,
        [name]: value,
        courseCode: null,
      }));
    } else {
      setFilter((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSearch = () => {
    if (filter.term) {
      setInitialApiCall(
        `${_endpoints.courses.GetSectionByTermAndCourse}/${filter.term}/${
          filter.courseCode ?? defaultId
        }`
      );
      setRefetch(!refetch);
    }
    setOnSearch(true);
  };
  const handleNextStep = () => {
    if (selectedCourse) {
      setIsNextStep(true);
    }
  };
  const onSuccess = ({ message, subMessage }) => {
    setIsSuccessModalOpen({
      show: true,
      message: message,
      subMessage: subMessage,
      isSuccess: true,
    });
  };

  const onError = ({ message, subMessage }) => {
    setIsSuccessModalOpen({
      show: true,
      message: message,
      subMessage: subMessage,
      isSuccess: false,
    });
  };
  const { formik, isLoading } = useForm({
    selectedCourse,
    filters: filter,
    onSuccess: onSuccess,
    onError: onError,
  });

  const renderCourseTable = () => (
    <Container>
      <div className={styles.application}>
        <div className={styles.filters}>
          <Select
            label="Term"
            placeholder="Select Term"
            value={filter.term}
            onChange={(e) => handleChange('term', e.target.value)}
            type={appConstants.TYPE.DATE}
            options={termsData}
            isLoading={termsIsLoading}
          />
          <Select
            label="Course Code"
            placeholder="Select Course Code"
            value={filter.courseCode}
            onChange={(e) => handleChange('courseCode', e.target.value)}
            type={appConstants.TYPE.DATE}
            options={courseCodeOptions}
            isLoading={courseCodeIsLoading}
            disabled={!filter.term}
          />
          <Button
            type={appConstants.TYPE.SEARCH}
            label="Search"
            variant="danger"
            onClick={handleSearch}
          />
        </div>
        {onSearch ? (
          <NewTable
            title={appConstants.CHOOSE_COURSE}
            api={initialApiCall}
            onRowClick={handleRowClick}
            refetch={refetch}
            actions={{ hasOptionalSelect: true }}
            rowKey="id"
          />
        ) : null}

        {selectedCourse && (
          <Button
            label={appConstants.LABEL.NEXT}
            variant={appConstants.VARIANT.DANGER}
            type={appConstants.TYPE.CHECK}
            onClick={handleNextStep}
          />
        )}
      </div>
    </Container>
  );

  const renderCourseDetails = () => {
    return (
      <div className={styles['course-wrapper']}>
        <Container>
          <CourseDetails
            selectedCourse={selectedCourse}
            onBackClick={handleBackClick}
          />
        </Container>
        <Container>
          <form onSubmit={formik.handleSubmit}>
            <div className={gloablStyle['course-actions']}>
              <TextArea
                label={`${appConstants.REASON_FOR} ${title}`}
                placeholder={appConstants.PLACEHOLDER.ENTER_REASON}
                type={appConstants.TYPE.NOTE}
                name="reason"
                value={formik.values.reason}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.reason && formik.errors.reason}
              />

              <Button
                label={isLoading ? 'Submitting...' : appConstants.LABEL.SUBMIT}
                variant={appConstants.VARIANT.DANGER}
                type="check"
                disabled={isLoading}
              />
            </div>
          </form>
          <SuccessModal
            isOpen={isSuccessModalOpen?.show}
            isSuccess={isSuccessModalOpen.isSuccess}
            text={isSuccessModalOpen?.message}
            subText={isSuccessModalOpen?.subMessage}
            onClose={() =>
              setIsSuccessModalOpen({
                show: false,
                message: '',
                subMessage: '',
              })
            }
          />
        </Container>
      </div>
    );
  };

  return (
    <div className={styles['application-container']}>
      {isNextStep ? renderCourseDetails() : renderCourseTable()}
    </div>
  );
};

export default Application;
