import React from 'react';
import CommonForm from './CommonForm';
import RegistrarForm from './RegistrarForm';
import Loader from '../../../../../../components/molecules/table/atoms/loader/Loader';
import { useSelector } from 'react-redux';
const AcademicStandingAppealWrapper = ({ data }) => {
  const { permissions } = useSelector((state) => state.user);

  if (!permissions) {
    return <Loader />;
  }
  const renderForm = () => {
    // Map of forms based on permission keys
    const formKeysMap = {
      ASA_DEAN_VERIFCIATION: <CommonForm data={data} provost />,
      ASA_OFFICE_SRS: <CommonForm data={data} provost />,
      ASA_PRESIDENT_VERIFCIATION: <CommonForm data={data} />,
      ASA_REGISTRAR_VERIFCIATION: <RegistrarForm data={data} />,
      ASA_REGISTRAR_PROCESS: <RegistrarForm data={data} finalProcess />,
      ASA_PROVOST_VERIFCIATION: <CommonForm data={data} provost />,
    };

    const permissionKeys =
      permissions?.permissions?.map((perm) => perm.actionTextKey) || [];

    const permission = Object.keys(formKeysMap).find((key) =>
      permissionKeys.includes(key)
    );
    if (
      data?.canAct === 1 &&
      permission &&
      data?.request?.waitingFor?.key == permission
    ) {
      return formKeysMap[permission];
    }

    return <p></p>;
  };

  return renderForm();
};

export default AcademicStandingAppealWrapper;
