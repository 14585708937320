import React, { useEffect, useMemo } from 'react';
import styles from './SpecialApproval_TEMP.module.scss';
import Container from '../../../../hoc/container/Container';
import MultiSelectList from '../../../../atoms/forms/select/multi-select-list/MultiSelectList';
import appConstants from '../../../../../app/constant/constants/appConstants';
import Button from '../../../../atoms/forms/button/Button';
import Input from '../../../../atoms/forms/input/input/Input';
import { useGetQuery } from '../../../../../services/apiCall';
import _endpoints from '../../../../../app/constant/endpoints/_endpoints';
import useForm from './factory/hook';
import Loader from '../../../../molecules/table/atoms/loader/Loader';

const studentsGroupApi = _endpoints.dropdown.getStudentGroup;
const programsApi = _endpoints.approver.getApproverDD;
const specialApprovalApi = _endpoints.specialApproval.get;

const SpecialApproval_TEMP = () => {
  const { data, isLoading } = useGetQuery({ api: studentsGroupApi });
  const {
    data: specialApprovalData,
    isLoading: approvalIsLoading,
    refetch,
  } = useGetQuery({
    api: specialApprovalApi,
    params: ['/3'],
  });

  const { data: programData, isLoading: programIsLoading } = useGetQuery({
    api: programsApi,
  });

  // Generate students group options
  const studentGroupOptions = useMemo(
    () =>
      data?.map((val) => ({
        value: val.id,
        label: val.studentGroupName,
      })),
    [data]
  );

  // Generate programs options
  const programsOptions = useMemo(
    () =>
      programData?.map((val) => ({
        value: val.id,
        label: val.versionCode,
      })),
    [programData]
  );

  const transformedSpecialApprovalData = useMemo(() => {
    if (!specialApprovalData || specialApprovalData.length === 0) return {};
    let groups = [];
    let programs = [];
    const studentCredits = specialApprovalData[0]?.studentCredits;
    specialApprovalData[0]?.groups?.map((val) => {
      groups.push(val.groupId);
    });
    specialApprovalData[0]?.programs?.map((val) => {
      programs.push(val.programId);
    });
    return {
      programs: programs,
      groups: groups,
      studentCredits: studentCredits,
    };
  }, [specialApprovalData]);

  const { formik, isLoading: isPosting } = useForm({
    data: transformedSpecialApprovalData,
    onSuccess: refetch,
  });
  useEffect(() => {
    refetch();
  }, []);


  return (
    <div className={styles['special-approval']}>
      {approvalIsLoading ? <Loader backdrop /> : null}
      <Container>
        <div className={styles.header}>
          <div className={styles.headings}>
            <h1>Senior Student for Special Approval</h1>
            <p>
              If students meet the following conditions, the redirect ‘Advisor’
              approval requests to the Registrar’s Office
            </p>
          </div>
          <Button
            type={appConstants.TYPE.SAVE}
            label="save"
            variant="danger"
            isLoading={isPosting}
            buttonType="button"
            onClick={formik.handleSubmit}
          />
        </div>
      </Container>

      <form onSubmit={formik.handleSubmit}>
        <div className={styles['student-programs']}>
          <div className={styles.program}>
            <Container>
              <div className={styles['text-wrapper']}>
                <MultiSelectList
                  label="The student’s program should not be any of those listed below."
                  placeholder="Enter student programs"
                  name="programs"
                  options={programsOptions}
                  isLoading={programIsLoading}
                  value={formik.values.programs}
                  type={appConstants.TYPE.FILE}
                  onChange={(selected) =>
                    formik.setFieldValue('programs', selected.target.value)
                  }
                  clearText="Clear Programs"
                  error={formik.touched.programs && formik.errors.programs}
                  touched={formik.touched.programs}
                  disabled={isPosting}
                />

                <Input
                  label="Student's total credit should be greater than"
                  placeholder="Enter credits"
                  type={appConstants.TYPE.ENUM}
                  inputType="number"
                  name="studentCredits"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.studentCredits}
                  error={
                    formik.touched.studentCredits &&
                    formik.errors.studentCredits
                  }
                  touched={formik.touched.studentCredits}
                  disabled={isPosting}
                />
              </div>
            </Container>
          </div>

          <div className={styles.program}>
            <Container>
              <MultiSelectList
                label="The student should not belong to any of the groups listed below."
                placeholder="Enter student group"
                name="groups"
                options={studentGroupOptions}
                type={appConstants.TYPE.FILE}
                isLoading={isLoading}
                value={formik.values.groups}
                onChange={(selected) =>
                  formik.setFieldValue('groups', selected.target.value)
                }
                clearText="Clear Groups"
                error={formik.touched.groups && formik.errors.groups}
                touched={formik.touched.groups}
                disabled={isPosting}
              />
            </Container>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SpecialApproval_TEMP;
