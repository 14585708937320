import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { usePostMutation } from '../../../../../../../services/apiCall';
import _endpoints from '../../../../../../../app/constant/endpoints/_endpoints';
import { successMessage } from '../../../../../../hoc/toast/Toast';
import { handleError } from '../../../../../../molecules/table/Table';

const apiUrl = _endpoints.approver.post;

const validationSchema = Yup.object({
  id: Yup.string(),
  keyId: Yup.string(),
  requestType: Yup.number().required('Request Type is required'),
  subFlow: Yup.number().required('Subflow is required'),
  activeStatus: Yup.number().required('Active status is required'),
  seq: Yup.number(),
  roleId: Yup.string().required('Approver Role is required'),
  approvedMessage: Yup.string().required('Approved Request Log is required'),
  rejectedMessage: Yup.string().required('Rejected Request Log is required'),
  onApproval_StudentTemplate: Yup.string(),
  onApproval_NextStepTemplate: Yup.string(),
  onRejection_InstructorTemplate: Yup.string(),
  onRejection_StudentTemplate: Yup.string(),
  onRejection_NotifyTemplate: Yup.string(),
  extraTemplates: Yup.array().of(
    Yup.object().shape({
      roleId: Yup.string().required('Role is required'),
      isApproval: Yup.boolean(),
      templateId: Yup.string().required('Email template is required'),
    })
  ),
});

const useForm = ({ onSuccess, data, type, activeApplication, subflow }) => {
  const [key, setKey] = useState(0);
  const [post, { isLoading, isSuccess }] = usePostMutation();
  const [init, setInit] = useState({});
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const formattedValues = Object.fromEntries(
      Object.entries(values).map(([key, value]) => [
        key,
        value === '' ? null : value,
      ])
    );

    if (type === 'create') delete formattedValues.id;
    if (!formattedValues.subFlow) delete formattedValues.subFlow;

    try {
      await post({ apiUrl, data: formattedValues }).unwrap();
      resetForm();
      setKey((prev) => prev + 1);
      successMessage('Saved Successfully!');
      if (onSuccess) onSuccess();
    } catch (error) {
      handleError(error);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    setInit({
      id: type === 'edit' ? data?.id || '' : '',
      keyId: data?.keyId || '',
      requestType: activeApplication?.id || 0,
      subFlow: subflow ?? 0,
      activeStatus: data?.activeStatus,
      seq: data?.seq || 0,
      roleId: data?.roleId || '',
      approvedMessage: data?.approvedMessage || '',
      rejectedMessage: data?.rejectedMessage || '',
      onApproval_StudentTemplate: data?.onApproval_StudentTemplate || '',
      onApproval_NextStepTemplate: data?.onApproval_NextStepTemplate || '',
      onRejection_InstructorTemplate:
        data?.onRejection_InstructorTemplate || '',
      onRejection_StudentTemplate: data?.onRejection_StudentTemplate || '',
      onRejection_NotifyTemplate: data?.onRejection_NotifyTemplate || '',
      extraTemplates: data?.details?.length > 0 ? data?.details : [],
      showStudent: data?.showStudent || false,
    });
  }, [data]);

  const formik = useFormik({
    initialValues: init,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return { isLoading, isSuccess, formik, key };
};

export default useForm;
