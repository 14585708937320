import React from 'react';
import StudentProfileWrapper from '../../../../../components/hoc/students/student-profile-wrapper/StudentProfileWrapper';
import CourseWithdrawal_TEMP from '../../../../../components/templates/students/applications/course-withdrawal/CourseWithdrawal_TEMP';
import appConstants from '../../../../../app/constant/constants/appConstants';
import useCheckIfIsAllowed from '../hooks/hook';

const CourseWithdrawal = () => {
  useCheckIfIsAllowed();
  return (
    <StudentProfileWrapper title={appConstants.COURSE_WITHDRAWAL} isStudent>
      <CourseWithdrawal_TEMP />
    </StudentProfileWrapper>
  );
};

export default CourseWithdrawal;
