import React, { useState } from 'react';
import StudentProfileWrapper from '../../../../../../components/hoc/students/student-profile-wrapper/StudentProfileWrapper';
import CommonApplication from '../commonApplication/CommonApplication';
import styles from '../gradeChange/GradeChange.module.scss';
import { useLocation } from 'react-router-dom';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
const RemovalOfI = () => {
  const location = useLocation();
  const [studentData, setStudentData] = useState(location?.state || null);
  const postApi = _endpoints.instructor.postRemovalOfI;
  return (
    <StudentProfileWrapper
      title="Apply for Removal of I Grade"
      data={studentData?.student}
    >
      <div className={styles.application}>
        <CommonApplication postApi={postApi} data={studentData} />
      </div>
    </StudentProfileWrapper>
  );
};

export default RemovalOfI;
