import { useFormik } from 'formik';
import * as Yup from 'yup';
import { usePostMutation } from '../../../../../../../services/apiCall';
import { handleError } from '../../../../../../molecules/new-table/NewTable';
import { useMemo } from 'react';
import _endpoints from '../../../../../../../app/constant/endpoints/_endpoints';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { reRenderHeader } from '../../../../../../../app/globals/storeSlices/userSlice';

const apiUrl = _endpoints.application.academicStandingAppeal;

const getValidationSchema = () =>
  Yup.object().shape({
    term: Yup.string().required('Appeal term is Required'),
    appeal: Yup.string().required('Nature of appeal is Required'),
    mobile: Yup.string().required('Mobile is Required'),

    appealDocuments: Yup.array()
      .min(1, 'At least one appeal document is required')
      .of(Yup.mixed().required('Appeal Documents is required')),
    studentsDocuments: Yup.array()
      .min(1, 'At least one student document is required')
      .of(Yup.mixed().required('Students Documents is required')),
  });

const useForm = ({ selectedCourse, termId, onSuccess, onError }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [post, { isLoading }] = usePostMutation();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    // Initialize FormData
    const formData = new FormData();
    formData.append('AppealType', values.appeal);
    formData.append('TermId', values.term);
    formData.append('MobileNumber', values?.mobile);

    values.appealDocuments.forEach((file, index) => {
      formData.append('ReasonAppealFile', file);
    });

    values.studentsDocuments.forEach((file, index) => {
      formData.append('SupportLetterFile', file);
    });
    const payload = { apiUrl, data: formData, type: 'formData' };

    try {
      const response = await post(payload).unwrap();
      resetForm();

      if (response?.allow) {
        resetForm();
        onSuccess({
          show: true,
          message: 'Submitted Successfully',
          subMessage: 'Your request needs to be verify by registrar',
        });
        dispatch(reRenderHeader());
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      } else {
        onError({
          show: true,
          message: response.message,
          subMessage: '',
        });
      }
    } catch (error) {
      handleError();
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = useMemo(
    () => ({
      term: '',
      appeal: '',
      mobile: '',
      appealDocuments: [],
      studentsDocuments: [],
    }),
    []
  );

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(),
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return { formik, isLoading };
};
export default useForm;
