import React from 'react';
import StudentProfileWrapper from '../../../../../components/hoc/students/student-profile-wrapper/StudentProfileWrapper';
import appConstants from '../../../../../app/constant/constants/appConstants';
import Reappeal_TEMP from '../../../../../components/templates/students/applications/reappeal/Reappeal_TEMP';
import useCheckIfIsAllowed from '../hooks/hook';

const Reappeal = () => {
  useCheckIfIsAllowed();
  return (
    <StudentProfileWrapper title={appConstants.GRADE_APPEAL} isStudent>
      <Reappeal_TEMP />
    </StudentProfileWrapper>
  );
};

export default Reappeal;
