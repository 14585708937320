import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Select from '../../../../../../../../atoms/forms/select/select/Select';
import Button from '../../../../../../../../atoms/forms/button/Button';
import appConstants from '../../../../../../../../../app/constant/constants/appConstants';
import useForm from './hook';
import { useGetQuery } from '../../../../../../../../../services/apiCall';
import _endpoints from '../../../../../../../../../app/constant/endpoints/_endpoints';
import Input from '../../../../../../../../atoms/forms/input/input/Input';
import MultiSelectList from '../../../../../../../../atoms/forms/select/multi-select-list/MultiSelectList';

const RestrictionsDetailsForm = ({
  handleClose,
  type,
  onSuccess,
  items,
  isCredit,
  isTerm,
}) => {
  const [secondApiParams, setSecondApiParams] = useState(null);

  const { data: resData, isLoading: isFirstApiLoading } = useGetQuery({
    api: _endpoints.restrictions.getRestrictionDropDown,
    params: [items.restrictionType.id],
  });
  const { data: dropdownData, isLoading: isSecondApiLoading } = useGetQuery(
    {
      api: secondApiParams,
    },
    { skip: !secondApiParams }
  );

  useEffect(() => {
    if (resData && resData?.api) {
      setSecondApiParams(resData?.api);
    }
  }, [resData]);

  const appData = dropdownData?.map((val) => ({
    value: val.id,
    label: val[resData['label']],
  }));
  const { formik, isLoading, isSuccess } = useForm({
    data: items,
    type: type,
    onSuccess: onSuccess,
    isTerm: isTerm,
    isCredit: isCredit,
    dropdownData: appData,
  });

  return (
    <form
      className={styles['form']}
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      {isCredit || isTerm ? (
        <Select
          label={items?.restrictionType?.restrictionName}
          type={appConstants.TYPE.PROFILE}
          placeholder={`enter ${items?.restrictionType.restrictionName}`}
          name="detailId"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.detailId}
          error={formik.touched.detailId && formik.errors.detailId}
          touched={formik.touched.detailId}
          options={appData || []}
          isLoading={isSecondApiLoading}
        />
      ) : (
        <MultiSelectList
          label={items?.restrictionType?.restrictionName}
          type={appConstants.TYPE.PROFILE}
          placeholder={`enter ${items?.restrictionType.restrictionName}`}
          name="detailId"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.detailId}
          error={formik.touched.detailId && formik.errors.detailId}
          touched={formik.touched.detailId}
          options={appData || []}
          isLoading={isSecondApiLoading}
        />
      )}
      {isCredit ? (
        <div className={styles['input-container']}>
          <Input
            label="Min Credit"
            type={appConstants.TYPE.ENUM}
            inputType="number"
            placeholder="Enters max Credit"
            name="minCredit"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.minCredit}
            error={formik.touched.minCredit && formik.errors.minCredit}
            touched={formik.touched.minCredit}
          />
          <Input
            label="Max Credit"
            type={appConstants.TYPE.ENUM}
            inputType="number"
            placeholder="Enter min Credit"
            name="maxCredit"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.maxCredit}
            error={formik.touched.maxCredit && formik.errors.maxCredit}
            touched={formik.touched.maxCredit}
          />
        </div>
      ) : null}
      {isTerm && (
        <MultiSelectList
          label="terms"
          type="reasonPaper"
          placeholder={'enter terms'}
          name="termText"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.termText}
          error={formik.touched.termText && formik.errors.termText}
          touched={formik.touched.termText}
          options={[
            { value: 'Fall', label: 'Fall' },
            { value: 'Spring', label: 'Spring' },
            { value: 'Summer', label: 'Summer' },
          ]}
        />
      )}
      <div className="form-footer">
        <Button label="cancel" buttonType="button" onClick={handleClose} />
        <Button
          label="save"
          type={appConstants.TYPE.SAVE}
          variant="danger"
          buttonType="submit"
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

export default RestrictionsDetailsForm;
