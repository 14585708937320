import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SuccessModal from '../../../../../../components/hoc/success-modal/SuccessModals';
import Button from '../../../../../../components/atoms/forms/button/Button';
import TextArea from '../../../../../../components/atoms/forms/text-area/TextArea';
import Input from '../../../../../../components/atoms/forms/input/input/Input';
import styles from './CommonApplication.module.scss';
import appConstants from '../../../../../../app/constant/constants/appConstants';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
import { usePostMutation } from '../../../../../../services/apiCall';
import * as Yup from 'yup';
import Container from '../../../../../../components/hoc/container/Container';
import objectToFormData from '../../../../../../app/helpers/forms/objectToFormData';
import { errorMessage } from '../../../../../../components/hoc/toast/Toast';
import SimpleTable from '../../../../../../components/molecules/simple-table/SimpleTable';
import Select from '../../../../../../components/atoms/forms/select/select/Select';

const defaultTableHeaderData = [
  'Course Code',
  'Section',
  'Term',
  'Instructor',
  'room',
  'building',
];

const options = [
  { value: 'IP', label: 'IP' },
  { value: 'P', label: 'P' },
  { value: 'F', label: 'F' },
];
const CommonApplication = ({ onSubmit, data, postApi }) => {
  const navigate = useNavigate();
  const [post] = usePostMutation();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const validationSchema = Yup.object({
    comment: Yup.string().required('Required'),
    numericGrade: Yup.number()
      .nullable()
      .test(
        'numericGrade-or-letterGrade',
        'Either Numeric Grade or Letter Grade is required',
        function (value) {
          return value || this.parent.letterGrade;
        }
      ),
    letterGrade: Yup.string()
      .nullable()
      .test(
        'letterGrade-or-numericGrade',
        'Either Numeric Grade or Letter Grade is required',
        function (value) {
          return value || this.parent.numericGrade;
        }
      ),
  });

  const formik = useFormik({
    initialValues: {
      comment: '',
      numericGrade: '',
      letterGrade: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsApproving(true);
      const payload = {
        StudentId: data?.student?.id,
        Comments: values.comment,
        TermId: data?.termId,
        SectionId: data?.course?.section?.id,
        CourseId: data?.course?.courseId,
        NewGrade: values.letterGrade || values.numericGrade,
      };
      const formData = objectToFormData(payload);
      try {
        await post({
          apiUrl: postApi,
          data: formData,
          type: 'formData',
        }).unwrap();
        setIsSuccessModalOpen(true);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      } catch (error) {
        errorMessage('Something went wrong!');
      } finally {
        setIsApproving(false);
      }
    },
  });
  const customizedTitles = [
    {
      key: data?.course?.grade,
      color: 'red',
      title: 'Current Grade (Numeric)',
    },
    {
      key: data?.student?.letterFta,
      color: 'red',
      title: 'Current Grade (Letter)',
    },
  ];
  const renderTitles = (titles) => {
    return titles
      .filter((item) => item.key)
      .map((item, index) => (
        <div
          key={index}
          style={{ color: item.color }}
          className={styles.customizedTitle}
        >
          <p>{item.title}</p>
          <span> {item.key}</span>
        </div>
      ));
  };
  return (
    <div className={styles.form}>
      <Container>
        <SimpleTable
          tableHeaderData={defaultTableHeaderData}
          tableData={[
            [
              data?.course?.course?.courseCode,
              data?.course?.section?.sectionName,
              data?.course?.termName,
              data?.course?.section?.instructorName,
              data?.course?.section?.room,
              data?.course?.section?.building,
            ],
          ]}
        />

        <div className={styles.customizedTitle_cont}>
          {renderTitles(customizedTitles)}
        </div>
      </Container>

      <Container>
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <div className={styles.decision}>
            <Input
              label="Enter New Numeric Grade"
              placeholder="Enter Grade"
              inputType="number"
              value={formik.values.numericGrade}
              name="numericGrade"
              onChange={(e) => {
                formik.setFieldValue('numericGrade', e.target.value);
                formik.setFieldValue('letterGrade', '');
              }}
              onBlur={formik.handleBlur}
              error={formik.errors.numericGrade}
              touched={formik.touched.numericGrade}
            />
            <p>OR</p>

            <Select
              options={options}
              label="Enter New Letter Grade"
              placeholder="Enter Grade"
              value={formik.values.letterGrade}
              name="letterGrade"
              onChange={(e) => {
                formik.setFieldValue('letterGrade', e.target.value);
                formik.setFieldValue('numericGrade', '');
              }}
              onBlur={formik.handleBlur}
              error={formik.errors.letterGrade}
              touched={formik.touched.letterGrade}
            />
          </div>

          <TextArea
            label="Reason for Authorizing a Grade Change"
            placeholder={appConstants.PLACEHOLDER.ENTER_COMMENT}
            type={appConstants.TYPE.NOTE}
            name="comment"
            value={formik.values.comment}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.comment}
            touched={formik.touched.comment}
          />

          <div className={styles['button-container']}>
            <Button
              label="Submit"
              type={appConstants.TYPE.CHECK}
              variant="blue"
              isLoading={isApproving}
              buttonType="submit"
            />
          </div>
          <SuccessModal
            isOpen={isSuccessModalOpen}
            isSuccess
            text="Submitted Successfully"
            timeout={1000}
          />
        </form>
      </Container>
    </div>
  );
};

export default CommonApplication;
