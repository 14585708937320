import React, { useMemo } from 'react';
import styles from './WithdrawalModal.module.scss';
import { differenceInCalendarDays } from 'date-fns';

const WithdrawalModal = ({ selectedTerm }) => {
  const startDate = selectedTerm?.[0]?.startDate
    ? new Date(selectedTerm[0].startDate)
    : null;
  const now = new Date();

  const daysSinceStart = differenceInCalendarDays(now, startDate);

  const week = useMemo(() => {
    if (daysSinceStart < 0) {
      return null;
    } else if (daysSinceStart < 7) {
      return 1;
    } else if (daysSinceStart < 14) {
      return 2;
    } else if (daysSinceStart < 21) {
      return 3;
    } else if (daysSinceStart < 28) {
      return 4;
    } else {
      return null;
    }
  }, [daysSinceStart]);

  return (
    <div className={styles['withdrawal-modal']}>
      <p>
        In the event that a student withdraws or is dismissed from all classes
        during term, refund of tuition and fees will be calculated according to
        the following schedule:
      </p>

      <table className={styles['refund-table']}>
        <thead>
          <tr>
            <th>Schedule</th>
            <th>Address</th>
          </tr>
        </thead>
        <tbody>
          {week == 1 && (
            <tr>
              <td>During Drop/Add *</td>
              <td>100%</td>
            </tr>
          )}

          {week == 2 && (
            <tr>
              <td>Until the end of the second week **</td>
              <td>50%</td>
            </tr>
          )}
          {week == 3 && (
            <tr>
              <td>Until the end of the third week</td>
              <td>25%</td>
            </tr>
          )}
          {week == 4 && (
            <tr>
              <td>After the third week</td>
              <td>0%</td>
            </tr>
          )}
        </tbody>
      </table>

      <p>
        In the event that a student withdraws from partial classes during the
        term, refunds will be calculated according to the following schedule:
      </p>

      <table className={styles['refund-table']}>
        <thead>
          <tr>
            <th>Schedule</th>
            <th>Address</th>
          </tr>
        </thead>
        <tbody>
          {week == 1 && (
            <tr>
              <td>Until end of Drop/Add ***</td>
              <td>100%</td>
            </tr>
          )}
          {week == 2 && (
            <tr>
              <td>Until the end of the second week **</td>
              <td>50%</td>
            </tr>
          )}
          {week == 3 && (
            <tr>
              <td>Until the end of the third week</td>
              <td>25%</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default WithdrawalModal;
