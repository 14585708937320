import React from 'react';
import styles from './EmailReceived.module.scss';
import Container from '../../../../../../../components/hoc/container/Container';
import { format } from 'date-fns';
import { getSvgByType } from '../../../../../../../app/helpers/forms/GetSvgByType';
import appConstants from '../../../../../../../app/constant/constants/appConstants';

const formatDate = (dateString) => {
  try {
    const date = new Date(dateString);
    return format(date, 'MM/dd/yyyy hh:mm a');
  } catch (e) {
    return '';
  }
};
const EmailsReceived = ({ email }) => {
  return (
    <div className={styles['email_container']}>
      <div
        className={`${styles['email_header_container']} ${styles['header_subContainer']}`}
      >
        <div className={styles['email_header_container']}>
          <img src={getSvgByType(appConstants.TYPE.PROFILE_MALE)} alt="user" />
          <div className={` ${styles['subContainer']}`}>
            <h4>
              From : <span>{email?.fromEmail}</span>
            </h4>
            <h4>
              To : <span>{email?.toEmail}</span>
            </h4>
          </div>
        </div>
        <div className={styles['email_header_container']}>
          {' '}
          <img
            src={getSvgByType(appConstants.TYPE.DATE)}
            alt="user"
            style={{ width: '20px' }}
          />
          <p> Date: {formatDate(email?.emailDate)}</p>
        </div>
      </div>
      <h4>
        Subject : <span>{email?.subject}</span>
      </h4>

      <div
        className={styles['content-email']}
        dangerouslySetInnerHTML={{ __html: email?.emailContent }}
      />
    </div>
  );
};

export default EmailsReceived;
