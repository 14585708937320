import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Tabs } from '../../../../molecules/tab/Tab';
import studentsNavigation from '../../../../../app/constant/navigations/students';
import { usePostMutation } from '../../../../../services/apiCall';
import _endpoints from '../../../../../app/constant/endpoints/_endpoints';

const pendingRequestApi = _endpoints.student.getPendingRequests;

const RouteTab = () => {
  const [post] = usePostMutation();
  const pendingRequests = useSelector((state) => state.user.pendingRequests);
  const [totalCount, setTotalCount] = useState();

  const fetchPendingRequests = async () => {
    try {
      const result = await post({
        apiUrl: pendingRequestApi,
        data: {},
      }).unwrap();
      setTotalCount(result?.totalCount);
    } catch (error) {
      // console.error('Error fetching pending requests:', error);
    }
  };

  useEffect(() => {
    fetchPendingRequests();
  }, [pendingRequests]);

  return (
    <Tabs useRouting totalCount={totalCount}>
      {studentsNavigation.map((tabConfig, index) => (
        <Tab
          key={index}
          label={tabConfig.pageName}
          type={tabConfig.icon}
          path={tabConfig.path}
          totalCount={
            tabConfig.pageName === 'pending requests' ? totalCount : null
          }
        />
      ))}
    </Tabs>
  );
};

export default RouteTab;
