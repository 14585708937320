import React from 'react';
import styles from './ProfileHeader.module.scss';
import { getSvgByType } from '../../../../../../app/helpers/forms/GetSvgByType';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
import appConstants from '../../../../../../app/constant/constants/appConstants';
import Container from '../../../../container/Container';
import Tag from '../../../../../atoms/tag/Tag';
import { useNavigate } from 'react-router-dom';
import Tooltip from '../../../../../molecules/tootltip/Tooltip';
import { formatDateTime } from '../../../../../../app/helpers/forms/formatDate';

const ProfileHeader = ({ data, fallbackState, lastUpdated }) => {
  const navigate = useNavigate();
  const fallback = () => {
    if (fallbackState) {
      navigate(fallbackState?.to, {
        state: fallbackState.query,
      });
    } else {
      navigate(-1);
    }
  };
  return (
    <Container>
      <div className={styles['profile']}>
        <div className={styles['profile-header']}>
          <div className={styles['header-info']}>
            <Tooltip content={'Back'}>
              <img
                src={getSvgByType(appConstants.TYPE.BACKBUTTON)}
                alt=""
                onClick={fallback}
              />
            </Tooltip>
            <p>
              {data?.applicationType}
              {data?.subFlow && (
                <span>{' ' + '(' + data?.subFlow?.flowName + ')'} </span>
              )}
            </p>
          </div>

          <div className={styles['text-wrapper']}>
            <p>
              Request ID: <span>{data?.requestId}</span>
            </p>
            <p>
              Date Created: <span>{formatDateTime(data?.dateCreated)}</span>
            </p>
            <p>
              Last Updated: <span>{formatDateTime(lastUpdated)}</span>{' '}
            </p>
          </div>
        </div>

        <div className={styles['waiting-wrapper']}>
          <div>
            <Tag label={data?.statusName} variant={data?.statusName} />
          </div>
          {data?.waitingFor && (
            <div className={styles['waiting-wrapper']}>
              <p>
                Waiting for: <span>{data?.waitingFor?.role}</span>
              </p>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default ProfileHeader;
