import React from 'react';
import styles from './CourseOverload_TEMP.module.scss';
import appConstants from '../../../../../app/constant/constants/appConstants';
import { Tab, Tabs } from '../../../../molecules/tab/Tab';
import Application from './components/application/Application';

const CourseOverload_TEMP = ({ title }) => {
  return <Application title={title} />;
};

export default CourseOverload_TEMP;
