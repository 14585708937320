import React, { useEffect, useState, useSyncExternalStore } from 'react';
import styles from './CommonApplication.module.scss';
import { useGetQuery } from '../../../../../../services/apiCall';
import CourseWithdrawalForm from '../../course-withdrawal/components/application/form/CourseWithdrawalForm';
import CourseIncompleteForm from '../../course-incomplete/components/application/form/CourseIncompletForm';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
import CourseDetails from '../CourseDetails/CourseDetails';
import CourseTableSelector from '../CourseTableSelector/CourseTableSelector';
import GradeAppealForm from '../../drade-appeal/components/application/form/GradeAppealForm';
import Modal from '../../../../../hoc/modal/Modal';
import WithdrawalModal from '../../course-withdrawal/components/application/WithdrawalModal';

const CommonApplication = ({ requestType, api }) => {
  const { data: terms, refetch } = useGetQuery({ api });
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isNextStep, setIsNextStep] = useState(false);
  const [termId, setTermId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (terms && terms.length > 0) {
      setTermId(terms[0]?.value);
    }
  }, [terms, requestType]);

  useEffect(() => {
    refetch();
  }, [requestType]);

  const handleBackClick = () => {
    setIsNextStep(false);
    setSelectedCourse(null);
  };

  const handleRowClick = (row) => {
    setSelectedCourse(row);
  };
  const handleNextStep = () => {
    if (selectedCourse && requestType !== '3') {
      setIsNextStep(true);
    } else {
      setIsModalOpen(true);
    }
  };

  const handleModalSubmit = () => {
    setIsNextStep(true);
    closeModal();
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const renderForm = () => {
    switch (requestType) {
      case '3':
        return (
          <CourseWithdrawalForm
            selectedCourse={selectedCourse}
            termId={termId}
          />
        );
      case '2':
        return (
          <CourseIncompleteForm
            selectedCourse={selectedCourse}
            termId={termId}
          />
        );
      case '8':
        return (
          <GradeAppealForm selectedCourse={selectedCourse} termId={termId} />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className={styles['application-container']}>
        {isNextStep ? (
          <div className={styles['courseFormCont']}>
            <CourseDetails
              selectedCourse={selectedCourse}
              onBackClick={handleBackClick}
              requestType={requestType}
              selectedTerm={terms?.filter((term) => term.value == termId)}
            />
            {renderForm()}
          </div>
        ) : (
          <CourseTableSelector
            selectedCourse={selectedCourse}
            onRowClick={handleRowClick}
            onNextStep={handleNextStep}
            terms={terms}
            setTermId={setTermId}
            termId={termId}
          />
        )}
      </div>{' '}
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={handleModalSubmit}
        title="Apply for Course Withdrawal"
        modalContent={{
          subtitle: 'Tuition (except IELP and English Bridge Program)',
          component: <div />,
        }}
        closeOnSubmit={true}
        classname={styles.modal}
      >
        <WithdrawalModal
          selectedTerm={terms?.filter((term) => term.value == termId)}
        />
      </Modal>
    </>
  );
};
export default CommonApplication;
