import React from 'react';
import styles from './RequestHistoryPopup.module.scss';
import Tag from '../../../../../atoms/tag/Tag';
import Title from '../../../../../atoms/title/Title';
import Accordion from '../../../../../molecules/accordion/Accordion';
import { formatDateTime } from '../../../../../../app/helpers/forms/formatDate';
import appConstants from '../../../../../../app/constant/constants/appConstants';
import { getSvgByType } from '../../../../../../app/helpers/forms/GetSvgByType';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
import { useGetQuery } from '../../../../../../services/apiCall';
import Loader from '../../../../../molecules/table/atoms/loader/Loader';
import DocumentView from '../../../../../atoms/document-view/DocumentView';
const requestApi = _endpoints.student.getRequestById;

const RequestHistoryPopup = ({ data }) => {
  const { data: requestData, isLoading: requestLoading } = useGetQuery(
    {
      api: requestApi,
      params: [data?.id],
    },
    { skip: !data?.id }
  );
  if (requestLoading) {
    return <Loader />;
  }

  return (
    <div className={styles['request-cont']}>
      <div className={styles['date-cont']}>
        <p>
          Date Created:
          <span> {formatDateTime(requestData?.request?.dateCreated)}</span>{' '}
        </p>
        <Tag
          label={requestData?.request?.statusName}
          variant={requestData?.request?.statusName}
        />
      </div>
      {(requestData?.request?.comments ||
        requestData?.request?.otherReasons) && (
        <div className={styles['request-cont']}>
          <Title text="Reason" />
          <div className={styles['request-subcont']}>
            <img src={getSvgByType('reasonPaper')} />
            <p>
              {requestData?.request?.comments ||
                requestData?.request?.otherReasons}
            </p>
          </div>
        </div>
      )}
      {requestData?.withdrawalReasons?.length > 0 && (
        <div className={styles['request-cont']}>
          <Title text="Withdrawal Reason" />
          <div className={styles['request-subcont']}>
            <img src={getSvgByType('reasonPaper')} />

            <div>
              {requestData?.withdrawalReasons?.map((r) => {
                return <p>{r.reason}</p>;
              })}
            </div>
          </div>
        </div>
      )}{' '}
      {requestData?.request?.makeupDate && (
        <div className={styles['request-cont']}>
          <Title text={'Makeup Date'} />
          <div className={styles['request-subcont']}>
            <img src={getSvgByType('reasonPaper')} />
            <p>{formatDateTime(requestData?.request?.makeupDate)}</p>
          </div>
        </div>
      )}
      {requestData?.files?.length > 0 ? (
        <div className={styles['request-cont']}>
          <Title text="Documents" />

          <div className={styles['documents-container']}>
            {requestData?.files?.map((doc) => {
              return (
                <DocumentView
                  fileName={doc.fileName}
                  fileType={doc.fileType}
                  hide
                />
              );
            })}
          </div>
        </div>
      ) : (
        ''
      )}
      <div className={styles['request-cont']}>
        <Title text="Request Log" />
        {requestData?.approvalRequest?.map((details) => {
          return (
            <Accordion
              title={details.message}
              type={details.approved ? 'approved' : 'notApproved'}
              className={styles['accordion_color']}
              date={details?.dateApproved}
            >
              <div>
                <div className={styles.detailsCont}>
                  <img
                    src={getSvgByType(appConstants.TYPE.PROFILE_MALE)}
                    alt="profile"
                  />
                  <div>
                    <Title text={details.userName} />

                    <Title text={details.role} variant="sm" />
                  </div>
                </div>

                <Title text="Comment :" variant="sm" />
                <Title text={details?.notes} variant="sm-blue" />
              </div>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
};

export default RequestHistoryPopup;
