import React from 'react';
import styles from './SkeletonLoader.module.scss';

const SkeletonLoader = ({
  variant = 'text',
  width = '100%',
  height = '1rem',
}) => {
  const classes = `${styles.skeleton} ${styles[variant]}`;

  return (
    <div
      className={classes}
      style={{
        width,
        height,
      }}
    ></div>
  );
};

export default SkeletonLoader;
