import React, { useEffect, useState } from 'react';
import styles from './AllApplications.module.scss';
import ApplicationCard from '../../../../components/molecules/applicationCard/ApplicationCard';
import routes from './factory/data';
import _endpoints from '../../../../app/constant/endpoints/_endpoints';
import Loader from '../../../../components/molecules/table/atoms/loader/Loader';
import applicationConfigurations from './factory/configurations';
import Container from '../../../../components/hoc/container/Container';
import SearchInput from '../../../../components/atoms/forms/input/searchInput/SearchInput';
import { useSelector } from 'react-redux';
import { useGetQuery } from '../../../../services/apiCall';
import { errorMessage } from '../../../../components/hoc/toast/Toast';

const api = _endpoints.request.getRequestTypes;

const AllApplications = () => {
  const permissions = useSelector(
    (state) => state?.user?.permissions?.permissions
  );
  const [filteredApplications, setFilteredApplications] = useState([]);
  const [search, setSearch] = useState('');
  const { data, isLoading, error } = useGetQuery(
    { api },
    { refetchOnMountOrArgChange: true }
  );

  const applicationsData = data?.map((application) => {
    let route;
    let onModalSubmitroute;
    let FormComponent;
    const config = applicationConfigurations?.find(
      (app) => app.id == application?.id
    );
    if (config) {
      onModalSubmitroute = config.onModalSubmitroute;
      FormComponent = config.FormComponent;
    } else {
      const routeConfig = routes[application.id];
      route = routeConfig?.route || undefined;
    }

    return {
      ...application,
      route,
      onModalSubmitroute,
      FormComponent,
    };
  });
  if (error) {
    errorMessage();
  }
  useEffect(() => {
    if (applicationsData) {
      const permittedApplicationNames = permissions
        ?.map((permission) => permission.actionName?.toLowerCase())
        .filter(Boolean);

      const filtered = applicationsData.filter((app) => {
        const appName = app.applicationName?.toLowerCase();
        return (
          appName &&
          permittedApplicationNames?.includes(appName) &&
          appName?.includes(search.toLowerCase())
        );
      });

      setFilteredApplications(filtered);
    }
  }, [data, permissions, search]);
  return (
    <div className={styles['all_applications_cont']}>
      <Container className={styles['search_cont']}>
        <SearchInput
          placeholder="Search Application"
          search={search}
          onChange={(_, value) => setSearch(value)}
        />
      </Container>
      <div className={styles['all-applications']}>
        {isLoading ? (
          <div className={styles['loader-container']}>
            <Loader />
          </div>
        ) : (
          filteredApplications?.map((application, index) => (
            <ApplicationCard key={index} {...application} />
          ))
        )}
      </div>
    </div>
  );
};

export default AllApplications;
