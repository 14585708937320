import React, { useEffect, useMemo, useState } from 'react';
import Button from '../../../../../../atoms/forms/button/Button';
import Input from '../../../../../../atoms/forms/input/input/Input';
import Select from '../../../../../../atoms/forms/select/select/Select';
import styles from './NewApproverForm.module.scss';
import appConstants from '../../../../../../../app/constant/constants/appConstants';
import _endpoints from '../../../../../../../app/constant/endpoints/_endpoints';
import { useGetQuery } from '../../../../../../../services/apiCall';
import useForm from './hook';
import { getSvgByType } from '../../../../../../../app/helpers/forms/GetSvgByType';
import line from './Line.svg';
import Loader from '../../../../../../molecules/table/atoms/loader/Loader';
import Tooltip from '../../../../../../molecules/tootltip/Tooltip';
import Checkbox from '../../../../../../atoms/forms/checkbox/Checkbox';

const api = _endpoints.roles.getUnPagedRoles;
const emailTemplateapi = _endpoints.notification.GetUnPagedNotifications;
const statusapi = _endpoints.dropdown.getStatus;
const getApproverByIdApi = _endpoints.approver.getApproverById;
const getKeyRoleByType = _endpoints.approver.getKeyRoleByType;

const NewApproverForm = ({
  rowData,
  type,
  handleClose,
  activeApplication,
  subflow,
  onSuccess,
}) => {
  const [sections, setSections] = useState(0);
  const { data: roles } = useGetQuery({ api });
  const { data: notifications } = useGetQuery({
    api: `${emailTemplateapi}?NotificationType=${activeApplication?.id}`,
  });
  const { data: status } = useGetQuery({ api: statusapi });
  const {
    data: keyRoleByType,
    refetch: refetchKeyRolebyType,
    isLoading: isLoadinhKeyRole,
  } = useGetQuery(
    {
      api: getKeyRoleByType,
      params: [`?RequestTypeId=${activeApplication?.id}`],
    },
    { skip: !activeApplication }
  );
  const {
    data: data,
    refetch,
    isLoading,
  } = useGetQuery(
    { api: getApproverByIdApi, params: [`?Id=${rowData?.id}`] },
    { skip: !rowData?.id }
  );
  const { formik, isLoading: isPostLoading } = useForm({
    data: data,
    type: type,
    onSuccess: onSuccess,
    activeApplication: activeApplication,
    subflow: subflow,
  });

  const handleFormSubmit = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  // Generate roles options
  const roleOptions = useMemo(
    () =>
      roles?.map((val) => ({
        value: val.roleId,
        label: val.roleName,
      })),
    [roles]
  );
  // Generate notification options
  const notificationsOptions = useMemo(
    () =>
      notifications?.map((val) => ({
        value: val.value,
        label: val.label,
      })),
    [notifications]
  );
  // Generate status options
  const statusOptions = useMemo(
    () =>
      status?.map((val) => ({
        value: val.statusId,
        label: val.statusText,
      })),
    [status]
  );

  const keyRoleByTypeOptions = useMemo(
    () =>
      keyRoleByType?.map((val) => ({
        value: val.pageActionId,
        label: val.actionName,
      })),
    [keyRoleByType]
  );
  useEffect(() => {
    if (type === 'create') {
      formik.resetForm();
    }
  }, [type]);

  useEffect(() => {
    if (rowData?.id === 0 || rowData?.id) {
      refetch();
    }
  }, [rowData?.id]);

  const addExtraTemplate = (isApproval) => {
    const newSection = { roleId: '', isApproval: isApproval, templateId: '' };
    formik.setFieldValue('extraTemplates', [
      ...formik.values.extraTemplates,
      newSection,
    ]);
    setSections(sections + 1);
  };

  const additionaIcon = (index) => (
    <Tooltip content="Remove">
      <img
        src={getSvgByType(appConstants.TYPE.DELETE)}
        onClick={() => removeSection(index)}
      />
    </Tooltip>
  );

  const removeSection = (index) => {
    const newFiles = formik.values.extraTemplates.filter((_, i) => i !== index);
    formik.setFieldValue('extraTemplates', newFiles);
    setSections(sections - 1);
  };
  return (
    <form
      className={styles['form']}
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <div className={styles.section}>
        {isLoading ? <Loader backdrop isComponent /> : null}
        <div className={styles.subSection}>
          {type == 'edit' && (
            <Input
              label={appConstants.LABEL.APPROVER_SEQUENCE}
              placeholder={appConstants.PLACEHOLDER.ENTER_SEQUENCE}
              type={appConstants.TYPE.FILE}
              name="seq"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.seq}
              error={formik.touched.seq && formik.errors.seq}
              touched={formik.touched.seq}
              inputType="number"
              disabled
            />
          )}
          <Select
            label="Permission Key"
            placeholder="Select permission key"
            type={appConstants.TYPE.FILE}
            name="keyId"
            options={keyRoleByTypeOptions}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.keyId}
            error={formik.touched.keyId && formik.errors.keyId}
            touched={formik.touched.keyId}
          />
        </div>

        <div className={styles.subSection}>
          <Select
            label={appConstants.LABEL.APPROVER_ROLE}
            type={appConstants.TYPE.ROLE}
            placeholder={appConstants.PLACEHOLDER.SELECT_APPROVER}
            name="roleId"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.roleId}
            error={formik.touched.roleId && formik.errors.roleId}
            touched={formik.touched.roleId}
            options={roleOptions}
          />

          <Select
            label="status"
            type={appConstants.TYPE.ROLE}
            placeholder="select status"
            name="activeStatus"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.activeStatus}
            error={formik.touched.activeStatus && formik.errors.activeStatus}
            touched={formik.touched.activeStatus}
            options={statusOptions}
          />
        </div>
        <Checkbox
          label="Show Student"
          onChange={(checked) => {
            formik.setFieldValue('showStudent', checked);
          }}
          ischecked={formik.values.showStudent}
        />
      </div>
      <div className={styles.grid}>
        <div className={styles.section}>
          <div className={styles.titleCont}>
            <div className={styles.titleSubCont}>
              <img src={getSvgByType('approved')} />
              <p>On Approval</p>
            </div>
            <p
              className={styles.addMore}
              onClick={() => addExtraTemplate(true)}
            >
              <span>+ </span>Add More
            </p>
          </div>
          <Input
            label={appConstants.LOG}
            type={appConstants.TYPE.REQUEST_LOG}
            placeholder={appConstants.ENTER_LOG_TEXT}
            name="approvedMessage"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.approvedMessage}
            error={
              formik.touched.approvedMessage && formik.errors.approvedMessage
            }
            touched={formik.touched.approvedMessage}
          />
          <Select
            label={appConstants.EMAIL_STUDENT}
            type={appConstants.TYPE.EMAIL}
            placeholder={appConstants.SELECT_TEMPLATE}
            name="onApproval_StudentTemplate"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.onApproval_StudentTemplate}
            error={
              formik.touched.onApproval_StudentTemplate &&
              formik.errors.onApproval_StudentTemplate
            }
            touched={formik.touched.onApproval_StudentTemplate}
            options={notificationsOptions}
          />
          <Select
            label="Approver Email"
            type={appConstants.TYPE.EMAIL}
            placeholder="Select Email Template"
            name="onApproval_NextStepTemplate"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.onApproval_NextStepTemplate}
            error={
              formik.touched.onApproval_NextStepTemplate &&
              formik.errors.onApproval_NextStepTemplate
            }
            touched={formik.touched.onApproval_NextStepTemplate}
            options={notificationsOptions}
          />
          {formik.values.extraTemplates?.length > 0 && (
            <div className={styles.extraTempl}>
              {formik.values.extraTemplates.map((section, index) => {
                if (section.isApproval != true) {
                  return null;
                }
                return (
                  <div key={index}>
                    {section.templateId == '' && section.roleId == '' && (
                      <Select
                        label={'Role'}
                        name={`extraTemplates[${index}].roleId`}
                        placeholder="Select Role"
                        type={appConstants.TYPE.ROLE}
                        options={roleOptions}
                        value={section.roleId}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.extraTemplates?.[index]?.roleId}
                        touched={formik.touched.extraTemplates?.[index]?.roleId}
                        additionalIcon={() => additionaIcon(index)}
                      />
                    )}
                    {section.roleId && (
                      <Select
                        label={`
                        ${
                          roles?.find((item) => item.roleId == section.roleId)
                            ?.roleName
                        } Email`}
                        name={`extraTemplates[${index}].templateId`}
                        placeholder={`Select ${
                          roles?.find((item) => item.roleId == section.roleId)
                            ?.roleName
                        } Email`}
                        type={appConstants.TYPE.EMAIL}
                        value={section.templateId}
                        options={notificationsOptions}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.errors.extraTemplates?.[index]?.templateId
                        }
                        touched={
                          formik.touched.extraTemplates?.[index]?.templateId
                        }
                        additionalIcon={() => additionaIcon(index)}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <img className={styles.verticalLine} src={line} />
        <div className={styles.section}>
          <div className={styles.titleCont}>
            <div className={styles.titleSubCont}>
              <img src={getSvgByType(appConstants.TYPE.redX)} />
              <p>On Rejection</p>
            </div>

            <p
              onClick={() => addExtraTemplate(false)}
              className={styles.addMore}
            >
              <span>+ </span>Add More
            </p>
          </div>
          <Input
            label={appConstants.LOG}
            type={appConstants.TYPE.REQUEST_LOG}
            placeholder={appConstants.ENTER_LOG_TEXT}
            name="rejectedMessage"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.rejectedMessage}
            error={
              formik.touched.rejectedMessage && formik.errors.rejectedMessage
            }
            touched={formik.touched.rejectedMessage}
          />
          <Select
            label={appConstants.EMAIL_STUDENT}
            type={appConstants.TYPE.EMAIL}
            placeholder="Select Email Template"
            name="onRejection_StudentTemplate"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.onRejection_StudentTemplate}
            error={
              formik.touched.onRejection_StudentTemplate &&
              formik.errors.onRejection_StudentTemplate
            }
            touched={formik.touched.onRejection_StudentTemplate}
            options={notificationsOptions}
          />
          <Select
            label="Previous Approvers Email"
            type={appConstants.TYPE.EMAIL}
            placeholder="Select Email Template"
            name="onRejection_NotifyTemplate"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.onRejection_NotifyTemplate}
            error={
              formik.touched.onRejection_NotifyTemplate &&
              formik.errors.onRejection_NotifyTemplate
            }
            touched={formik.touched.onRejection_NotifyTemplate}
            options={notificationsOptions}
          />
          {formik.values.extraTemplates?.length > 0 && (
            <div className={styles.extraTempl}>
              {formik.values.extraTemplates.map((section, index) => {
                if (section.isApproval != false) {
                  return null;
                }
                return (
                  <div key={index}>
                    {section.templateId == '' && section.roleId == '' && (
                      <Select
                        label={'Role'}
                        name={`extraTemplates[${index}].roleId`}
                        placeholder="Select Role"
                        type={appConstants.TYPE.ROLE}
                        options={roleOptions}
                        value={section.roleId}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.extraTemplates?.[index]?.roleId}
                        touched={formik.touched.extraTemplates?.[index]?.roleId}
                        additionalIcon={() => additionaIcon(index)}
                      />
                    )}
                    {section.roleId && (
                      <Select
                        label={`
                        ${
                          roles?.find((item) => item.roleId == section.roleId)
                            ?.roleName
                        } Email`}
                        name={`extraTemplates[${index}].templateId`}
                        placeholder={`Select ${
                          roles?.find((item) => item.roleId == section.roleId)
                            ?.roleName
                        } Email`}
                        type={appConstants.TYPE.EMAIL}
                        value={section.templateId}
                        options={notificationsOptions}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.errors.extraTemplates?.[index]?.templateId
                        }
                        touched={
                          formik.touched.extraTemplates?.[index]?.templateId
                        }
                        additionalIcon={() => additionaIcon(index)}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      <div className="form-footer">
        <Button
          buttonType="button"
          onClick={handleClose}
          label={appConstants.CANCEL}
        />
        <Button
          onClick={handleFormSubmit}
          label="Save"
          variant={appConstants.VARIANT.DANGER}
          type={appConstants.TYPE.CHECK}
          isLoading={isPostLoading}
          buttonType="button"
        />
      </div>
    </form>
  );
};

export default NewApproverForm;
