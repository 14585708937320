import React from 'react';
import styles from './ReplaceInstructor_TEMP.module.scss';
import Container from '../../../../hoc/container/Container';
import NewTable from '../../../../molecules/new-table/NewTable';
import _endpoints from '../../../../../app/constant/endpoints/_endpoints';

const ReplaceInstructor_TEMP = () => {
  return (
    <div className={styles['groups-container']}>
      <Container>
        <NewTable
          filters={{
            hasSearch: true,
          }}
          api={_endpoints.studentGroup.getStudentGroup}
        />
      </Container>
    </div>
  );
};

export default ReplaceInstructor_TEMP;
