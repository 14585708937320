import React, { useState } from 'react';
import styles from './GradeAppealForm.module.scss';
import Button from '../../../../../../../atoms/forms/button/Button';
import TextArea from '../../../../../../../atoms/forms/text-area/TextArea';
import UploadFile from '../../../../../../../atoms/forms/upload-file/UploadFile';
import Container from '../../../../../../../hoc/container/Container';
import useGradeAppealForm from './hook';
import RadioGroup from '../../../../../../../atoms/forms/radio-group/RadioGroup';
import Select from '../../../../../../../atoms/forms/select/select/Select';
import appConstants from '../../../../../../../../app/constant/constants/appConstants';
import MultipleFileUpload from '../../../../../../../atoms/forms/upload-file/MultipleFileUpload';
import SuccessModal from '../../../../../../../hoc/success-modal/SuccessModals';

const options = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];
const reasonsOptions = [
  { label: 'Instructor is not available', value: 1 },
  { label: 'Instructor left AUD', value: 2 },
  { label: 'Did not ask for a meeting', value: 3 },
];
const GradeAppealForm = ({ selectedCourse, termId }) => {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState({ show: false });
  const onSuccess = ({ show, message }) => {
    setIsSuccessModalOpen({ show: show, isSuccess: true, message: message });
  };
  const onError = ({ show, message }) => {
    setIsSuccessModalOpen({ show: show, message: message, isSuccess: false });
  };
  const { formik, isLoading } = useGradeAppealForm({
    selectedCourse,
    termId,
    onSuccess: onSuccess,
    onError: onError,
  });
  return (
    <div className={styles['course-wrapper']}>
      <Container>
        <form className={styles['course-actions']}>
          <div className={styles['reason-container']}>
            <RadioGroup
              label="Have you met with your instructor?"
              name="MetInstructor"
              selectedValue={formik.values.MetInstructor}
              onChange={(e) => {
                formik.setFieldValue('MetInstructor', e.target.value);
                if (formik.values.MetInstructor == 'yes') {
                  formik.setFieldValue('Reason', '');
                }
              }}
              error={formik.errors.MetInstructor}
              options={options}
            />
            {formik.values.MetInstructor === 'no' ? (
              <div>
                <Select
                  label="Reason"
                  placeholder="Select Reason"
                  name="Reason"
                  type={appConstants.TYPE.FILE}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.Reason}
                  error={formik.errors.Reason}
                  touched={formik.touched.Reason}
                  options={reasonsOptions}
                />
              </div>
            ) : null}
          </div>
          {formik.values.MetInstructor === 'no' &&
          formik.values.Reason !== '' ? (
            formik.values.Reason !== 3 ? (
              <p className={styles['danger']}>
                This grade appeal process should start by meeting with the
                instructor; however, the request will be forwarded to the
                relevant Chair.
              </p>
            ) : (
              <p className={styles['success']}>
                This process should start with your instructor. Please schedule
                a meeting with him/her.
              </p>
            )
          ) : null}
          {formik.values.MetInstructor === 'yes' ||
          (formik.values.MetInstructor === 'no' &&
            formik.values.Reason !== 3) ? (
            <>
              <TextArea
                label="Comment"
                placeholder="Enter your comment here"
                name="Comments"
                value={formik.values.Comments}
                onChange={formik.handleChange}
                error={formik.errors.Comments}
                touched={formik.touched.Comments}
              />
              <MultipleFileUpload
                title="Attach Supporting Documents"
                subTitle="Supported: pdf, jpg, png, doc & docx Max size 45MB"
                name="SupportingDocuments"
                value={formik.values.SupportingDocuments}
                onChange={(e) => {
                  formik.setFieldValue('SupportingDocuments', e.target.value);
                }}
                onBlur={formik.handleBlur}
                // error={formik.errors.SupportingDocuments}
                // touched={formik.touched.SupportingDocuments}
                maxSize="45000000"
              />{' '}
              <Button
                label="Submit"
                variant="danger"
                buttonType="Submit"
                onClick={formik.handleSubmit}
                disabled={isLoading}
                type={appConstants.TYPE.CHECK}
                isLoading={isLoading}
              />
            </>
          ) : null}
        </form>
      </Container>
      <SuccessModal
        isOpen={isSuccessModalOpen.show}
        isSuccess={isSuccessModalOpen.isSuccess}
        text={isSuccessModalOpen.message}
        subText="Your request needs to be verified by the registrar"
        onClose={() => setIsSuccessModalOpen({ show: false, message: '' })}
      />
    </div>
  );
};

export default GradeAppealForm;
