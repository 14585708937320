import React from 'react';
import StudentProfileWrapper from '../../../../../components/hoc/students/student-profile-wrapper/StudentProfileWrapper';
import appConstants from '../../../../../app/constant/constants/appConstants';
import CourseIncomplete_TEMP from '../../../../../components/templates/students/applications/course-incomplete/CourseIncomplete_TEMP';
import useCheckIfIsAllowed from '../hooks/hook';

const CourseIncomplete = () => {
  useCheckIfIsAllowed();
  return (
    <StudentProfileWrapper title={appConstants.COURSE_INCOMPLETE} isStudent>
      <CourseIncomplete_TEMP />
    </StudentProfileWrapper>
  );
};

export default CourseIncomplete;
