import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import _endpoints from '../../../../../../../../../app/constant/endpoints/_endpoints';
import { usePostMutation } from '../../../../../../../../../services/apiCall';
import { handleError } from '../../../../../../../../molecules/new-table/NewTable';
import { successMessage } from '../../../../../../../../hoc/toast/Toast';

const apiUrl = _endpoints.restrictions.postRequestDetail;
const useForm = ({ onSuccess, data, isCredit, isTerm, dropdownData }) => {
  const [key, setKey] = useState(0);
  const [post, { isLoading, isSuccess }] = usePostMutation();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      let filteredDetails = [];
      if (isCredit || isTerm) {
        filteredDetails = dropdownData.filter(
          (item) => values.detailId === item.value
        );
      } else {
        filteredDetails = dropdownData.filter((item) =>
          values.detailId.includes(item.value)
        );
      }

      if (isCredit) {
        filteredDetails = filteredDetails.map((detail) => ({
          ...detail,
          minCredit: values.minCredit || null,
          maxCredit: values.maxCredit || null,
        }));
      }

      if (isTerm) {
        filteredDetails = filteredDetails.map((detail) => ({
          ...detail,
          termText: Array.isArray(values.termText)
            ? values.termText.join(', ')
            : values.termText || null,
        }));
      }

      const restrictionDefinition = data.id;
      const payload = filteredDetails.map((detail) => ({
        detailId: detail.value + '',
        detailText: detail.label,
        restrictionDefinition,
        ...(isCredit && {
          minCredit: detail.minCredit + '',
          maxCredit: detail.maxCredit + '',
        }),
        ...(isTerm && { termText: detail.termText }),
      }));

      await post({ apiUrl, data: { rdArray: payload } }).unwrap();

      if (onSuccess) {
        setKey((prev) => prev + 1);
        successMessage('Saved Successfully');
        onSuccess();
        resetForm();
      }
    } catch (error) {
      handleError(error);
    } finally {
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object({
    detailId:
      isTerm || isCredit
        ? Yup.string().required('Field is required')
        : Yup.array().required('Field is required'),
    detailText: Yup.string(),
    termText: isTerm ? Yup.array().required('Field is required') : Yup.string(),
    minCredit: isCredit
      ? Yup.number()
          .min(0, 'Value must be at least 0')
          .required('Field is required')
      : Yup.number(),
    maxCredit: isCredit
      ? Yup.number()
          .moreThan(
            Yup.ref('minCredit'),
            'Max Credit must be greater than Min Credit'
          )
          .max(300, 'Max Credit cannot exceed 300')
          .required('Field is required')
      : Yup.number(),
  });

  const formik = useFormik({
    initialValues: {
      restrictionDefinition: String(data.id || ''),
      detailId: isCredit ? '' : [],
      detailText: '',
      minCredit: '',
      maxCredit: '',
      termText: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });
  return { isLoading, isSuccess, formik, key };
};

export default useForm;
