import React from 'react';
import CommonForm from './CommonForm';
import RegistrarForm from './RegistrarForm';
import Loader from '../../../../../../components/molecules/table/atoms/loader/Loader';
import { useSelector } from 'react-redux';
const CourseOverloadWrapper = ({ data }) => {
  const { permissions } = useSelector((state) => state.user);

  if (!permissions) {
    return <Loader />;
  }
  const renderForm = () => {
    // Map of forms based on permission keys
    const formKeysMap = {
      OVERLOAD_ADVISOR_VERIFICATION: <CommonForm data={data} />,
      OVERLOAD_DEAN_VERIFICATION: <CommonForm data={data} />,
      OVERLOAD_REGISTAR_VERIFICATION: <CommonForm data={data} />,
      OVERLOAD_REGISTRAR_PROCESS: <RegistrarForm data={data} />,
    };

    const permissionKeys =
      permissions?.permissions?.map((perm) => perm.actionTextKey) || [];

    const permission = Object.keys(formKeysMap).find((key) =>
      permissionKeys.includes(key)
    );
    if (
      data?.canAct === 1 &&
      permission &&
      data?.request?.waitingFor?.key == permission
    ) {
      return formKeysMap[permission];
    }

    return <p></p>;
  };

  return renderForm();
};

export default CourseOverloadWrapper;
