import _endpoints from '../app/constant/endpoints/_endpoints';
import { checkOnlineStatus } from '../app/helpers/web-status/checkOnlineStatus';
import { apiSlice } from '../http/HttpRequest';

const getHeaders = (type) => {
  let headers = {
    'Accept-Language': 'en',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  if (type !== 'formData') {
    headers['Content-Type'] = 'application/json';
  }

  return headers;
};

export const apiCall = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    get: builder.query({
      query: ({ api, params, responseType }) => {
        checkOnlineStatus();
        const API_URL = params ? api + params.join('/') : api;
        return {
          url: API_URL,
          method: 'GET',
          headers: getHeaders('json'),
          responseHandler: (response) => {
            if (responseType === 'blob') {
              return response.blob();
            }
            return response.json();
          },
        };
      },
    }),

    patch: builder.mutation({
      query: ({ apiUrl, formData }) => ({
        url: apiUrl,
        method: 'PATCH',
        headers: getHeaders('json'),
        body: JSON.stringify(formData),
      }),
    }),

    post: builder.mutation({
      query: ({ apiUrl, data, type, params, responseType }) => {
        checkOnlineStatus();
        const API_URL = params ? apiUrl + params.join('/') : apiUrl;

        return {
          url: API_URL,
          method: 'POST',
          headers: getHeaders(type),
          body: type === 'formData' ? data : JSON.stringify(data),
        };
      },
    }),

    postBlob: builder.mutation({
      query: ({ apiUrl, data, type, params, responseType }) => {
        checkOnlineStatus();
        const API_URL = params ? apiUrl + params.join('/') : apiUrl;

        return {
          url: API_URL,
          method: 'POST',
          headers: getHeaders(type),
          body: type === 'formData' ? data : JSON.stringify(data),
          responseHandler: (response) => {
            if (responseType === 'blob') {
              return response?.blob();
            }

            return response.json();
          },
        };
      },
    }),
    put: builder.mutation({
      query: ({ apiUrl, formData }) => ({
        url: apiUrl,
        method: 'PUT',
        headers: getHeaders('json'),
        body: JSON.stringify(formData),
      }),
    }),

    deleteResource: builder.mutation({
      query: ({ apiUrl, params }) => {
        checkOnlineStatus();
        const API_URL = params ? apiUrl + params.join('/') : apiUrl;
        return {
          url: API_URL,
          method: 'DELETE',
          headers: getHeaders('json'),
        };
      },
    }),
    validateToken: builder.query({
      query: () => ({
        url: _endpoints.auth.tokenValidation,
        method: 'POST',
        headers: getHeaders('json'),
      }),
    }),
  }),
});

export const {
  useGetQuery,
  usePostMutation,
  usePostBlobMutation,
  usePatchMutation,
  usePutMutation,
  useDeleteResourceMutation,
  useValidateTokenQuery,
} = apiCall;
