import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useCheckIfIsAllowed = (redirectPath = '/not-authorized') => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location || {};

  useEffect(() => {
    if (!state || !state.isAllowed) {
      navigate(redirectPath);
    }
  }, [state, navigate, redirectPath]);
};

export default useCheckIfIsAllowed;
