
import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { usePostMutation } from '../../../../../../../services/apiCall';
import _endpoints from '../../../../../../../app/constant/endpoints/_endpoints';
import { handleError } from '../../../../../../molecules/new-table/NewTable';
const validationSchema = Yup.object({
  reason: Yup.string(),
});
const useForm = ({ selectedCourse, filters, onSuccess, onError }) => {
  const navigate = useNavigate();
  const [postWithdraw, { isLoading, isSuccess }] = usePostMutation();
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const payload = {
      apiUrl: _endpoints.application.courseOverload,
      data: {
        termId: filters?.term,
        courseId: selectedCourse?.courseId,
        sectionId: selectedCourse?.id,
        reason: values?.reason,
      },
    };
    try {
      const response = await postWithdraw(payload).unwrap();
      if (response?.allow) {
        resetForm();
        onSuccess({
          show: true,
          message: 'Submitted Successfully',
          subMessage: 'Your request needs to be verified by the registrar',
        });
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      } else {
        onError({
          show: true,
          message: response?.message,
        });
      }
    } catch (error) {
      handleError(error);
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = {
    termId: filters?.termId || '',
    reason: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  return { formik, isLoading, isSuccess };
};

export default useForm;
