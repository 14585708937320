import React, { useMemo } from 'react';
import Button from '../../../../../../atoms/forms/button/Button';
import Input from '../../../../../../atoms/forms/input/input/Input';
import Select from '../../../../../../atoms/forms/select/select/Select';
import styles from './NewRestrictionForm.module.scss';
import appConstants from '../../../../../../../app/constant/constants/appConstants';
import _endpoints from '../../../../../../../app/constant/endpoints/_endpoints';
import { useGetQuery } from '../../../../../../../services/apiCall';
import MultiSelectList from '../../../../../../atoms/forms/select/multi-select-list/MultiSelectList';
import useForm from './hook';

const RestrictionApi = _endpoints.restrictions.getRestrictions;
const applicationsApi = _endpoints.request.getRequestTypes;

const NewRestrictionForm = ({ data, type, handleClose, onSuccess }) => {
  const { data: restrictionsData, isLoading: restrictionLoading } = useGetQuery(
    { api: RestrictionApi }
  );

  const { data: applicationsData, isLoading: appLoading } = useGetQuery({
    api: applicationsApi,
  });

  // generate roles options
  const restrictionsOptions = useMemo(
    () =>
      restrictionsData?.map((val) => ({
        value: val.id,
        label: val.restrictionName,
      })),
    [restrictionsData]
  );
  // generate application options
  const applicationOptions = applicationsData?.map((val) => ({
    value: val.id,
    label: val.applicationName,
  }));

  const { formik, isLoading, isSuccess } = useForm({
    data: data,
    type: type,
    onSuccess: onSuccess,
  });

  return (
    <div>
      <form
        className={styles['form']}
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <Input
          label={appConstants.LABEL.RESTRICTION_NAME}
          placeholder={appConstants.PLACEHOLDER.ENTER_RESTRICTION_NAME}
          type={appConstants.TYPE.FILE}
          name="restrictionName"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.restrictionName}
          error={
            formik.touched.restrictionName && formik.errors.restrictionName
          }
          touched={formik.touched.restrictionName}
        />
        <Select
          label={appConstants.LABEL.APPLICATION}
          type={appConstants.TYPE.FILE}
          placeholder={appConstants.PLACEHOLDER.SELECT_APPLICATION}
          name="requestTypeId"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.requestTypeId}
          error={formik.touched.requestTypeId && formik.errors.requestTypeId}
          touched={formik.touched.requestTypeId}
          options={applicationOptions}
          isLoading={appLoading}
        />
        <MultiSelectList
          label={appConstants.LABEL.RESTRICTIONS}
          type={appConstants.TYPE.RESTRICTIONS}
          placeholder="select restrictions"
          name="definitions"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.definitions}
          error={formik.touched.definitions && formik.errors.definitions}
          touched={formik.touched.definitions}
          options={restrictionsOptions}
          // config={{ hasPlusBtn: false, hasClear: false }}
          isLoading={restrictionLoading}
        />
        <div className="form-footer">
          <Button
            buttonType="submit"
            onClick={handleClose}
            label={appConstants.CANCEL}
          />
          <Button
            label="Save"
            variant={appConstants.VARIANT.DANGER}
            type={appConstants.TYPE.CHECK}
            isLoading={isLoading}
            buttonType="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default NewRestrictionForm;
