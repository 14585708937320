import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';
import styles from './MultiSelectList.module.scss';
import Label from '../../label/Label';
import { getSvgByType } from '../../../../../app/helpers/forms/GetSvgByType';
import appConstants from '../../../../../app/constant/constants/appConstants';
import Checkbox from '../../checkbox/Checkbox';
import Tooltip from '../../../../molecules/tootltip/Tooltip';
import Loader from '../../../../molecules/table/atoms/loader/Loader';

const MultiSelectList = ({
  label,
  placeholder,
  name,
  value: initialValue = [],
  onChange,
  style,
  type,
  error,
  touched,
  options,
  config = { hasClear: true, hasPlusBtn: true },
  clearText,
  isLoading,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedValues, setSelectedValues] = useState(initialValue);
  const [dropdownStyle, setDropdownStyle] = useState({});
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  const [visibleItemsCount, setVisibleItemsCount] = useState(20);

  // select a value function
  const handleSelect = useCallback(
    (newValue) => {
      let updatedValues;
      if (selectedValues?.includes(newValue)) {
        updatedValues = selectedValues.filter((val) => val !== newValue);
      } else {
        updatedValues = [...selectedValues, newValue];
      }
      setSelectedValues(updatedValues);
      if (onChange) {
        onChange({
          target: {
            name,
            value: updatedValues,
          },
        });
      }
    },
    [name, onChange, selectedValues]
  );

  // clear values functions
  const handleClear = () => {
    setSelectedValues([]);
    if (onChange) {
      onChange({
        target: {
          name,
          value: [],
        },
      });
    }
  };

  const filteredOptions = useMemo(() => {
    const safeOptions = Array.isArray(options) ? options : [];

    const nonSelectedOptions = safeOptions
      .filter(
        (option) =>
          !selectedValues?.includes(option.value) &&
          option.label.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .map((option) => ({
        value: option.value,
        label: option.label,
        checked: false,
      }));

    return nonSelectedOptions.slice(0, visibleItemsCount);
  }, [options, searchTerm, selectedValues, visibleItemsCount]);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      const { height, top, left, width } =
        inputRef.current.getBoundingClientRect();
      setDropdownStyle({
        position: 'absolute',
        width: `${width}px`,
        top: `${top + height + window.scrollY}px`,
        left: `${left + window.scrollX}px`,
      });
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !inputRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleScrollOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !inputRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleResize = () => {
      setIsOpen(false);
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      window.addEventListener('scroll', handleScrollOutside, true);
      window.addEventListener('resize', handleResize);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScrollOutside, true);
      window.removeEventListener('resize', handleResize);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScrollOutside, true);
      window.removeEventListener('resize', handleResize);
    };
  }, [isOpen]);

  useEffect(() => {
    if (JSON.stringify(initialValue) !== JSON.stringify(selectedValues)) {
      setSelectedValues(initialValue);
    }
  }, [initialValue, selectedValues]);

  const handleDropdownScroll = useCallback(() => {
    if (
      dropdownRef.current &&
      dropdownRef.current.scrollTop + dropdownRef.current.clientHeight >=
        dropdownRef.current.scrollHeight - 20
    ) {
      // each time we reach the bottom of the scroll we will add 50 items more
      setVisibleItemsCount((prevCount) => prevCount + 50);
    }
  }, []);

  return (
    <div className={styles['select-container']} style={style}>
      {label && <Label text={label} htmlFor={`for-${name}`} />}
      <div
        className={styles['select-flex']}
        tabIndex={0}
        aria-controls={`select-dropdown-${name}`}
        aria-expanded={isOpen}
      >
        <div
          className={styles['select-wrapper']}
          onClick={isLoading ? () => {} : () => setIsOpen(!isOpen)}
        >
          <div className={styles['select-display']}>
            {type && (
              <img
                className={styles.svg}
                src={getSvgByType(type)}
                alt={placeholder}
              />
            )}
            <input
              id={`select-${name}`}
              type="text"
              ref={inputRef}
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target?.value);
                if (!isOpen) {
                  setIsOpen(true);
                }
              }}
              autoComplete="off"
              placeholder={placeholder}
              className={`${styles['select']} ${
                !searchTerm && !selectedValues?.length
                  ? styles['placeholder']
                  : ''
              }`}
            />
            {config.hasPlusBtn ? (
              isLoading ? (
                <div className={styles['loader-container']}>
                  <div className={styles.loader}>
                    <Loader
                      style={{ width: '20px', height: '20px' }}
                      isComponent
                    />
                  </div>
                </div>
              ) : (
                <div className={styles['expand-img']}>
                  <img
                    src={getSvgByType(appConstants.TYPE.PLUS_SIGN)}
                    alt="expand"
                  />
                </div>
              )
            ) : null}
          </div>

          {isOpen &&
            createPortal(
              <div
                className={`${styles['options']} ${styles['dropdown-animation']}`}
                style={dropdownStyle}
                ref={dropdownRef}
                onScroll={handleDropdownScroll}
              >
                {filteredOptions.length > 0 ? (
                  filteredOptions.map((option) => (
                    <div
                      key={option.value}
                      className={`${styles['option']} ${
                        option.checked ? styles['selected'] : ''
                      }`}
                      onClick={() => handleSelect(option.value)}
                    >
                      <Checkbox
                        label={option.label}
                        ischecked={option.checked}
                        onChange={() => handleSelect(option.value)}
                      />
                    </div>
                  ))
                ) : (
                  <div className={styles['no-options']}>No data found</div>
                )}
              </div>,
              document.body
            )}
        </div>
        {config.hasClear && clearText && (
          <p onClick={handleClear} className={styles.clear}>
            {clearText}
          </p>
        )}
      </div>

      {selectedValues?.length > 0 && (
        <div className={styles['selected-items-container']}>
          {selectedValues?.length > 0 && (
            <div className={styles['selected-items-container']}>
              {selectedValues?.map((value) => {
                const option = options?.find((opt) => opt.value === value);
                return (
                  option && (
                    <div key={option.value} className={styles['selected-item']}>
                      {option.label}
                      <span onClick={() => handleSelect(option.value)}>
                        <img
                          src={getSvgByType(appConstants.TYPE.PLUS_SIGN)}
                          alt="remove"
                        />
                      </span>
                    </div>
                  )
                );
              })}
            </div>
          )}
        </div>
      )}
      {error && touched && (
        <div className={styles['error-message']}>{error}</div>
      )}
    </div>
  );
};

export default MultiSelectList;
