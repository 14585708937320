import React from 'react';
import styles from './StudentProfile.module.scss';
import Container from '../../hoc/container/Container';
import Title from '../../atoms/title/Title';
import appConstants from '../../../app/constant/constants/appConstants';
import EducationItem from '../../atoms/education-item/EducationItem';
import dummyImage from '../../../assets/images/dummy/gender/male-default.svg';
import SkeletonLoader from '../../atoms/skeleton-loader/SkeletonLoader';

const StudentProfile = (props) => {
  const { data, isLoading } = props;

  return (
    <Container className={styles.container}>
      <div className={styles['student-profile']}>
        <div className={styles['card']}>
          <div className={styles['profile']}>
            <div className={styles['image-wrapper']}>
              {isLoading ? (
                <SkeletonLoader width="100%" height="100%" />
              ) : (
                <img
                  src={data?.profileImage || dummyImage}
                  alt={data?.firstName}
                  className={styles['image']}
                />
              )}
            </div>
            <div className={styles['header-md']}>
              {isLoading ? (
                <SkeletonLoader width="70%" height="1.5rem" />
              ) : (
                <Title
                  text={`${data?.firstName ?? ''} ${data?.lastName ?? ''}`}
                  variant={appConstants.VARIANT.MD}
                />
              )}
              {isLoading ? (
                <SkeletonLoader width="50%" height="1rem" />
              ) : (
                <p className={styles.text}>Student ID: {data?.studentId}</p>
              )}
            </div>
          </div>

          <div className={styles['content']}>
            <div className={styles['header']}>
              {isLoading ? (
                <SkeletonLoader width="100%" height="1.5rem" />
              ) : (
                <Title
                  text={`${data?.firstName ?? ''} ${data?.lastName ?? ''}`}
                  variant={appConstants.VARIANT.MD}
                />
              )}
              {isLoading ? (
                <SkeletonLoader width="70%" height="1rem" />
              ) : (
                <p className={styles.text}>Student ID: {data?.studentId}</p>
              )}
            </div>
            <div className={styles['info-wrapper']}>
              <div className={styles['info']}>
                {isLoading ? null : (
                  <>
                    <EducationItem
                      text={data?.program || 'Not available'}
                      type={appConstants.TYPE.GRADUATIONHAT}
                    />
                    <EducationItem
                      text={data?.email || 'Not available'}
                      type={appConstants.TYPE.EMAILMESSAGE}
                    />
                    <EducationItem
                      text={data?.mobileNumber || 'Not available'}
                      type={appConstants.TYPE.PHONE}
                    />
                    <EducationItem
                      text={data?.schoolStatus || 'Not available'}
                      type={appConstants.TYPE.CAMPUS}
                      isLabeled
                      isActive
                    />
                  </>
                )}
              </div>
              <div className={styles['info']}>
                <EducationItem
                  title={appConstants.SCHOLARSHIP}
                  text={data?.agency || 'Not available'}
                />
                <EducationItem
                  title={appConstants.CREDIt_EARNED}
                  text={data?.gpaCredits?.toFixed(2) || '0.00'}
                />
                <div className={styles['text-wrapper']}>
                  <EducationItem
                    title={appConstants.GPA}
                    text={data?.cummilativeGPA?.toFixed(2) || 'N/A'}
                    isLabeled
                    isActive
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default StudentProfile;
