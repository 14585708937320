import React, { useState, useEffect } from 'react';
import styles from '../NewSetupTermForm.module.scss';
import { renderFieldsForId1 } from './form_1';
import { renderFieldsForId2 } from './form_2';
import { renderFieldsForId3 } from './form_3';
import { renderFieldsForId0 } from './form_0';
import { renderFieldsForId4 } from './form_4';
import { renderFieldsForId6 } from './form_6';
import Select from '../../../../../../../atoms/forms/select/select/Select';
import Button from '../../../../../../../atoms/forms/button/Button';
import appConstants from '../../../../../../../../app/constant/constants/appConstants';

const Form = ({ data, formik, activeApplication, handleClose, isLoading }) => {
  const handleFormSubmit = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };
  const renderFields = () => {
    switch (activeApplication?.id) {
      case 1:
        return renderFieldsForId1(formik, data);
      case 2:
        return renderFieldsForId2(formik, data);
      case 3:
        return renderFieldsForId3(formik, data);
      case 4:
        return renderFieldsForId4(formik, data);
      case 6:
        return renderFieldsForId6(formik, data);
      case 0:
        return renderFieldsForId0(formik, data);
      default:
        return null;
    }
  };

  return (
    <form className={styles['form']} onSubmit={formik.handleSubmit}>
      <Select
        type="file"
        placeholder={activeApplication?.applicationName}
        disabled
      />
      {renderFields()}
      <div className="form-footer">
        <Button onClick={handleClose} label={appConstants.CANCEL} />
        <Button
          onClick={handleFormSubmit}
          label="Save"
          variant={appConstants.VARIANT.DANGER}
          type={appConstants.TYPE.CHECK}
          isLoading={isLoading}
          buttonType="submit"
        />
      </div>
    </form>
  );
};

export default Form;
