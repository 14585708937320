import React from 'react';
import NewTable from '../../../../../../components/molecules/new-table/NewTable';

const AllApplicationsLog = ({ data, refetch }) => {
  const tableData = data
    ? {
        items: data?.otherApplications || [],
        tableView:
          [
            {
              ...data?.tableView[0],
              hasFilter: false,
              hasPaging: false,
              hasSearch: false,
            },
          ] || [],
        totalCount: data?.otherApplications?.length,
        pagNumber: 1,
        pageSize: 10,
      }
    : null;
  return (
    <div>
      <NewTable data={tableData} />
    </div>
  );
};

export default AllApplicationsLog;
