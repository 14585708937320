import React, { useRef, useState } from 'react';
import Container from '../../../../hoc/container/Container';
import styles from './UserProfileHEader.module.scss';
import { getSvgByType } from '../../../../../app/helpers/forms/GetSvgByType';
import appConstants from '../../../../../app/constant/constants/appConstants';
import Title from '../../../../atoms/title/Title';
import Button from '../../../../atoms/forms/button/Button';
import PasswordForm from '../changePasswordForm/PasswordForm';

const UserProfileHeader = ({ data, editMode, setEditMode, formik }) => {
  const fileInputRef = useRef(null);

  const [isChangePass, setIsChangePass] = useState(false);

  const handleSelectImage = () => {
    fileInputRef.current.click();
  };
  const handleUpdate = () => {
    formik.handleSubmit();
    setEditMode(false);
  };
  return (
    <>
      <Container>
        <div className={styles['user_header_cont']}>
          <div className={styles['parent']}>
            <img
              src={getSvgByType(appConstants.TYPE.PROFILE_MALE)}
              alt="icon"
              className={styles['user-image']}
            />
            <input
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              ref={fileInputRef}
              //   onChange={handleImageChange}
            />
            <img
              src={getSvgByType('editImageIcon')}
              alt="icon"
              className={styles['child']}
              onClick={handleSelectImage}
            />
          </div>
          <div className={styles.subContainer}>
            <div className={styles['title_container']}>
              <Title
                text={data?.firstName + ' ' + data?.lastName}
                variant="lg"
              />

              <div className={styles['desktop']}>
                {editMode ? (
                  <div className={styles['edit_title']}>
                    <Button
                      onClick={handleUpdate}
                      label="Update Profile"
                      buttonType="button"
                    />
                  </div>
                ) : (
                  <Button
                    onClick={() => setEditMode(true)}
                    label="Edit Profile"
                    buttonType="button"
                  />
                )}
              </div>
            </div>
            <div className={styles['horizontal_container']}>
              <div className={styles['horizontal']}></div>
            </div>

            <div className={styles['details_subcontainer']}>
              <Title text="Role" variant="sm-bold-black" />
              <Title text={data?.role} variant="sm-black" />
            </div>
          </div>
        </div>
        {/*mobile*/}
        <div className={styles['mobile']}>
          {editMode ? (
            <div className={styles['edit_title_mobile']}>
              <Button
                onClick={() => setIsChangePass(true)}
                label="Change Password"
                variant={appConstants.VARIANT.DANGER}
                buttonType="submit"
              />
              <Button
                onClick={handleUpdate}
                label="Update Profile"
                buttonType="button"
              />
            </div>
          ) : (
            <Button
              onClick={() => setEditMode(true)}
              label="Edit Profile"
              buttonType="button"
            />
          )}
        </div>
      </Container>

      <PasswordForm
        isChangePass={isChangePass}
        onClose={() => setIsChangePass(false)}
        data={data}
      />
    </>
  );
};

export default UserProfileHeader;
