import React, { useState } from 'react';
import styles from './AppealModal.module.scss';
import Modal from '../../../../../components/hoc/modal/Modal';
import Button from '../../../../../components/atoms/forms/button/Button';
import SuccessModal from '../../../../../components/hoc/success-modal/SuccessModals';
import { errorMessage } from '../../../../../components/hoc/toast/Toast';
import _endpoints from '../../../../../app/constant/endpoints/_endpoints';
import { usePostMutation } from '../../../../../services/apiCall';
import appConstants from '../../../../../app/constant/constants/appConstants';
const api = _endpoints.approver.acceptRejectForReappeal;
const AcceptModal = ({ isOpen, onClose, item, onSuccess }) => {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [post] = usePostMutation();

  const handleAccept = async () => {
    setIsLoading(true);
    try {
      await post({
        apiUrl: `${api}${item?.id}`,
      }).unwrap();
      onSuccess();
      onClose();
      setIsSuccessModalOpen(true);
    } catch {
      errorMessage('Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} title={'Re-appeal'}>
        <p className={styles.subText}>
          If you consider the appeal resolved, you will not be able to proceed
          further
        </p>
        <div className={styles.actions}>
          <Button label={appConstants.CANCEL} onClick={onClose} />
          <Button
            label="Okay"
            variant="blue"
            type="check"
            onClick={handleAccept}
            isLoading={isLoading}
          />
        </div>
      </Modal>

      <SuccessModal
        isOpen={isSuccessModalOpen}
        isSuccess
        text={'Reapply Request'}
        timeout={1000}
        subText={'Your request has been processed. Thank you!'}
        onClose={() => setIsSuccessModalOpen(false)}
      />
    </>
  );
};
export default AcceptModal;
