import React, { useEffect, useMemo, useState } from 'react';
import appConstants from '../../../../../../../app/constant/constants/appConstants';
import Input from '../../../../../../atoms/forms/input/input/Input';
import styles from './CoursesForm.module.scss';
import Button from '../../../../../../atoms/forms/button/Button';
import _endpoints from '../../../../../../../app/constant/endpoints/_endpoints';
import Select from '../../../../../../atoms/forms/select/select/Select';
import useForm from './hook';
import { useGetQuery } from '../../../../../../../services/apiCall';
const api = _endpoints.users.getUsers;

const CoursesForm = ({ handleClose, onSuccess, data }) => {
  const { data: users } = useGetQuery({ api });

  const [updatedData, setUpdatedData] = useState(null);

  useEffect(() => {
    if (users && data) {
      const updatedFormData = {
        ...data,
        advisor:
          users.find((user) => user.label === data.advisor)?.value ||
          data.advisor,
        dean:
          users.find((user) => user.label === data.dean)?.value || data.dean,
      };
      setUpdatedData(updatedFormData);
    }
  }, [users, data]);

  const { formik, isLoading } = useForm({
    data: updatedData,
    onSuccess: onSuccess,
  });
  return (
    <form
      className={styles['form']}
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <Input
        label="Course Description"
        placeholder={appConstants.PLACEHOLDER.ENTER_DEPARTMENT}
        type={appConstants.TYPE.FILE}
        name="courseDescription"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.courseDescription}
        error={
          formik.touched.courseDescription && formik.errors.courseDescription
        }
        touched={formik.touched.courseDescription}
      />
      <Select
        label="Chair"
        type={appConstants.TYPE.PROFILE}
        placeholder={appConstants.PLACEHOLDER.ENTER_NAME}
        name="advisor"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.advisor}
        error={formik.touched.advisor && formik.errors.advisor}
        touched={formik.touched.advisor}
        options={users}
      />
      <Select
        label="Dean"
        type={appConstants.TYPE.PROFILE}
        placeholder={appConstants.PLACEHOLDER.ENTER_NAME}
        name="dean"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.dean}
        error={formik.touched.dean && formik.errors.dean}
        touched={formik.touched.dean}
        options={users}
      />
      <div className="form-footer">
        <Button onClick={handleClose} label={appConstants.CANCEL} />
        <Button
          label="Save"
          variant={appConstants.VARIANT.DANGER}
          type={appConstants.TYPE.CHECK}
          isLoading={isLoading}
          buttonType="submit"
        />
      </div>
    </form>
  );
};

export default CoursesForm;
