import React, { useState } from 'react';
import StudentProfileWrapper from '../../../../../../components/hoc/students/student-profile-wrapper/StudentProfileWrapper';
import Container from '../../../../../../components/hoc/container/Container';
import CommonApplication from '../commonApplication/CommonApplication';
import styles from './GradeChange.module.scss';
import { useLocation } from 'react-router-dom';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';

const GradeChange = () => {
  const location = useLocation();
  const [studentData, setStudentData] = useState(location?.state || null);
  const postApi = _endpoints.instructor.postGradeChange;
  return (
    <StudentProfileWrapper
      title="Apply for Grade Change"
      data={studentData?.student}
    >
      <div className={styles.application}>
        <CommonApplication data={studentData} postApi={postApi} />
      </div>
    </StudentProfileWrapper>
  );
};

export default GradeChange;
