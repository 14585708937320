import React, { useEffect, useState } from 'react';
import styles from '../PrivilageSelector.module.scss';
import { getSvgByType } from '../../../../../app/helpers/forms/GetSvgByType';
import appConstants from '../../../../../app/constant/constants/appConstants';
import SmallToggle from '../../small-toggle/SmallToggle';
import SmallCheckBox from '../../small-checkbox/SmallCheckBox';
import _endpoints from '../../../../../app/constant/endpoints/_endpoints';
import Tooltip from '../../../../molecules/tootltip/Tooltip';

const PrivilageSelector = ({ data, onUpdate, isOpen, id, isLoading }) => {
  const [initialData, setInitialData] = useState(data);
  const [mainDropState, setMainDropState] = useState(false);
  useEffect(() => {
    if (data) {
      setInitialData(id ? data[0] : data);
    }
  }, [data]);

  const toggleOpen = (pageId, isChild = false, parentPageId = null) => {
    setInitialData((prevData) => {
      const updatedData = prevData?.pageActions?.map((parentPage) => {
        if (parentPage.pageId === pageId && !isChild) {
          const newIsSelected = !parentPage.isSelected;
          return {
            ...parentPage,
            isSelected: newIsSelected,
          };
        }

        if (isChild && parentPage.pageId === parentPageId) {
          const updatedChildPages = parentPage.childPages.map((childPage) => {
            if (childPage.pageId === pageId) {
              const newIsSelected = !childPage.isSelected;

              return {
                ...childPage,
                isSelected: newIsSelected,
              };
            }
            return childPage;
          });

          return {
            ...parentPage,
            childPages: updatedChildPages,
          };
        }

        return parentPage;
      });

      const updatedInitialData = { ...prevData, pageActions: updatedData };
      onUpdate(updatedInitialData);
      return updatedInitialData;
    });
  };

  const handleCheckboxChange = ({
    parentPageId,
    actionId,
    checked,
    pageId,
  }) => {
    setInitialData((prevData) => {
      const updatedData = prevData.pageActions.map((parentPage) => {
        if (parentPage.pageId === parentPageId) {
          if (parentPage.childPages && parentPage.childPages.length > 0) {
            return {
              ...parentPage,
              childPages: parentPage.childPages.map((child) => {
                if (child.pageId === pageId) {
                  return {
                    ...child,
                    childActions: child.childActions.map((action) => {
                      if (action.pageActionId === actionId) {
                        return { ...action, isSelected: checked };
                      }
                      return action;
                    }),
                  };
                }
                return child;
              }),
            };
          } else if (
            parentPage.childActions &&
            parentPage.childActions.length > 0
          ) {
            return {
              ...parentPage,
              childActions: parentPage.childActions.map((action) => {
                if (action.pageActionId === actionId) {
                  return { ...action, isSelected: checked };
                }
                return action;
              }),
            };
          }
        }
        return parentPage;
      });

      const updatedInitialData = { ...prevData, pageActions: updatedData };
      onUpdate(updatedInitialData);
      return updatedInitialData;
    });
  };

  useEffect(() => {
    if (!isOpen) {
      setMainDropState(false);
      setInitialData((prevData) => ({
        ...prevData,
        pageActions: prevData?.pageActions?.map((page) => ({
          ...page,
          isSelected: false,
        })),
      }));
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setMainDropState(false);
      setInitialData((prevData) => ({
        ...prevData,
        pageActions: prevData?.pageActions?.map((page) => ({
          ...page,
          isSelected: false,
          childPages: page.childPages.map((child) => ({
            ...child,
            isSelected: false,
            childActions: child.childActions.map((action) => ({
              ...action,
              isSelected: false,
            })),
          })),
        })),
      }));
    }
  }, [isOpen]);
  if (isLoading) return;

  return (
    <div>
      <Tooltip content="View More">
        <div
          className={styles['privilage-container']}
          onClick={() => setMainDropState(!mainDropState)}
        >
          <div className={styles['privilage']}>
            <p className={styles['label']}>Pages</p>
          </div>
          <img src={getSvgByType(appConstants.TYPE.DOWN_ARROW)} alt="Toggle" />
        </div>
      </Tooltip>

      {mainDropState &&
        initialData?.pageActions?.map((parentPage) => (
          <div key={parentPage.pageId} className={styles['privilages']}>
            <div className={styles['child-actions']}>
              <div className={styles['child-action-item']}>
                <SmallToggle
                  label={parentPage.pageName}
                  onChange={() => toggleOpen(parentPage.pageId)}
                  value={parentPage.isSelected || false}
                />
              </div>
            </div>
            {parentPage.isSelected && (
              <div className={styles['dropdown-content']}>
                {parentPage?.childPages?.length > 0
                  ? parentPage?.childPages?.map((child) => (
                      <div
                        key={child.pageId}
                        className={styles['dropdown-item']}
                      >
                        <SmallToggle
                          key={child.pageId}
                          label={child.pageName}
                          onChange={() =>
                            toggleOpen(child.pageId, true, parentPage.pageId)
                          }
                          value={child.isSelected || false}
                        />
                        {child.isSelected &&
                          child?.childActions?.length > 0 && (
                            <div className={styles['child-actions']}>
                              {child.childActions.map((action) => (
                                <div
                                  key={action.pageActionId}
                                  className={styles['child-action-item']}
                                >
                                  <SmallCheckBox
                                    key={action.pageActionId}
                                    label={action.actionName}
                                    ischecked={action.isSelected || false}
                                    onChange={(state) =>
                                      handleCheckboxChange({
                                        parentPageId: parentPage.pageId,
                                        pageId: child.pageId,
                                        actionId: action.pageActionId,
                                        checked: state,
                                      })
                                    }
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                      </div>
                    ))
                  : parentPage.childActions?.length > 0 && (
                      // <div className={styles['child-actions-cont']}>
                      //   {parentPage.childActions.map((action) => (
                      //     <div
                      //       key={action.pageActionId}
                      //       className={styles['child-action-item']}
                      //     >
                      //       <SmallCheckBox
                      //         key={action.pageActionId}
                      //         label={action.actionName}
                      //         ischecked={action.isSelected || false}
                      //         onChange={(state) =>
                      //           handleCheckboxChange({
                      //             parentPageId: parentPage.pageId,
                      //             pageId: null,
                      //             actionId: action.pageActionId,
                      //             checked: state,
                      //           })
                      //         }
                      //       />
                      //     </div>
                      //   ))}
                      // </div>
                      <div className={styles['child-actions-cont']}>
                        {Object.entries(
                          parentPage?.childActions.reduce((groups, action) => {
                            const groupId = action.groupId;
                            if (!groups[groupId]) {
                              groups[groupId] = [];
                            }
                            groups[groupId].push(action);
                            return groups;
                          }, {})
                        ).map(([letter, actions]) => (
                          <div
                            key={letter}
                            className={styles['grouped-action-container']}
                          >
                            {actions?.map((action) => (
                              <div
                                key={action.pageActionId}
                                className={styles['child-action-item']}
                              >
                                <SmallCheckBox
                                  key={action.pageActionId}
                                  label={action.actionName}
                                  ischecked={action.isSelected || false}
                                  onChange={(state) =>
                                    handleCheckboxChange({
                                      parentPageId: parentPage.pageId,
                                      pageId: null,
                                      actionId: action.pageActionId,
                                      checked: state,
                                    })
                                  }
                                />
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    )}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default PrivilageSelector;
