import { useFormik } from 'formik';
import React from 'react';
import appConstants from '../../../../../app/constant/constants/appConstants';
import SuccessModal from '../../../../../components/hoc/success-modal/SuccessModals';
import TextArea from '../../../../../components/atoms/forms/text-area/TextArea';
import * as Yup from 'yup';
const RejectionForm = ({
  isWarningModalOpen,
  closeModal,
  isRejecting,
  handleRejection,
}) => {
  const getValidationSchema = () => {
    let baseSchema = {
      reason: Yup.string().required('Required'),
    };

    return Yup.object().shape(baseSchema);
  };
  const formik = useFormik({
    initialValues: {
      reason: '',
    },
    validationSchema: getValidationSchema(),
    onSubmit: (values) => {
      handleRejection(values.reason);
    },
  });
  return (
    <form>
      <SuccessModal
        isOpen={isWarningModalOpen}
        text="Reject Request"
        subText="Are you sure you want to reject this request?"
        onClose={closeModal}
        onSubmit={formik.handleSubmit}
        onApproveButton={{ text: 'Reject' }}
        isLoading={isRejecting}
      >
        {' '}
        <TextArea
          label="Reason"
          placeholder={'Write a reason for rejection'}
          type={appConstants.TYPE.NOTE}
          name="reason"
          value={formik.values.reason}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.reason}
          touched={formik.touched.reason}
        />
      </SuccessModal>
    </form>
  );
};

export default RejectionForm;
