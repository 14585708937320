import React, { useState } from 'react';
import TextArea from '../../../../../../../atoms/forms/text-area/TextArea';
import Button from '../../../../../../../atoms/forms/button/Button';
import useForm from './hook';
import styles from './CourseIncompleteForm.module.scss';
import Container from '../../../../../../../hoc/container/Container';
import appConstants from '../../../../../../../../app/constant/constants/appConstants';
import MultipleFileUpload from '../../../../../../../atoms/forms/upload-file/MultipleFileUpload';
import SuccessModal from '../../../../../../../hoc/success-modal/SuccessModals';

const CourseIncompleteForm = ({ selectedCourse, termId }) => {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState({
    show: false,
    message: '',
    subMessage: '',
    isSuccess: false,
  });
  const onSuccess = ({ message, subMessage }) => {
    setIsSuccessModalOpen({
      show: true,
      message: message,
      subMessage: subMessage,
      isSuccess: true,
    });
  };

  const onError = ({ message, subMessage }) => {
    setIsSuccessModalOpen({
      show: true,
      message: message,
      subMessage: subMessage,
      isSuccess: false,
    });
  };

  const { formik, isLoading } = useForm({
    selectedCourse: selectedCourse,
    termId: termId,
    onSuccess: onSuccess,
    onError: onError,
  });

  return (
    <>
      <Container>
        <form
          className={styles['course-actions']}
          onSubmit={formik.handleSubmit}
        >
          <TextArea
            label="Comments"
            name="comments"
            value={formik.values.comments}
            onChange={formik.handleChange}
            placeholder="Specify comments"
            error={formik.errors.comments}
            touched={formik.touched.comments}
          />
          <MultipleFileUpload
            title="Attach Supporting Documents"
            subTitle="Supported: pdf, jpg, png, doc & docx Max size 45MB"
            name="supportingDocuments"
            value={formik.values.supportingDocuments}
            onChange={(e) => {
              formik.setFieldValue('supportingDocuments', e.target.value);
            }}
            onBlur={formik.handleBlur}
            error={formik.errors.supportingDocuments}
            touched={formik.touched.supportingDocuments}
            maxSize="45000000"
          />

          <Button
            label="Submit"
            buttonType="submit"
            isLoading={isLoading}
            variant={appConstants.VARIANT.DANGER}
            type={appConstants.TYPE.CHECK}
          />
        </form>
      </Container>
      <SuccessModal
        isOpen={isSuccessModalOpen?.show}
        isSuccess={isSuccessModalOpen.isSuccess}
        text={isSuccessModalOpen?.message}
        subText={isSuccessModalOpen?.subMessage}
        onClose={() =>
          setIsSuccessModalOpen({ show: false, message: '', subMessage: '' })
        }
      />
    </>
  );
};

export default CourseIncompleteForm;
