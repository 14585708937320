import React, { useState } from 'react';
import Container from '../../../../components/hoc/container/Container';
import NewTable from '../../../../components/molecules/new-table/NewTable';
import _endpoints from '../../../../app/constant/endpoints/_endpoints';
import PendingRequestPopup from './factory/PendingRequestPopup';
import Modal from '../../../../components/hoc/modal/Modal';
import styles from './PendingRequest.module.scss';
import AppealModal from './factory/AppealModal';
import AcceptModal from './factory/AcceptModal';
const PendingRequests = () => {
  const [rowData, setRowData] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isAppealModalOpen, setIsAppealModalOpen] = useState({
    accept: false,
    reAppeal: false,
  });
  const [item, setItem] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const handleRowClick = (row) => {
    setIsOpenModal(true);
    setRowData(row);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setRowData({});
  };

  const handleAppeal = (item) => {
    setIsAppealModalOpen((prev) => ({
      ...prev,
      reAppeal: true,
    }));
    setItem(item);
  };

  const handleAccept = (item) => {
    setIsAppealModalOpen((prev) => ({
      ...prev,
      accept: true,
    }));
    setItem(item);
  };

  const onSuccess = () => {
    setRefetch(!refetch);
  };
  return (
    <>
      <Container>
        <NewTable
          api={_endpoints.student.getPendingRequests}
          rowKey="id"
          onRowClick={handleRowClick}
          hasCount
          isAppeal={true}
          actions={{
            hasAppeal: {
              isAppeal: true,
              func1: (item) => {
                handleAppeal(item);
              },
              func2: (item) => {
                handleAccept(item);
              },
            },
          }}
          refetch={refetch}
        />
      </Container>
      <Modal
        onClose={() => setIsOpenModal(false)}
        isOpen={isOpenModal}
        title={rowData?.applicationType}
        withoutLine
        classname={styles.modal}
      >
        <PendingRequestPopup data={rowData} />
      </Modal>
      <AppealModal
        isOpen={isAppealModalOpen.reAppeal}
        onClose={() =>
          setIsAppealModalOpen((prev) => ({
            ...prev,
            reAppeal: false,
          }))
        }
        item={item}
        onSuccess={onSuccess}
      />
      <AcceptModal
        isOpen={isAppealModalOpen.accept}
        onClose={() =>
          setIsAppealModalOpen((prev) => ({
            ...prev,
            accept: false,
          }))
        }
        item={item}
        onSuccess={onSuccess}
      />
    </>
  );
};

export default PendingRequests;
