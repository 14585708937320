import React, { useRef, useState } from 'react';
import styles from './UploadFile.module.scss';
import Title from '../../title/Title';
import Button from '../button/Button';
import appConstants from '../../../../app/constant/constants/appConstants';
import { getSvgByType } from '../../../../app/helpers/forms/GetSvgByType';

const UploadFile = ({ title, subTitle, name, value, onChange, error }) => {
  const [uploadedFileName, setUploadedFileName] = useState(value || '');
  const fileInputRef = useRef(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedFileName(file.name);
      onChange?.({
        target: {
          name,
          value: file,
        },
      });
    }
  };

  const handleFileRemove = () => {
    setUploadedFileName('');
    onChange?.({
      target: {
        name,
        value: '',
      },
    });
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      // console.error('File input is not available.');
    }
  };

  const hasTextWrapper = title || subTitle || uploadedFileName;

  return (
    <div>
      <div className={styles['actions-container']}>
        {hasTextWrapper && (
          <div className={styles['text-wrapper']}>
            {title && <Title text={title} variant="sm-bold-black" />}
            {subTitle && <Title text={subTitle} variant="sm-blue" />}
            {uploadedFileName && (
              <div className={styles['file-name']}>
                <div className={styles.name}>
                  <img src={getSvgByType('fileUpload')} alt="File Icon" />
                  {uploadedFileName}
                </div>
                <img
                  className={styles.remove}
                  src={getSvgByType(appConstants.TYPE.redX)}
                  alt="Remove File"
                  onClick={handleFileRemove}
                />
              </div>
            )}
          </div>
        )}
        <div className={styles['buttons-wrapper']}>
          <Button
            label={
              !uploadedFileName
                ? appConstants.LABEL.UPLOAD_FILE
                : appConstants.LABEL.REPLACE_FILE
            }
            type={appConstants.TYPE.ATTACHMENTS}
            variant={
              !uploadedFileName
                ? appConstants.VARIANT.DEFAULT
                : appConstants.VARIANT.DANGER
            }
            onClick={handleButtonClick} // Use the safe function
          />
          <input
            ref={fileInputRef}
            name={name}
            type="file"
            onChange={handleFileUpload}
            style={{ display: 'none' }}
          />
        </div>
      </div>
      {error && <div className={styles['error-message']}>{error}</div>}
    </div>
  );
};

export default UploadFile;
