import React, { useEffect, useState } from 'react';
import styles from './RegistrarHeader.module.scss';
import ProfileDropdown from '../../../atoms/profile-dropdown/ProfileDropdown';
import MobileHeader from '../../mobile/mobile-header/MobileHeader';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import adminLinks from '../../../../app/constant/navigations/AdminLinks';
import { setTitle } from '../../../../app/globals/pageTitleSlice';
import { useResponsive } from '../../../../assets/styles/breakpoints/breakpoint';
import navigateBack from '../../../../assets/icons/vectors/navigateBack.svg';
import findLink from './factory/findLink';

const RegistrarHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isTablet } = useResponsive();
  const [currentTitle, setCurrentTitle] = useState();
  const headerGlob = useSelector((state) => state.pageTitle);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const currentLink = findLink(adminLinks);

    if (currentLink?.id) {
      setCurrentTitle({ isBack: false, title: currentLink.label });
    } else {
      if (location.pathname.startsWith('/settings')) {
        const title = 'Settings';
        setCurrentTitle({ isBack: false, title });
        setTitle({ isBack: false, title });
      } else {
        let title = location.pathname
          .replace(/[-/]/g, ' ')
          .trim()
          .split(' ')
          .slice(0, 2);
        if (/^\d/.test(title[2])) {
          title.splice(2, 1);
        }

        // this is for the removal of I grade page only (its the only way to prevent the title from being cut use this to add more if the title is larger the 2 words)
        if (location.pathname === '/removal-of-I-grade') {
          title = 'Removal of I Grade';
        } else title = title.join(' ');

        setCurrentTitle({ isBack: false, title });
        setTitle({ isBack: false, title });
      }
    }
  }, [location, headerGlob]);

  const handleFallBack = () => {
    navigate(-1);
  };
  return (
    <>
      {!isTablet ? (
        <div className={styles['registrar-header']}>
          <div className={styles['title']}>
            {currentTitle?.isBack ? (
              <img onClick={handleFallBack} src={navigateBack} alt="" />
            ) : null}

            <p>{currentTitle?.title}</p>
          </div>
          <ProfileDropdown role={user.roleName} name={user.fullName} />
        </div>
      ) : (
        <MobileHeader data={adminLinks} title={currentTitle} />
      )}
    </>
  );
};

export default RegistrarHeader;
