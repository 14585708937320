import React, { useState } from 'react';
import styles from './Profile_TEMP.module.scss';
import UserProfileHeader from './components/profileHeader/UserProfileHeader';
import UserProfileDetails from './components/profileDetails/UserProfileDetails';
import { useSelector } from 'react-redux';
import { useGetQuery } from '../../../services/apiCall';
import _endpoints from '../../../app/constant/endpoints/_endpoints';
import useForm from './components/profileDetails/hook';
import Loader from '../../molecules/table/atoms/loader/Loader';

const userApi = _endpoints.users.getAuthenticatedUser;

const Profile_TEMP = () => {
  const [editMode, setEditMode] = useState(false);
  const { data: user, refetch, isLoading } = useGetQuery({ api: userApi });
  const handleSuccess = () => {
    refetch();
  };
  const { formik } = useForm({ onSuccess: handleSuccess, user });

  return (
    <div className={styles['profile_cont']}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <UserProfileHeader
            data={user}
            editMode={editMode}
            setEditMode={setEditMode}
            formik={formik}
          />
          <UserProfileDetails
            data={user}
            editMode={editMode}
            setEditMode={setEditMode}
            refetch={refetch}
            formik={formik}
          />
        </>
      )}
    </div>
  );
};

export default Profile_TEMP;
