import { useFormik } from 'formik';
import * as Yup from 'yup';
import { usePostMutation } from '../../../../../../../../services/apiCall';
import { handleError } from '../../../../../../../molecules/new-table/NewTable';
import { useMemo } from 'react';
import _endpoints from '../../../../../../../../app/constant/endpoints/_endpoints';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { reRenderHeader } from '../../../../../../../../app/globals/storeSlices/userSlice';
import { errorMessage } from '../../../../../../../hoc/toast/Toast';

const apiUrl = _endpoints.application.courseIncomplete;

const getValidationSchema = () =>
  Yup.object().shape({
    comments: Yup.string().required('Comment is required'),
    supportingDocuments: Yup.array()
      .min(1, 'At least one supporting document is required')
      .of(
        Yup.mixed()
          .required('Supporting document is required')
          .test('fileType', 'Unsupported file format', (value) => {
            return (
              value &&
              [
                'application/pdf',
                'image/jpeg',
                'image/tiff',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              ].includes(value.type)
            );
          })
          .test('fileSize', 'File must be less than 45MB', (value) => {
            return value && value.size <= 45 * 1024 * 1024;
          })
      ),
  });

const useForm = ({ selectedCourse, termId, onSuccess, onError }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [post, { isLoading }] = usePostMutation();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    // Initialize FormData
    const formData = new FormData();
    formData.append('Comments', values.comments);
    formData.append('TermId', termId);
    formData.append('CourseId', selectedCourse?.courseId);
    formData.append('SectionId', selectedCourse?.section?.id);

    values.supportingDocuments.forEach((file, index) => {
      formData.append('SupportingDocuments', file);
    });

    const payload = { apiUrl, data: formData, type: 'formData' };

    try {
      const response = await post(payload).unwrap();
      if (response?.allow) {
        resetForm();
        onSuccess({
          show: true,
          message: 'Submitted Successfully',
          subMessage: 'Your request needs to be verify by registrar',
        });
        dispatch(reRenderHeader());
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      } else {
        onError({
          show: true,
          message: response.message,
          subMessage: '',
        });
      }
    } catch (error) {
      handleError();
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = useMemo(
    () => ({
      comments: '',
      supportingDocuments: [],
    }),
    []
  );

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(),
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return { formik, isLoading };
};

export default useForm;
