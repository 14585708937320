import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

const initialState = {
  id: null,
  name: '',
  email: '',
  roleName: '',
  isAuthenticated: false,
  token: null,
  tokenExpiry: null,
  fullName: '',
  username: '',
  sideBarPages: [],
  permissions: [],
  message: null,
  userType: null,
  count: 0,
  pendingRequests: 0,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      state.isAuthenticated = true;
      state.token = action.payload.token;
      state.tokenExpiry = action.payload.tokenExpiry;
      state.fullName = action.payload.fullName;
      state.username = action.payload.username;
      state.sideBarPages = action.payload.sideBarPages;
      state.message = action.payload.message || null;
      state.roleName = action.payload.roleName;
      state.userType = action.payload.userType;
    },
    setPermissions(state, action) {
      state.permissions = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    reRenderHeader(state) {
      state.pendingRequests += 1;
    },
    clearUser(state) {
      state.id = null;
      state.name = '';
      state.email = '';
      state.roleName = '';
      state.isAuthenticated = false;
      state.token = null;
      state.tokenExpiry = null;
      state.fullName = '';
      state.username = '';
      state.sideBarPages = [];
      state.permissions = [];
      state.message = null;
      state.userType = null;
      state.count = 0;
      state.pendingRequests = 0;
    },
    updateUserDetails(state, action) {
      state.firstName = action.payload.firstName || state.name;
      state.lastName = action.payload.lastName || state.name;
      state.email = action.payload.email || state.email;
    },
  },
});

const selectPermissions = (state) =>
  state?.user?.permissions?.permissions || [];

export const getPermissionKeys = createSelector(
  [selectPermissions],
  (permissions) => permissions.map((permission) => permission.actionTextKey)
);

export const {
  setUser,
  setPermissions,
  clearUser,
  setCount,
  reRenderHeader,
  updateUserDetails,
} = userSlice.actions;
export default userSlice.reducer;
