import React, { useState } from 'react';
import styles from './AppealModal.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGetQuery, usePostMutation } from '../../../../../services/apiCall';
import { errorMessage } from '../../../../../components/hoc/toast/Toast';
import Loader from '../../../../../components/molecules/table/atoms/loader/Loader';
import Modal from '../../../../../components/hoc/modal/Modal';
import { formatDateTime } from '../../../../../app/helpers/forms/formatDate';
import Tag from '../../../../../components/atoms/tag/Tag';
import Title from '../../../../../components/atoms/title/Title';
import TextArea from '../../../../../components/atoms/forms/text-area/TextArea';
import MultipleFileUpload from '../../../../../components/atoms/forms/upload-file/MultipleFileUpload';
import DocumentView from '../../../../../components/atoms/document-view/DocumentView';
import Accordion from '../../../../../components/molecules/accordion/Accordion';
import SuccessModal from '../../../../../components/hoc/success-modal/SuccessModals';
import appConstants from '../../../../../app/constant/constants/appConstants';
import Button from '../../../../../components/atoms/forms/button/Button';
import { getSvgByType } from '../../../../../app/helpers/forms/GetSvgByType';
import _endpoints from '../../../../../app/constant/endpoints/_endpoints';

const requestApi = _endpoints.student.getRequestById;
const postApi = _endpoints.approver.reAppealRequest;

const AppealForm = ({ onClose, isOpen, item, onSuccess }) => {
  const [post] = usePostMutation();
  const [isSubmitting, setISSubmitting] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const { data: requestData, isLoading: requestLoading } = useGetQuery(
    {
      api: requestApi,
      params: [item?.id],
    },
    { skip: !item?.id }
  );

  const formik = useFormik({
    initialValues: {
      SupportingDocuments: [],
      Comments: '',
    },
    validationSchema: Yup.object({
      Comments: Yup.string().required('Required'),
      SupportingDocuments: Yup.array().of(Yup.mixed()),
    }),
    onSubmit: async (values, { resetForm }) => {
      setISSubmitting(true);
      const formData = new FormData();
      formData.append('RequestId', item?.id);
      formData.append('Comments', values?.Comments);
      if (values.SupportingDocuments.length > 0) {
        values.SupportingDocuments.forEach((file) => {
          formData.append('SupportingDocuments', file);
        });
      }

      const payload = {
        apiUrl: postApi,
        data: formData,
        type: 'formData',
      };
      try {
        await post(payload).unwrap();
        onClose();
        setIsSuccessModalOpen(true);
        resetForm();
        onSuccess();
      } catch (error) {
        errorMessage('Something went wrong!');
      } finally {
        setISSubmitting(false);
      }
    },
  });

  if (requestLoading) {
    return <Loader />;
  }
  const customizedTitles = [
    {
      key: requestData?.request?.course?.courseCode,
      title: 'Course Code',
    },
    {
      key: requestData?.request?.section?.sectionName,
      title: 'Section',
    },

    {
      key: requestData?.section?.instructorName,
      title: 'Instructor',
    },
    {
      key: requestData?.request?.termName,
      title: requestData?.request?.requestType == 0 ? 'Appeal' : 'Term',
    },
    {
      key: requestData?.request?.appealType,
      title: 'Nature of Appeal',
    },
    {
      key: requestData?.request?.mobileNumber,
      title: 'Mobile',
    },
    {
      key: requestData?.request?.newGrade,
      color: 'green',
      title: 'New Grade',
    },
    {
      key:
        requestData?.request?.requestType == 6
          ? requestData?.request?.otherReasons
          : null,
      color: 'red',
      title: 'Old Grade',
    },
    {
      key: requestData?.request?.numericGrade,
      color: 'red',
      title: 'Current Grade (Numeric)',
    },
    {
      key: requestData?.request?.numericGrade,
      color: 'red',
      title: 'Current Grade (Letter)',
    },
    {
      key: requestData?.request?.makeupDate,
      title: 'Make Exam Date and Time',
      dateTime: true,
    },
    {
      key: requestData?.request?.pendingCourseWork,
      title:
        requestData?.request?.requestType == 0
          ? 'Condition for next semester'
          : 'Pending Course Work',
    },
    {
      key: requestData?.request?.withdrawlReason,
      title: 'Withdrawal Reason',
    },

    {
      key:
        requestData?.request?.metInstructor == true
          ? 'Yes'
          : requestData?.request?.metInstructor == false
          ? 'No'
          : null,
      title: 'Have you met the student?',
    },
  ];

  const renderTitles = (titles) => {
    return titles
      .filter((item) => item.key)
      .map((item, index) => (
        <div
          key={index}
          style={{ color: item.color }}
          className={styles.customizedTitle}
        >
          <p>{item.title}</p>
          <span> {item.dateTime ? formatDateTime(item.key) : item.key}</span>
        </div>
      ));
  };
  return (
    <>
      <Modal
        title={item?.applicationType}
        isOpen={isOpen}
        onClose={onClose}
        classname={styles.modal}
      >
        <div className={styles['request-cont']}>
          <div className={styles['date-cont']}>
            <p>
              Date Created:
              <span>
                {' '}
                {formatDateTime(requestData?.request?.dateCreated)}
              </span>{' '}
            </p>
            <Tag
              label={requestData?.request?.statusName}
              variant={requestData?.request?.statusName}
            />
          </div>
          {renderTitles(customizedTitles)?.length > 0 && (
            <div className={styles.customizedTitle_cont}>
              {renderTitles(customizedTitles)}
            </div>
          )}
          {(requestData?.request?.comments ||
            requestData?.request?.otherReasons) && (
            <div className={styles['request-cont']}>
              <Title text="Reason" />
              <div className={styles['request-subcont']}>
                <img src={getSvgByType('reasonPaper')} />
                <p>
                  {requestData?.request?.comments ||
                    requestData?.request?.otherReasons}
                </p>
              </div>
            </div>
          )}
          {requestData?.withdrawalReasons?.length > 0 && (
            <div className={styles['request-cont']}>
              <Title text="Withdrawal Reason" />
              <div className={styles['request-subcont']}>
                <img src={getSvgByType('reasonPaper')} />

                <div>
                  {requestData?.withdrawalReasons?.map((r) => {
                    return <p>{r.reason}</p>;
                  })}
                </div>
              </div>
            </div>
          )}
          {/* {requestData?.request?.makeupDate && (
            <div className={styles['request-cont']}>
              <Title text={'Makeup Date'} />
              <div className={styles['request-subcont']}>
                <img src={getSvgByType('reasonPaper')} />
                <p>{formatDateTime(requestData?.request?.makeupDate)}</p>
              </div>
            </div>
          )} */}
          <TextArea
            label="Comment"
            placeholder="Enter your comment here"
            name="Comments"
            value={formik.values.Comments}
            onChange={formik.handleChange}
            error={formik.errors.Comments}
            touched={formik.touched.Comments}
          />
          <MultipleFileUpload
            title="Attach Supporting Documents"
            subTitle="Supported: pdf, jpg, png, doc & docx Max size 45MB"
            name="SupportingDocuments"
            value={formik.values.SupportingDocuments}
            onChange={(e) => {
              formik.setFieldValue('SupportingDocuments', e.target.value);
            }}
            onBlur={formik.handleBlur}
            error={formik.errors.SupportingDocuments}
            touched={formik.touched.SupportingDocuments}
            maxSize="45000000"
          />{' '}
          {requestData?.files?.length > 0 ? (
            <div className={styles['request-cont']}>
              <Title text="Documents" />

              <div className={styles['documents-container']}>
                {requestData?.files?.map((doc) => {
                  return (
                    <DocumentView
                      fileName={doc.fileName}
                      fileType={doc.fileType}
                      hide
                    />
                  );
                })}
              </div>
            </div>
          ) : (
            ''
          )}
          <div className={styles['request-cont']}>
            <Title text="Request Log" />
            {requestData?.approvalRequest?.map((details) => {
              return (
                <Accordion
                  title={details.message}
                  type={details.approved ? 'approved' : 'notApproved'}
                  className={styles['accordion_color']}
                  date={details?.dateApproved}
                >
                  <div>
                    <div className={styles.detailsCont}>
                      <img
                        src={getSvgByType(appConstants.TYPE.PROFILE_MALE)}
                        alt="profile"
                      />
                      <div>
                        <Title text={details.userName} />
                        <Title text={details.role} variant="sm" />
                      </div>
                    </div>
                    {details?.studentReason && (
                      <div className={styles.detailsCont}>
                        <Title text="Reason:" variant="sm" />
                        <Title
                          text={details?.studentReason}
                          variant="sm-blue"
                        />
                      </div>
                    )}
                  </div>
                </Accordion>
              );
            })}
          </div>
          <div className={styles.actions}>
            <Button label={appConstants.CANCEL} onClick={onClose} />
            <Button
              label="Re-appeal"
              variant="blue"
              type="check"
              onClick={formik.handleSubmit}
              isLoading={isSubmitting}
            />
          </div>
        </div>
      </Modal>
      <SuccessModal
        isOpen={isSuccessModalOpen}
        isSuccess
        text={'Reapply Request'}
        timeout={1000}
        subText={'Your request has been processed. Thank you!'}
        onClose={() => setIsSuccessModalOpen(false)}
      />
    </>
  );
};

export default AppealForm;
