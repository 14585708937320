import React, { useState } from 'react';
import styles from '../Components.module.scss';
import appConstants from '../../../../../../app/constant/constants/appConstants';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
import { errorMessage } from '../../../../../../components/hoc/toast/Toast';
import { useFormik } from 'formik';
import Container from '../../../../../../components/hoc/container/Container';
import TextArea from '../../../../../../components/atoms/forms/text-area/TextArea';
import Button from '../../../../../../components/atoms/forms/button/Button';
import SuccessModal from '../../../../../../components/hoc/success-modal/SuccessModals';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { usePostMutation } from '../../../../../../services/apiCall';
import objectToFormData from '../../../../../../app/helpers/forms/objectToFormData';

const RegistrarForm = ({ onSubmit, data, statusName }) => {
  const navigate = useNavigate();
  const [post] = usePostMutation();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [actionType, setActionType] = useState('');

  const formik = useFormik({
    initialValues: {
      comment: '',
    },
    validationSchema: Yup.object({
      comment: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      handleApprove();
    },
  });

  const handleApprove = async () => {
    setIsApproving(true);
    setActionType('approve');

    const payload = {
      RequestId: data?.request.id,
      Comments: formik.values.comment,
    };

    const formData = objectToFormData(payload);

    try {
      await post({
        apiUrl: _endpoints.approver.approveRequest,
        data: formData,
        type: 'formData',
      }).unwrap();
      setIsSuccessModalOpen(true);
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } catch (error) {
      errorMessage('Something went wrong!');
    } finally {
      setIsApproving(false);
    }
  };

  return (
    <>
      <div className={styles['requests-container']}>
        <Container>
          <form
            className={styles.form}
            onSubmit={(e) => {
              e.preventDefault();
              formik.validateForm().then(() => formik.handleSubmit());
            }}
          >
            <TextArea
              label={appConstants.LABEL.COMMENT}
              placeholder={appConstants.PLACEHOLDER.ENTER_COMMENT}
              type={appConstants.TYPE.NOTE}
              name="comment"
              value={formik.values.comment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.comment}
              touched={formik.touched.comment}
            />

            <Button
              label="Process Request"
              type={appConstants.TYPE.CHECK}
              variant={appConstants.VARIANT.DEFAULT}
              onClick={formik.handleSubmit}
              isLoading={isApproving}
            />

            <SuccessModal
              isOpen={isSuccessModalOpen}
              isSuccess
              text={
                actionType === 'approve'
                  ? 'Request Approved'
                  : 'Request Rejected'
              }
              timeout={1000}
              subText={
                actionType === 'approve'
                  ? 'Request has been reviewed and given approval.'
                  : 'Request has been reviewed and rejected.'
              }
            />
          </form>
        </Container>
      </div>
    </>
  );
};

export default RegistrarForm;
