import React from 'react';
import StudentProfileWrapper from '../../../../../components/hoc/students/student-profile-wrapper/StudentProfileWrapper';
import CourseOverload_TEMP from '../../../../../components/templates/students/applications/course-overload/CourseOverload_TEMP';
import useCheckIfIsAllowed from '../hooks/hook';

const CourseCommon = ({ api, title }) => {
  useCheckIfIsAllowed();
  return (
    <StudentProfileWrapper api={api} title={title} isStudent>
      <CourseOverload_TEMP title={title} />
    </StudentProfileWrapper>
  );
};

export default CourseCommon;
