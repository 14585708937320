export const checkOnlineStatus = (redirectUrl = '/offline') => {
  if (!navigator.onLine) {
    // window.location.href = redirectUrl;
  }
};

window.addEventListener('offline', () => {
  window.location.href = '/offline';
});

window.addEventListener('online', () => {
  // console.log('You are back online!');
});
