import React, { useEffect, useState } from 'react';
import styles from './EmailNotificationCard.module.scss';
import { getSvgByType } from '../../../app/helpers/forms/GetSvgByType';
import appConstants from '../../../app/constant/constants/appConstants';
import Loader from '../../molecules/table/atoms/loader/Loader';
import { format } from 'date-fns';
import noDataSvg from '../../molecules/new-table/factory/noData.svg';

const EmailNotificationCard = ({
  title,
  applicationName,
  subject,
  toEmail,
  termName,
  term,
  isActive,
  onClick,
  date,
  attachments,
}) => {
  return (
    <div
      className={`${styles['email-notification-card']} ${
        isActive ? styles.active : ''
      }`}
      onClick={onClick}
    >
      <img
        src={getSvgByType(appConstants.TYPE.MAIL_EMPTY)}
        alt={title}
        className={styles['card-image']}
      />
      <div className={styles['card-content']}>
        <div className={styles['to']}>
          <p className={styles['card-title']}>To: {toEmail}</p>
          <p className={styles['card-text']}>{date}</p>
        </div>

        {subject ? <p className={styles['card-subTitle']}>{subject}</p> : null}

        <div className={styles['reminder-container']}>
          <div className={styles['reminder-list']}>
            {/* <div className={styles['reminder-item']}>
              <p className={styles['text']}>Reminder</p>
            </div> */}
            {attachments?.length > 0 ? (
              <div className={styles['attachment-item']}>
                <img src={getSvgByType(appConstants.TYPE.ATTACHMENTS)} alt="" />

                <p>+{attachments?.length}</p>
              </div>
            ) : null}
            {termName ? (
              <div className={styles['reminder-item']}>
                <p className={styles['text']}>{termName}</p>
              </div>
            ) : null}

            {applicationName ? (
              <div className={styles['reminder-item']}>
                <p className={styles['text']}>{applicationName}</p>
              </div>
            ) : null}
            {term ? (
              <div className={styles['reminder-item']}>
                <p className={styles['text']}>{term}</p>
              </div>
            ) : null}
            {/* <div className={styles['reminder-item']}>
              <img
                src={getSvgByType(appConstants.TYPE.CALENDAR_EMPTY)}
                alt={appConstants.TYPE.ADD}
                className={styles['icon']}
              />
              <p className={styles['text']}>test</p>
            </div> */}
          </div>
          {/* <div className={styles['reminder-item']}>
            <img
              src={getSvgByType(appConstants.TYPE.CALENDAR_EMPTY)}
              alt={appConstants.TYPE.ADD}
              className={styles['icon']}
            />
            <p className={styles['text']}>test</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};
const formatDate = (dateString) => {
  try {
    const date = new Date(dateString);
    return format(date, 'MM/dd/yyyy');
  } catch (e) {
    return '';
  }
};
const EmailNotificationList = ({ getNotification, data, isLoading }) => {
  const [activeCardId, setActiveCardId] = useState(null);

  const handleCardClick = (notification) => {
    setActiveCardId(notification.id);
    if (getNotification) getNotification(notification);
  };

  useEffect(() => {
    setActiveCardId(data?.items[0]?.id);
  }, [data]);

  return (
    <div className={styles['emails-bar']}>
      {isLoading ? (
        <Loader isComponent />
      ) : data?.items?.length > 0 ? (
        data.items.map((notification) => (
          <EmailNotificationCard
            key={notification.id}
            applicationName={notification.applicationName}
            term={notification.termName}
            attachments={notification?.attachments}
            emailContent={notification.emailContent}
            subject={notification.subject}
            toEmail={notification.toEmail}
            isActive={notification.id === activeCardId}
            onClick={() => handleCardClick(notification)}
            date={formatDate(notification.emailDate)}
          />
        ))
      ) : (
        <div className={styles['no-data-container']}>
          <img src={noDataSvg} alt="" />
          <p>No emails found</p>
        </div>
      )}
    </div>
  );
};
export { EmailNotificationCard, EmailNotificationList };
