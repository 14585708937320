import React, { useEffect, useState } from 'react';
import styles from './Components.module.scss';
import appConstants from '../../../../../app/constant/constants/appConstants';
import Accordion from '../../../../../components/molecules/accordion/Accordion';
import Container from '../../../../../components/hoc/container/Container';
import Tag from '../../../../../components/atoms/tag/Tag';
import SuccessModal from '../../../../../components/hoc/success-modal/SuccessModals';
import { getSvgByType } from '../../../../../app/helpers/forms/GetSvgByType';
import Title from '../../../../../components/atoms/title/Title';
import WIthdrawalFormsWrapper from './WithdrawalForms/WIthdrawalFormsWrapper';
import CourseIncompleteWrapper from './courseIncompleteForms/CourseIncompleteWrapper';
import SimpleTable from '../../../../../components/molecules/simple-table/SimpleTable';
import GradeAppealWrapper from './gradeAppealForms/GradeAppealWrapper';
import { format } from 'date-fns';
import GradeChangeWrapper from './gradeChangeForms/GradeChangeWrapper';
import RemovalOfIWrapper from './removalOfIGrade/RemovalOfIWrapper';
import TextArea from '../../../../../components/atoms/forms/text-area/TextArea';
import DocumentView from '../../../../../components/atoms/document-view/DocumentView';
import { formatDateTime } from '../../../../../app/helpers/forms/formatDate';
import AcademicStandingAppealWrapper from './academicStandingAppeal/AcademicStandingAppealWrapper';
import CourseOverloadWrapper from './courseOverload/CourseOverloadWrapper';
import { useSelector } from 'react-redux';
import { getPermissionKeys } from '../../../../../app/globals/storeSlices/userSlice';
import NewTable from '../../../../../components/molecules/new-table/NewTable';

// const AppealTable = ({ application }) => (
//   <div className={styles.table}>
//     <p className={styles.title}>{application?.applicationType}</p>
//     <p className={styles.text}>{application?.termName}</p>
//     <p className={styles.text}>
//       {new Date(application?.dateCreated).toLocaleDateString()}
//     </p>
//     <Tag
//       label={application.statusName}
//       variant={application.statusName.toLowerCase()}
//     />
//   </div>
// );

const formatDate = (dateString) => {
  try {
    const date = new Date(dateString);
    return format(date, 'MM/dd/yyyy hh:mm a');
  } catch (e) {
    return '';
  }
};

const WarningModal = ({ isOpen, onClose, onSubmit }) => (
  <SuccessModal isOpen={isOpen} onClose={onClose} />
);
const defaultTableHeaderData = ['Course Code', 'Section', 'Term', 'Instructor'];

const PendingRequestsTab = ({ data }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const permissionKeys = useSelector(getPermissionKeys);
  const closeModal = () => {
    setModalOpen(false);
  };

  const handleRejectRequest = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const newEntry =
      data?.request?.requestType !== 0
        ? [
            data?.request?.course?.courseCode,
            data?.request?.section?.sectionName,
            data?.request?.termName,
            data?.request?.section?.instructorName,
          ]
        : [
            data?.request?.termName,
            data?.request?.appealType,
            data?.request?.mobileNumber,
          ];
    setTableData((prevData) => [...prevData, newEntry]);
  }, [data]);

  const FormWrapper = (type) => {
    switch (type) {
      case 3:
        return <WIthdrawalFormsWrapper data={data} />;
      case 2:
        return <CourseIncompleteWrapper data={data} />;
      case 5:
        return <GradeAppealWrapper data={data} />;
      case 6:
        return <GradeChangeWrapper data={data} />;
      case 7:
        return <RemovalOfIWrapper data={data} />;
      case 0:
        return <AcademicStandingAppealWrapper data={data} />;
      case 4:
        return <CourseOverloadWrapper data={data} />;
      default:
        return null;
    }
  };

  const customizedTitles = [
    {
      key: data?.request?.newGrade,
      color: 'green',
      title: 'New Grade',
    },
    {
      key: data?.request?.requestType == 6 ? data?.request?.otherReasons : null,
      color: 'red',
      title: 'Old Grade',
    },
    {
      key: data?.request?.numericGrade,
      color: 'red',
      title: 'Current Grade (Numeric)',
    },
    {
      key: data?.request?.numericGrade,
      color: 'red',
      title: 'Current Grade (Letter)',
    },
    {
      key: data?.request?.makeupDate,
      title: 'Make Exam Date and Time',
      dateTime: true,
    },
    {
      key: data?.request?.pendingCourseWork,
      title:
        data?.request?.requestType == 0
          ? 'Condition for next semester'
          : 'Pending Course Work',
    },
    {
      key: data?.request?.withdrawlReason,
      title: 'Withdrawal Reason',
    },
    {
      key:
        data?.request?.scholarshipVerified == true
          ? 'Yes'
          : data?.request?.scholarshipVerified == false
          ? 'No'
          : null,
      title: 'Scholarship verified with vendor',
    },
    {
      key:
        data?.request?.coveredByAgency == true
          ? 'Yes'
          : data?.request?.coveredByAgency == false
          ? 'No'
          : null,
      title: 'Course Overload covered by agency ',
    },
    {
      key:
        data?.request?.metInstructor == true
          ? 'Yes'
          : data?.request?.metInstructor == false
          ? 'No'
          : null,
      title: 'Have you met with your instructor?',
    },
  ];
  const renderTitles = (titles) => {
    return titles
      .filter((item) => item.key)
      .map((item, index) => (
        <div
          key={index}
          style={{ color: item.color }}
          className={styles.customizedTitle}
        >
          <p>{item.title}</p>
          <span> {item.dateTime ? formatDateTime(item.key) : item.key}</span>
        </div>
      ));
  };
  const courseTableData = data
    ? {
        items: data?.studentEnrollment || [],
        tableView:
          [
            {
              ...data?.coursetableView[0],
              hasFilter: false,
              hasPaging: false,
              hasSearch: false,
            },
          ] || [],
        totalCount: data?.studentEnrollment?.length,
        pagNumber: 1,
        pageSize: 10,
      }
    : null;

  return (
    <div className={styles.requests}>
      <div className={styles['requests-container']}>
        <Container>
          <div className={styles['requests-container']}>
            {data?.request?.requestType !== 0 ? (
              <SimpleTable
                tableHeaderData={defaultTableHeaderData}
                tableData={tableData}
              />
            ) : (
              <SimpleTable
                tableHeaderData={['Appeal Term', 'Nature of Appeal', 'Mobile']}
                tableData={tableData}
              />
            )}
            {renderTitles(customizedTitles)?.length > 0 && (
              <div className={styles.customizedTitle_cont}>
                {renderTitles(customizedTitles)}
              </div>
            )}

            {data?.withdrawalReasons?.length > 0 ? (
              <div className={styles.customizedTitle}>
                {' '}
                <p>Withdrawal Reason</p>
                <div className={styles['withdrawal-reasons']}>
                  {data?.withdrawalReasons?.map((r) => {
                    return <span>{r.reason}</span>;
                  })}
                </div>
              </div>
            ) : (
              ''
            )}

            {(data?.request?.otherReasons || data?.request?.comments) && (
              <div className={styles.customizedTitle}>
                <p>
                  {data?.request?.requestType == 6 ||
                  data?.request?.requestType == 7
                    ? 'Instructor Comment'
                    : data?.request?.otherReasons
                    ? 'Reason'
                    : 'Student Comment'}
                </p>
                <span>
                  {data?.request?.requestType == 6
                    ? data?.request?.comments
                    : data?.request?.otherReasons || data?.request?.comments}
                </span>
              </div>
            )}
          </div>
        </Container>
        {data?.request?.requestType === 4 && (
          <Accordion title="Schedule" isExit>
            <NewTable data={courseTableData} />
          </Accordion>
        )}
        {(data?.request?.hideDocuments == false ||
          data?.request?.hideDocuments == null) &&
          data?.files?.length > 0 && (
            <Accordion title="Documents" isExit>
              <div className={styles['documents-container']}>
                {data?.files?.length > 0
                  ? data?.files?.map((doc) => {
                      return (
                        <DocumentView
                          key={doc?.id}
                          fileId={doc?.id}
                          fileName={doc?.fileName}
                          fileType={doc?.fileType}
                          hide={!permissionKeys?.includes('VIEW_DOCUMENTS')}
                        />
                      );
                    })
                  : 'No documents'}
              </div>
            </Accordion>
          )}
        {FormWrapper(data?.request?.requestType)}
      </div>
      <div className={styles['requests-container']}>
        {data?.approvalRequest?.length > 0 ? (
          <Container>
            <div className={styles.logs}>
              <p className={styles['text']}>{appConstants.REQUEST_LOG}</p>
            </div>
          </Container>
        ) : null}

        {data?.approvalRequest?.map((details) => {
          return (
            <Accordion
              title={details.message}
              type={details.approved ? 'approved' : 'notApproved'}
              date={formatDate(details?.dateApproved)}
            >
              <div>
                <div className={styles.detailsCont}>
                  <img
                    src={getSvgByType(appConstants.TYPE.PROFILE_MALE)}
                    alt="profile"
                  />
                  <div className={styles.detailsSubCont}>
                    <Title text={details.userName} />
                    <Title text={details.role} variant="sm-blue-bold" />
                  </div>
                </div>

                {details?.notes && (
                  <div className={styles.detailsCont}>
                    <Title text="Comment:" variant="sm" />
                    <Title text={details?.notes} variant="sm-blue" />
                  </div>
                )}
                {details?.studentReason && (
                  <div className={styles.detailsCont}>
                    <Title text="Reason:" variant="sm" />
                    <Title text={details?.studentReason} variant="sm-blue" />
                  </div>
                )}
              </div>
            </Accordion>
          );
        })}
      </div>
      <WarningModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={handleRejectRequest}
      />
    </div>
  );
};

export default PendingRequestsTab;
