import React, { useState, useRef, useCallback, useId, useEffect } from 'react';
import styles from './DateSelect.module.scss';
import Label from '../../label/Label';
import { useDropdown } from '../factory/Factory';
import { getSvgByType } from '../../../../../app/helpers/forms/GetSvgByType';
import appConstants from '../../../../../app/constant/constants/appConstants';
import { format, parseISO } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const formatDateForInput = (dateString) => {
  if (!dateString) return '';
  return format(parseISO(dateString), 'yyyy-MM-dd');
};

const DateTimeSelect = ({
  label,
  placeholder,
  name,
  value: initialValue,
  onChange,
  error,
  touched,
  style,
  classname,
  startDate,
  endDate,
  ...props
}) => {
  const id = useId();
const datePickerRef = useRef(null);
  const formattedInitialValue = formatDateForInput(initialValue);
  const formattedStartDate = formatDateForInput(startDate);
  const formattedEndDate = formatDateForInput(endDate);

  const [value, setValue] = useState(formattedInitialValue || '');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setValue(formattedInitialValue);
  }, [formattedInitialValue]);

  const handleChange = useCallback(
    (date) => {
      const newValue = format(date, 'yyyy-MM-dd HH:mm');
      setValue(newValue);
      if (onChange) {
        onChange(name, newValue);
      }
    },
    [name, onChange]
  );

  const handleWrapperClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setFocus(); 
    }
  };

  return (
    <div
      className={`${styles['select-date-container']} ${classname}`}
      style={style}
    >
      {label && <Label text={label} htmlFor={id} />}
      <div
        className={styles['select-wrapper']}
        tabIndex={0}
        onClick={handleWrapperClick}
      >
        <div className={styles['select-display']}>
          <img
            src={getSvgByType(appConstants.TYPE.DATE)}
            alt={placeholder}
            className={styles['dateTime-img']}
          />
          <DatePicker
            id={id}
            ref={datePickerRef}
            selected={value ? parseISO(value) : null}
            onChange={handleChange}
            placeholder={placeholder}
            className={`${styles['select']} ${!value && styles['placeholder']}`}
            minDate={formattedStartDate ? parseISO(formattedStartDate) : null}
            maxDate={formattedEndDate ? parseISO(formattedEndDate) : null}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="MMMM d, yyyy h:mm aa"
            timeCaption="Time"
            placeholderText="Select a date and time"
            isOpen={isOpen}
            onClickOutside={() => setIsOpen(false)}
            {...props}
          />
        </div>
      </div>
      {error && touched && (
        <div className={styles['error-message']}>{error}</div>
      )}
    </div>
  );
};

export default DateTimeSelect;
