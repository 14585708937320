import React, { useEffect } from 'react';
import StudentProfileWrapper from '../../../../../components/hoc/students/student-profile-wrapper/StudentProfileWrapper';
import AcademyStandingApealTemplate from '../../../../../components/templates/students/applications/academy-standing-apeal-template/AcademyStandingApealTemplate';
import { useGetQuery } from '../../../../../services/apiCall';
import _endpoints from '../../../../../app/constant/endpoints/_endpoints';
import useCheckIfIsAllowed from '../hooks/hook';

const getCurrentStudentApi = _endpoints.student.getStudent;

const AcademyStandingApeal = () => {
  useCheckIfIsAllowed();
  const {
    data: studentData,
    refetch,
    isLoading,
  } = useGetQuery({
    api: getCurrentStudentApi,
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <StudentProfileWrapper
      title="Academic Standing Appeal"
      data={studentData}
      isLoading={isLoading}
      isStudent={false}
    >
      <AcademyStandingApealTemplate data={studentData} />
    </StudentProfileWrapper>
  );
};

export default AcademyStandingApeal;
