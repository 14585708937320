import React from 'react';
import styles from '../NewSetupTermForm.module.scss';
import DateSelect from '../../../../../../../atoms/forms/select/date/DateSelect';
import * as Yup from 'yup';

export const form1ValidationSchema = Yup.object().shape({
  waitList_StartDate: Yup.date().required('Waitlist start date is required'),
  waitList_EndDate: Yup.date()
    .required('Waitlist end date is required')
    .min(Yup.ref('waitList_StartDate'), 'End date must be after start date'),
});

export const form1InitialValues = {
  waitList_StartDate: '',
  waitList_EndDate: '',
};

export const renderFieldsForId1 = (formik) => {
  const handleDateChange = (name, value) => {
    formik.setFieldValue(name, value);
  };
  return (
    <div className={styles['date-grid']}>
      <DateSelect
        label="Waitlist start date"
        type="date"
        placeholder="Select date"
        name="waitList_StartDate"
        onChange={handleDateChange}
        onBlur={formik.handleBlur}
        value={formik.values.waitList_StartDate}
        error={
          formik.touched.waitList_StartDate && formik.errors.waitList_StartDate
        }
        touched={formik.touched.waitList_StartDate}
        classname={styles.date}
      />
      <DateSelect
        label="Waitlist end date"
        type="date"
        placeholder="Select date"
        name="waitList_EndDate"
        onChange={handleDateChange}
        onBlur={formik.handleBlur}
        value={formik.values.waitList_EndDate}
        error={
          formik.touched.waitList_EndDate && formik.errors.waitList_EndDate
        }
        touched={formik.touched.waitList_EndDate}
        classname={styles.date}
        startDate={formik.values.waitList_StartDate}
      />
    </div>
  );
};
