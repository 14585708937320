import { useState } from 'react';
import { useFormik } from 'formik';
import { usePostMutation } from '../../../../../../../services/apiCall';
import _endpoints from '../../../../../../../app/constant/endpoints/_endpoints';
import {
  form1ValidationSchema,
  form1InitialValues,
} from './application-forms/form_1';
import {
  form2ValidationSchema,
  form2InitialValues,
} from './application-forms/form_2';
import {
  form3ValidationSchema,
  form3InitialValues,
} from './application-forms/form_3';
import {
  form6ValidationSchema,
  form6InitialValues,
} from './application-forms/form_6';
import { handleError } from '../../../../../../molecules/table/Table';
import { successMessage } from '../../../../../../hoc/toast/Toast';
import {
  form5InitialValues,
  form5ValidationSchema,
} from './application-forms/form_0';
import {
  form4InitialValues,
  form4ValidationSchema,
} from './application-forms/form_4';

const apiUrl = _endpoints.term.postTerms;

const useForm = ({ activeApplication, data, type, onSuccess }) => {
  const [key, setKey] = useState(0);

  const [post, { isLoading, isSuccess }] = usePostMutation();

  const getValidationSchema = () => {
    switch (activeApplication?.id) {
      case 0:
        return form5ValidationSchema;
      case 1:
        return form1ValidationSchema;
      case 2:
        return form2ValidationSchema;
      case 3:
        return form3ValidationSchema;
      case 4:
        return form4ValidationSchema;
      case 6:
        return form6ValidationSchema;
      default:
        return form1ValidationSchema;
    }
  };

  const getInitialValues = (values) => {
    switch (activeApplication?.id) {
      case 1:
        if (type == 'edit') {
          return {
            ...values,
          };
        } else {
          return { form1InitialValues };
        }
      case 2:
        if (type === 'edit') {
          return {
            ...values,
          };
        } else {
          return { form2InitialValues };
        }
      case 3:
        if (type === 'edit') {
          return {
            ...values,
          };
        } else {
          return { form3InitialValues };
        }
      case 4:
        if (type === 'edit') {
          return {
            ...values,
          };
        } else {
          return { form4InitialValues };
        }
      case 0:
        if (type === 'edit') {
          return {
            ...values,
          };
        } else {
          return { form5InitialValues };
        }
      case 6:
        if (type === 'edit') {
          return {
            ...values,
          };
        } else {
          return { form6InitialValues };
        }
      default:
        return form1InitialValues;
    }
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    // const cleanValues = Object.fromEntries(
    //   Object.entries(values).filter(([key, value]) => value != null)
    // );

    let payload = {
      apiUrl,
      data: values,
    };
    try {
      await post(payload).unwrap();
      resetForm();
      setKey((prev) => prev + 1);
      onSuccess();
      successMessage('Saved Successfully');
    } catch (error) {
      handleError();
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: getInitialValues(data),
    validationSchema: getValidationSchema(),
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });
  return { isLoading, isSuccess, handleSubmit, formik, key };
};

export default useForm;
