import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import styles from '../Table.module.scss';
import { getSvgByType } from '../../../../app/helpers/forms/GetSvgByType';
import appConstants from '../../../../app/constant/constants/appConstants';
import Tag from '../../../atoms/tag/Tag';
import Input from '../../../atoms/forms/input/input/Input';
import capitalizeFirstLetter from '../../../../app/helpers/forms/capitalizedFirstLetter';
import Loader from '../atoms/loader/Loader';
import { RadioButton } from '../../../atoms/forms/radio-group/RadioGroup';
import {
  useDeleteResourceMutation,
  usePostMutation,
} from '../../../../services/apiCall';
import { successMessage } from '../../../hoc/toast/Toast';
import { handleError } from '../NewTable';
import SuccessModal from '../../../hoc/success-modal/SuccessModals';
import noDataSvg from './noData.svg';
import Toggle from '../../../atoms/forms/toggle/Toggle';
import Checkbox from '../../../atoms/forms/checkbox/Checkbox';
import Button from '../../../atoms/forms/button/Button';
import Tooltip from '../../tootltip/Tooltip';
const formatTitle = (title, isSorted) => {
  const titleParts = title.split(' - ');
  return titleParts.map((part, index, isSorted) => (
    <span
      key={index}
      style={{
        display: 'block',
        whiteSpace: 'nowrap',
        cursor: isSorted ? 'pointer' : 'default',
      }}
    >
      {part}
    </span>
  ));
};
const hasActionsHeader = (actions) => {
  return (
    actions?.hasCancel ||
    actions?.hasEdit ||
    actions?.hasDelete ||
    actions?.hasView ||
    actions?.hasReverse
  );
};

const RenderTableHeader = ({
  tableHeaderData,
  actions,
  visibleColumns,
  isTerm,
  onSelectAll,
  isAllSelected,
  setQuery,
  query,
  tableData,
  isAppeal,
}) => {
  const handleSort = (header) => {
    setQuery((prevQuery) => {
      const isCurrentSort = !prevQuery.desc ?? false;
      return {
        ...prevQuery,
        sortBy: header.key,
        desc: isCurrentSort ?? false,
      };
    });
  };
  return (
    <thead>
      <tr>
        {actions?.hasOptionalSelect && <th></th>}
        {actions?.hasCheckbox && (
          <th>
            <img
              src={getSvgByType(
                isAllSelected ? 'minusCheckSign' : 'plusCheckSign'
              )}
              onClick={() => onSelectAll(!isAllSelected)}
              alt="Select all"
              aria-label="Select all rows"
            />
          </th>
        )}
        {tableHeaderData
          .filter((header) => visibleColumns.includes(header.key))
          .map((header, index) => (
            <th
              key={index}
              className={`${styles['header-cell']} ${
                isTerm && index === 0 ? styles['fixed-width-column'] : ''
              } ${isTerm && index === 0 ? styles['header-term-border'] : ''}`}
              style={{ cursor: header.isSorted ? 'pointer' : 'default' }}
              onClick={() => header.isSorted && handleSort(header)}
            >
              <div className={styles['cell']}>
                <p style={{ cursor: header.isSorted ? 'pointer' : 'default' }}>
                  {formatTitle(header.title, header.isSorted)}
                </p>
                {header.isSorted && (
                  <img
                    src={getSvgByType(appConstants.TYPE.FILTER_ARROW)}
                    alt="Sort"
                    className={
                      query.sortBy?.toLowerCase() === header.key.toLowerCase()
                        ? query.desc
                          ? styles['sort-desc']
                          : styles['sort-asc']
                        : ''
                    }
                    style={{ cursor: header.isSorted ? 'pointer' : 'default' }}
                  />
                )}
              </div>
            </th>
          ))}
        {hasActionsHeader(actions) && <th></th>}
        {isAppeal && <th></th>}
      </tr>
    </thead>
  );
};

const RenderTableBody = ({
  tableHeaderData,
  tableData,
  onRowClick,
  actions,
  isFiltering,
  visibleColumns,
  setQuery,
  query,
  isLoading,
  api,
  data,
  rowKey,
  deleteApi,
  fetchData,
  setIsModalOpen,
  form,
  deleteMethod,
  isTerm,
  title,
  deleteString,
  selectedItems,
  setSelectedItems,
  permissionKeys,
  isAppeal,
  onDeleteSuccess,
  refetch,
}) => {
  const [post] = usePostMutation();
  const [del] = useDeleteResourceMutation();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [item, setItem] = useState(null);

  const [selectedRow, setSelectedRow] = useState(null);

  const formik = useFormik({
    initialValues: tableHeaderData.reduce((acc, header) => {
      acc[header.key] = '';
      return acc;
    }, {}),
    onSubmit: (values) => {},
  });
  const hasAnyAppeal = tableData?.some((item) => item.isAppeal);
  const handleCheckboxChange = (itemId) => {
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = prevSelectedItems?.includes(itemId)
        ? prevSelectedItems?.filter((id) => id !== itemId)
        : [...prevSelectedItems, itemId];

      return newSelectedItems;
    });
  };

  const handleRowClick = (item) => {
    // here we Find the item with the matching rowKey
    const selectedItem = data?.items?.find(
      (dataItem) => dataItem[rowKey] === item[rowKey]
    );
    if (onRowClick) {
      onRowClick(selectedItem);
    }
    if (actions?.hasOptionalSelect) setSelectedRow(item[rowKey]);
    return selectedItem;
  };

  const handleActions = (e, func, item) => {
    e.stopPropagation();
    if (item[rowKey] === '') {
      item[rowKey] = 0;
    }
    const selectedItem = data?.items?.find(
      (dataItem) => dataItem[rowKey] === item[rowKey]
    );

    if (typeof func === 'function') {
      func(selectedItem);
    } else {
      throw new Error(
        `Action function is not provided or not a function. Received: ${typeof func} --> please provide the function from outside the function or remove the functionality from table`
      );
    }
  };

  const handleEdit = (e, func, item, type) => {
    e.stopPropagation();
    if (type === 'edit' && form) setIsModalOpen(true);
    if (item[rowKey] === '') {
      item[rowKey] = 0;
    }
    const selectedItem = data?.items?.find(
      (dataItem) => dataItem[rowKey] === item[rowKey]
    );
    if (typeof func === 'function') {
      func(selectedItem);
    } else {
      throw new Error(
        `Action function is not provided or not a function. Received: ${typeof func} --> please provide the function from outside the function or remove the functionality from table`
      );
    }
  };

  const handleDelete = async () => {
    const selectedItem = data?.items?.find(
      (dataItem) => dataItem[rowKey] === item[rowKey]
    );
    try {
      if (selectedItem) {
        if (deleteMethod === 'del') {
          await del({
            apiUrl: deleteApi,
            params: [`/${selectedItem[rowKey]}`],
          }).unwrap();
        } else {
          await post({
            apiUrl: deleteApi,
            params: [`${deleteString ? '?Id=' : '/'}${selectedItem[rowKey]}`],
          }).unwrap();
        }

        successMessage('Deleted Successfully!');
        handleCloseModal();
        if (onDeleteSuccess) onDeleteSuccess();
        else fetchData(query);
      } else {
        handleError();
      }
    } catch (error) {
      handleError();
    }
  };
  const filteredHeaders = tableHeaderData.filter((header) =>
    visibleColumns.includes(header.key)
  );

  // useEffect(() => {
  //   const formatValues = Object.keys(formik.values)
  //     .map((key) => {
  //       const value = formik.values[key];

  //       if (typeof value === 'object' && value !== null) {
  //         const nestedKey = Object.keys(value)[0];
  //         return {
  //           columnName: `${capitalizeFirstLetter(key)}.${capitalizeFirstLetter(
  //             nestedKey
  //           )}`,
  //           columnValue: value[nestedKey],
  //         };
  //       } else {
  //         return {
  //           columnName: capitalizeFirstLetter(key),
  //           columnValue: value,
  //         };
  //       }
  //     })
  //     .filter(({ columnValue }) => columnValue !== '');

  //   setQuery((prevQuery) => {
  //     const newQuery = { ...prevQuery, filterColumns: formatValues };

  //     if (
  //       JSON.stringify(prevQuery.filterColumns) ===
  //       JSON.stringify(newQuery.filterColumns)
  //     ) {
  //       return prevQuery;
  //     }

  //     return newQuery;
  //   });
  // }, [formik.values]);
  useEffect(() => {
    const formatValues = Object.keys(formik.values)
      .map((key) => ({
        columnName: capitalizeFirstLetter(key),
        columnValue: formik.values[key],
      }))
      .filter(({ columnValue }) => columnValue !== '');

    setQuery((prevQuery) => {
      const newQuery = { ...prevQuery, filterColumns: formatValues };

      if (
        JSON.stringify(prevQuery.filterColumns) ===
        JSON.stringify(newQuery.filterColumns)
      ) {
        return prevQuery;
      }

      return newQuery;
    });
  }, [formik.values]);
  useEffect(() => {
    if (query?.filterColumns) {
      const filterValues = query.filterColumns.reduce((acc, filter) => {
        const key =
          filter.columnName.charAt(0).toLowerCase() +
          filter.columnName.slice(1);
        acc[key] = filter.columnValue || '';
        return acc;
      }, {});

      if (JSON.stringify(formik.values) !== JSON.stringify(filterValues)) {
        formik.setValues(filterValues);
      }
    }
  }, [query?.filterColumns]);

  useEffect(() => {
    // Reset formik values when isFiltering is false
    if (!isFiltering) formik.resetForm();
  }, [isFiltering]);

  const handleOpenModal = (e, item) => {
    e.stopPropagation();
    setItem(item);
    setIsDeleteModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleSubmitModal = () => {
    handleDelete();
  };
  return (
    <>
      {isLoading && api ? (
        <td className={styles['loading-container']}>
          <Loader />
        </td>
      ) : null}

      <tbody className={styles['table-body']}>
        {isFiltering
          ? filteredHeaders?.map((header, index) => (
              <td
                key={index}
                className={`${
                  isTerm && index === 0 ? styles['findexxed-width-column'] : ''
                } ${isTerm && index === 0 ? styles['body-term-border'] : ''}`}
              >
                <Input
                  name={header.key}
                  placeholder={appConstants.PLACEHOLDER.SEARCH}
                  onChange={formik.handleChange}
                  value={formik.values[header.key]}
                />
              </td>
            ))
          : null}
        {tableData && tableData?.length > 0 ? (
          tableData.map((item, index) => (
            <tr
              key={index}
              onClick={() => handleRowClick(item)}
              className={`${styles['clickable-row']} ${
                selectedRow === item[rowKey] ? styles['selected-row'] : ''
              }`}
            >
              {actions?.hasOptionalSelect ? (
                <td>
                  <RadioButton
                    name="rowSelection"
                    value={item[rowKey]}
                    checked={selectedRow === item[rowKey]}
                    onChange={(e) => handleRowClick(item, e)}
                  />
                </td>
              ) : null}
              {actions?.hasCheckbox ? (
                <td>
                  <Checkbox
                    ischecked={selectedItems.includes(item[rowKey])}
                    onChange={() => handleCheckboxChange(item[rowKey])}
                  />
                </td>
              ) : null}
              {filteredHeaders?.map((header, i) => (
                <td
                  key={i}
                  className={
                    isTerm && i === 0 ? styles['body-term-border'] : ''
                  }
                  style={{ fontWeight: header.isBold ? '600' : '' }}
                >
                  {header.isColored ? (
                    <Tag
                      label={item[header.key]}
                      variant={item[header.key]}
                      isColored
                    />
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          item[header.key] !== null ? item[header.key] : '-',
                      }}
                      className={`${
                        isAppeal
                          ? styles['max-width-td']
                          : tableHeaderData?.length > 6
                          ? styles.comment
                          : ''
                      }`}
                    />
                  )}
                </td>
              ))}
              {hasActionsHeader(actions) && (
                <td
                  className={`${styles['action-container']} ${
                    hasAnyAppeal ? styles['action-container-appeal'] : ''
                  }`}
                >
                  {actions?.hasRole &&
                    (!actions?.hasRole?.permisionKey ||
                      permissionKeys?.includes(
                        actions?.hasRole?.permisionKey
                      )) && (
                      <Tooltip content="Role">
                        <img
                          className={styles['action-image']}
                          src={getSvgByType(appConstants.TYPE.VIEW)}
                          alt="view"
                          onClick={(e) =>
                            handleActions(e, actions.hasRole.func, item)
                          }
                        />
                      </Tooltip>
                    )}
                  {actions?.hasEdit &&
                    (!actions?.hasEdit?.permisionKey ||
                      permissionKeys?.includes(
                        actions?.hasEdit?.permisionKey ?? null
                      )) && (
                      <Tooltip content="Edit">
                        <img
                          className={styles['action-image']}
                          src={getSvgByType(appConstants.TYPE.EDIT)}
                          alt="edit"
                          onClick={(e) =>
                            handleEdit(e, actions.hasEdit.func, item, 'edit')
                          }
                        />
                      </Tooltip>
                    )}
                  {actions?.hasDelete &&
                    (!actions?.hasDelete?.permisionKey ||
                      permissionKeys?.includes(
                        actions?.hasDelete?.permisionKey ?? null
                      )) && (
                      <Tooltip content="Delete" red>
                        <img
                          className={styles['action-image']}
                          src={getSvgByType(appConstants.TYPE.DELETE)}
                          alt="delete"
                          onClick={(e) => handleOpenModal(e, item)}
                        />
                      </Tooltip>
                    )}

                  {data?.items?.[index].canCancel == 1 &&
                  actions?.hasCancel?.permissionKey &&
                  permissionKeys?.includes(
                    actions?.hasCancel?.permissionKey
                  ) ? (
                    <Tooltip content="Cancel Request" red>
                      <img
                        className={styles['action-image']}
                        src={getSvgByType('cancel')}
                        alt="cancel"
                        onClick={(e) =>
                          handleActions(e, actions.hasCancel.func, item)
                        }
                      />
                    </Tooltip>
                  ) : null}

                  {data?.items?.[index].canCancel == 1 &&
                  actions?.hasReverse?.permissionKey &&
                  permissionKeys?.includes(
                    actions?.hasReverse?.permissionKey
                  ) ? (
                    <Tooltip content="Reverse" red>
                      <img
                        className={styles['action-image']}
                        src={getSvgByType('reverse')}
                        alt="reverse"
                        onClick={(e) =>
                          handleActions(e, actions.hasReverse.func, item)
                        }
                      />
                    </Tooltip>
                  ) : null}

                  {actions?.hasSwitch && <Toggle />}
                </td>
              )}

              {isAppeal ? (
                data?.items?.[index].showAppeal ? (
                  <td className={styles.appeal}>
                    <Button
                      label="Re-appeal"
                      variant="danger"
                      onClick={(e) => {
                        // e.stopPropagation();
                        // setIsAppealModalOpen((prev) => ({
                        //   ...prev,
                        //   reAppeal: true,
                        // }));
                        // setItem(item);
                        handleActions(e, actions.hasAppeal.func1, item);
                      }}
                      className={styles['appeal-button']}
                    />
                    <Button
                      label="Accept"
                      variant="green"
                      onClick={(e) => {
                        // e.stopPropagation();
                        // setIsAppealModalOpen((prev) => ({
                        //   ...prev,
                        //   accept: true,
                        // }));
                        // setItem(item);
                        handleActions(e, actions.hasAppeal.func2, item);
                      }}
                      className={styles['appeal-button']}
                    />
                  </td>
                ) : (
                  <td></td>
                )
              ) : null}
            </tr>
          ))
        ) : !isLoading || !api ? (
          <tr>
            <td
              className={styles['no-data']}
              colSpan={tableHeaderData.length + 1}
            >
              {query.searchBy ? (
                <div className={styles['no-data-text']}>
                  <p>No results were found</p>
                </div>
              ) : (
                <div className={styles['no-data-container']}>
                  <img src={noDataSvg} alt="" />
                  <p>no {'data'} Found</p>
                </div>
              )}
            </td>
          </tr>
        ) : null}
      </tbody>
      <SuccessModal
        isOpen={isDeleteModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleSubmitModal}
        closeOnSubmit={true}
        onApproveButton={{ text: 'delete' }}
        text="Are you sure you want to delete?"
      />
    </>
  );
};

export { RenderTableHeader, RenderTableBody };
