export default {
  baseUrl: process.env.REACT_APP_URL,
  auth: {
    login: 'api/Auth/login',
    getPermissions: '/getPermissions',
    tokenValidation: 'api/Auth/validate',
  },
  profile: {
    postPassword: 'api/User/ResetPassword',
  },
  roles: {
    getRoles: 'GetRoles',
    postRole: 'PostRoles',
    getRolebyId: 'GetRolesById',
    deleteRole: 'DeleteRole',
    getUnPagedRoles: 'GetUnPagedRoles',
  },
  users: {
    postUser: 'api/User/PostUser',
    getUsers: 'api/User/GetUsers',
    changeRole: 'AssignRoleToUsers',
    getAuthenticatedUser: 'api/User/GetAuthenticatedUser',
  },
  student: {
    getStudent: 'api/Student/GetStudentInfo',
    getActiveCourses: 'api/Student/GetActiveCources',
    getPendingRequests: 'api/Student/GetPendingRequests',
    getRequestHistory: 'api/Student/GetRequestHistory',
    getRequestById: 'api/Request/GetRequestById/',
    AllowCourseWithdrawalApi: 'api/Application/AllowCourseWithdrawal',
    AllowRequest: 'api/Application/AllowRequest',
  },
  application: {
    withdrawCourse: 'api/Application/CourseWithdrawal',
    courseIncomplete: 'api/Application/CourseIncomplete',
    gradeAppeal: 'api/Application/GradeAppeal',
    academicStandingAppeal: 'api/Application/AcademicStandingAppeal',
    courseOverload: 'api/Application/CourseOverload',
  },
  instructor: {
    getEnrolledStudents: 'api/Instructor/GetEnrolledStudents',
    getEnrolledStudentById: 'api/Instructor/GetEnrolledStudentById',
    getEnrolledCourses: 'api/Instructor/GetEnrolledCourses',
    postGradeChange: 'api/Instructor/GradeChange',
    postRemovalOfI: 'api/Instructor/RemovalOfI',
  },
  restrictions: {
    getRestrictions: 'api/Restriction/GetRestrictionTypes',
    postRestriction: 'api/Restriction/PostRestriction',
    getRestrictionsTable: 'api/Restriction/GetRestrictions',
    getRestrictionsById: 'api/Restriction/GetRestrictionById/',
    delete: 'api/Restriction/deleteRestriction',
    getRestrictionDropDown: 'api/Restriction/GetRestrictionDropDown/',
    postRequestDetail: 'api/Restriction/PostRequestDetail',
    inOutRequest: 'api/Restriction/SetInOut_RestrictionDefinition',
    deleteRestricationDetail: 'api/Restriction/RemoveRestrictionDetail',
  },
  courses: {
    getCourses: 'api/Courses/GetCourses',
    postCourseAdvisorDean: 'api/Courses/SetCourseAdvisorAndDean',
    GetSectionByTermAndCourse: 'api/Courses/GetSectionByTermAndCourse',
  },
  notification: {
    getNotificationsById: 'api/Notifications/NotificationsById/',
    getNotifications: 'api/Notifications/GetNotifications',
    getNotificationOptions: 'api/Notifications/GetNotificationOptions',
    getNotificationTypes: 'api/Notifications/NotificationsTypes',
    getNotificationTypesByAppId:
      'api/Notifications/NotificationsTypesByApplication?RequestType',
    GetUnPagedNotifications: 'api/Notifications/GetUnPagedNotifications',
    postEmailSignatures: 'api/Notifications/PostEmailSignatures',
  },
  reminder: {
    postREminder: '/api/Reminder/RequestReminders',
    getReminder: '/api/Reminder/GetRequestReminders',
    deleteReminder: '/api/Reminder/DeleteReminder',
  },

  approver: {
    get: 'api/Approver/GetApprovalFlow',
    post: 'api/Approver/PostApprovalFlow',
    delete: 'api/Approver/DeleteApprovalFlow',
    getApproverById: 'api/Approver/GetApprovalFlowById',
    getSubFlow: 'api/Approver/GetSubFlow',
    getApproverDD: 'api/ProgramVersion/GetProgramVersion_DD',
    rejectRequest: 'api/Approver/RejectRequest',
    approveRequest: 'api/Approver/ApproveRequest',
    deleteApprovalFlow: 'api/Approver/DeleteApprovalFlow',
    postCancelRequest: 'api/Approver/CancelRequest',
    postRevertRequest: 'api/Approver/RevertRequest',
    acceptRejectForReappeal: 'api/Approver/AcceptRejectionForReappeal/',
    reAppealRequest: 'api/Approver/ReappealRequest',
    getKeyRoleByType: 'api/Approver/GetKeyRoleByType',
  },
  specialApproval: {
    post: 'api/SpecialApproval/PostProgramAndGroups',
    get: 'api/SpecialApproval/GetSpecialApproval',
  },
  request: {
    getRequestTypes: 'api/Request/GetRequestTypes',
    getRequestByType: 'api/Request/GetRequestByType',
    getRequestById: 'api/Request/GetRequestById',
    getPreviousStepsById: 'api/Request/GetPreviousSteps/',
    getMyRequests: 'api/Request/GetAssignedRequestByType',
    getDocumentById: 'api/Request/GetDocumentById/',
  },
  term: {
    post: '',
    getTerms: 'api/Term/GetTerms',
    termsAndConditions: 'api/Setting/PostTermAndCondition',
    postTerms: 'api/Term/PostTerms',
    getMakeupRange: 'api/Term/GetMakeUpDateRange/',
  },
  email: {
    postNotifications: 'api/Notifications/PostNotifications',
    getEmailTemplates: 'api/Notifications/PostNotifications',
    getEmails: 'api/Notifications/GetEmails',
    postEmail: 'api/Notifications/CreateEmail',
    deleteEmailTemp: 'api/Notifications/DeleteNotification',
  },
  program: {
    programVersion: 'api/ProgramVersion/GetProgramVersion',
    setProgramVersion: 'api/ProgramVersion/SetProgramVersion',
  },
  studentGroup: {
    getStudentGroup: 'api/StudentGroup/GetStudentGroups',
  },
  settings: {
    getSignature: 'api/Notifications/GetEmailSignatures',
    postSignature: 'api/Notifications/PostEmailSignatures',
    deleteSignature: 'api/Notifications/DeleteEmailSignature',
    getTermsAndConditions: 'api/Setting/GetTermAndConditions',
  },
  dropdown: {
    getPages: '/GetPageActions',
    getStatus: 'api/Approver/GetStatus',
    getUser: 'api/User/GetUsers',
    getEmailTemplates: 'api/Notifications/GetUnPagedNotifications',
    getStudentGroup: 'api/StudentGroup/GetUnpagedStudentGroups',
    getTerms: 'api/Term/GetTermsDD',
    GetEmailSignaturesDD: 'api/Notifications/GetEmailSignaturesDD',
    GetTermsForCourseOverload: 'api/Term/GetTermsForCourseOverload',
    GetCourseByTerm: 'api/Courses/GetCourseByTerm',
  },
  terms: {
    getWithdrawTerms: 'api/Term/GetTermsForCourseWithdrawal',
    getIncompleteTerms: 'api/Term/GetTermsForCourseIncomplete',
    getTermsGradeAppeal: 'api/Term/GetTermsForGradeAppeal',
    getTermsForASA: 'api/Term/GetTermsForASA',
  },
  reason: {
    getReasonsTable: 'api/Setting/GetRequestReasons',
    deleteReason: 'api/Setting/DeleteRequestReason',
    postNewReason: 'api/Setting/PostRequestReasons',
    getReasonsDropdown: 'api/Setting/GetRequestReasonsDD',
  },
};
