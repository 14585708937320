import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { usePostMutation } from '../../../../../../../services/apiCall';
import _endpoints from '../../../../../../../app/constant/endpoints/_endpoints';
import { handleError } from '../../../../../../molecules/new-table/NewTable';
import { successMessage } from '../../../../../../hoc/toast/Toast';

const apiUrl = _endpoints.restrictions.postRestriction;

const validationSchema = Yup.object({
  restrictionName: Yup.string().required('Restriction name is required'),
  requestTypeId: Yup.number().required('Request Type is required'),
  definitions: Yup.array().min(1, 'At least one restriction must be selected'),
});

const useForm = ({ onSuccess, data, type }) => {
  const [key, setKey] = useState(0);
  const [post, { isLoading, isSuccess }] = usePostMutation();
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (type === 'create') delete values.id;
    const postData = {
      ...values,
      definitions: values.definitions.map((def) => String(def.value || def)),
    };
    try {
      await post({ apiUrl, data: postData }).unwrap();
      resetForm();
      setKey((prev) => prev + 1);
      successMessage('Saved Successfully');
      if (onSuccess) onSuccess();
    } catch (error) {
      handleError();
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: type === 'edit' ? data.id : null,
      restrictionName: data?.restrictionName || '',
      requestTypeId: data?.requestTypeId || '',
      definitions: data?.definitions || [],
    },
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });
  
  return { isLoading, isSuccess, formik, key };
};

export default useForm;
