import React, { useEffect, useState } from 'react';
import TextArea from '../../../../../../../atoms/forms/text-area/TextArea';
import useForm from './hook';
import Container from '../../../../../../../hoc/container/Container';
import Button from '../../../../../../../atoms/forms/button/Button';
import _endpoints from '../../../../../../../../app/constant/endpoints/_endpoints';
import { useGetQuery } from '../../../../../../../../services/apiCall';
import styles from './CourseWithdrawal.module.scss';
import SuccessModal from '../../../../../../../hoc/success-modal/SuccessModals';
import MultiSelectList from '../../../../../../../atoms/forms/select/multi-select-list/MultiSelectList';
import * as Yup from 'yup';

const getValidationSchema = (withdrawalReasonId, reasonData) => {
  const otherReason = reasonData?.find((reason) => reason.label === 'Other');
  const otherReasonId = otherReason?.value;

  const isOtherSelected =
    Array.isArray(withdrawalReasonId) &&
    withdrawalReasonId.includes(otherReasonId);

  if (isOtherSelected) {
    return Yup.object().shape({
      withdrawalReasonId: Yup.array()
        .of(Yup.string())
        .min(1, 'Please select at least one reason')
        .required('Please select at least one reason'),
      reason: Yup.string().required('Please specify the reason for withdrawal'),
    });
  } else {
    return Yup.object().shape({
      withdrawalReasonId: Yup.array()
        .of(Yup.string())
        .min(1, 'Please select at least one reason')
        .required('Please select at least one reason'),
    });
  }
};

const reasonsApi = _endpoints.reason.getReasonsDropdown;

const CourseWithdrawalForm = ({ selectedCourse, termId }) => {
  const [reasons, setReasons] = useState([]);

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState({ show: false });

  const { data: reasonData, isLoading: appLoading } = useGetQuery({
    api: reasonsApi,
  });

  const onSuccess = ({ show, message }) => {
    setIsSuccessModalOpen({ show: show, isSuccess: true, message: message });
  };

  const onError = ({ show, message }) => {
    setIsSuccessModalOpen({ show: show, message: message, isSuccess: false });
  };

  const { formik, isLoading } = useForm({
    selectedCourse,
    termId,
    onSuccess,
    onError,
    getValidationSchema: () => getValidationSchema(reasons, reasonData),
  });

  useEffect(() => {
    setReasons(formik.values.withdrawalReasonId);
    formik.validateForm();
  }, [formik.values.withdrawalReasonId]);

  return (
    <>
      <Container>
        <form
          className={styles['course-actions']}
          onSubmit={formik.handleSubmit}
        >
          <MultiSelectList
            label="Reason for Withdrawal"
            name="withdrawalReasonId"
            type="reasonPaper"
            value={formik.values.withdrawalReasonId}
            onChange={(event) => {
              formik.setFieldValue('withdrawalReasonId', event.target.value);
            }}
            placeholder="Select reason"
            error={formik.errors.withdrawalReasonId}
            touched={formik.touched.withdrawalReasonId}
            isLoading={appLoading}
            options={reasonData}
          />
          <TextArea
            label="Other Reason"
            name="reason"
            value={formik.values.reason}
            onChange={formik.handleChange}
            placeholder="Specify reason"
            error={formik.errors.reason}
            touched={formik.touched.reason}
          />
          <Button
            label="Submit"
            disabled={isLoading}
            buttonType="submit"
            isLoading={isLoading}
          />
        </form>
      </Container>
      <SuccessModal
        isOpen={isSuccessModalOpen.show}
        isSuccess={isSuccessModalOpen.isSuccess}
        text={isSuccessModalOpen.message}
        subText="Your request needs to be verified by the registrar"
        onClose={() => setIsSuccessModalOpen({ show: false, message: '' })}
      />
    </>
  );
};

export default CourseWithdrawalForm;
