import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { routes } from '../app/constant/routes/Routes';

// AUTH
import Login from '../features/authentication/login/Login';
import PageNotFound from '../features/authentication/page-not-found/PageNotFound';
import getRoutes from '../app/helpers/routing/validateRoutes';

import ScrollToTop from '../app/hooks/ScrolToTop';
import OfflinePage from '../features/authenticated/web-mode/OfflinePage';
import useFetchPermissions from '../app/helpers/permissions/getPermissionsCall';
import { clearUser } from '../app/globals/storeSlices/userSlice';
import UnAuthorized from '../features/authentication/un-authorized/UnAuthorized';

const MainRouter = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useFetchPermissions();
  useEffect(() => {
    const now = new Date().getTime();
    const tokenExpiryTimestamp = new Date(user.tokenExpiry).getTime();

    if (user.tokenExpiry && tokenExpiryTimestamp < now) {
      dispatch(clearUser());
    }
  }, [user, dispatch]);

  const redirectIfAuthenticated = () => {
    if (user.isAuthenticated && user.userType === 'STAFF') {
      return <Navigate to={user.sideBarPages[0]?.path} />;
    }
    if (user.isAuthenticated && user.userType === 'STUDENT') {
      return <Navigate to={routes.ALL_APPLICATIONS} />;
    }
    return null;
  };

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route
          path={routes.BASE}
          element={redirectIfAuthenticated() || <Login />}
        />
        <Route
          path={routes.LOGIN}
          element={redirectIfAuthenticated() || <Login />}
        />
        {getRoutes()}
        <Route path={routes.PAGE_NOT_FOUND} element={<PageNotFound />} />
        <Route
          key="offline"
          path={routes.OFFLINE}
          element={<OfflinePage />}
        />, <Route path="/not-authorized" element={<UnAuthorized />} />
      </Routes>
    </>
  );
};

export default MainRouter;
