import React, { useEffect } from 'react';
import styles from './DocumentView.module.scss';
import { getSvgByType } from '../../../app/helpers/forms/GetSvgByType';
import appConstants from '../../../app/constant/constants/appConstants';
import DropdownButton from '../dropdown-button/DropdownButton';
import _endpoints from '../../../app/constant/endpoints/_endpoints';
import { useGetQuery } from '../../../services/apiCall';
import { errorMessage, successMessage } from '../../hoc/toast/Toast';

const api = _endpoints.request.getDocumentById;
const DocumentView = ({ type, fileName, fileType, fileId, hide }) => {
  const { data, refetch } = useGetQuery(
    {
      api: api,
      params: [fileId],
      responseType: 'blob',
    },
    {
      skip: !fileId,
    }
  );

  const truncateFileName = (name) => {
    return name.length > 30 ? `${name.slice(0, 10)}...` : name;
  };

  const handleDownload = async (doc) => {
    try {
      if (!data) {
        errorMessage();
      }
      const name = fileName || 'downloaded-file';

      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.download = name;
      link.click();
      window.URL.revokeObjectURL(url);
      successMessage('Document successfully Downloaded !');
    } catch (error) {
      errorMessage(`Error downloading file: ${error.message}`);
    }
  };

  const portalItems = [
    {
      label: 'Download File',
      type: appConstants.TYPE.EXPORT,
      function: handleDownload,
    },
  ];

  return (
    <div className={styles['document-view']}>
      <img src={getSvgByType(type)} alt="" className={styles['file-img']} />
      <div className={styles.info}>
        <p className={styles.title}>{truncateFileName(fileName)}</p>
        <p className={styles.text}>{fileType}</p>
      </div>
      {/* <img
        className={styles.dots}
        src={getSvgByType('verticalThreeDots')}
        alt=""
      /> */}
      {!hide && (
        <div className={styles['drop']}>
          <DropdownButton
            items={portalItems}
            type="verticalThreeDots"
            tooltipContent="More"
          />
        </div>
      )}
    </div>
  );
};

export default DocumentView;
