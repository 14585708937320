import React, { useEffect } from 'react';
import StudentProfile from '../../../molecules/students-profile/StudentProfile';
import styles from './StudentProfileWrapper.module.scss';
import ProfileHeader from './components/header/ProfileHeader_';
import _endpoints from '../../../../app/constant/endpoints/_endpoints';
import { useGetQuery } from '../../../../services/apiCall';
import Title from '../../../atoms/title/Title';
import Backheader from '../../../atoms/back-header/BackHeader';

const getCurrentStudentApi = _endpoints.student.getStudent;

const StudentProfileWrapper = ({
  children,
  title,
  headerData,
  fallbackState,
  isStudent,
  data,
  isLoading,
}) => {
  const header = headerData?.request;
  const lastUpdated = headerData?.lastUpdated;

  const {
    data: studentData,
    refetch: refetchUser,
    isLoading: loader,
  } = useGetQuery(
    {
      api: getCurrentStudentApi,
    },
    {
      skip: !isStudent,
    }
  );

  useEffect(() => {
    if (isStudent) {
      refetchUser();
    }
  }, [isStudent, refetchUser]);

  return (
    <div className={styles.profile}>
      {title ? <Backheader title={title} /> : null}
      {headerData ? (
        <ProfileHeader
          data={header}
          fallbackState={fallbackState}
          lastUpdated={lastUpdated}
        />
      ) : null}
      <StudentProfile
        title={title}
        data={isStudent ? studentData : headerData?.student || data}
        isLoading={isStudent ? loader : isLoading}
      />
      {children}
    </div>
  );
};

export default StudentProfileWrapper;
