import React, { useMemo, useState } from 'react';
import styles from './Form.module.scss';
import Container from '../../../../../../components/hoc/container/Container';
import Select from '../../../../../../components/atoms/forms/select/select/Select';
import Button from '../../../../../../components/atoms/forms/button/Button';
import appConstants from '../../../../../../app/constant/constants/appConstants';
import { useNavigate } from 'react-router-dom';
import {
  useGetQuery,
  usePostMutation,
} from '../../../../../../services/apiCall';
import { useFormik } from 'formik';
import { errorMessage } from '../../../../../../components/hoc/toast/Toast';
import SuccessModal from '../../../../../../components/hoc/success-modal/SuccessModals';
import * as Yup from 'yup';
import TextArea from '../../../../../../components/atoms/forms/text-area/TextArea';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
import objectToFormData from '../../../../../../app/helpers/forms/objectToFormData';
import UploadFile from '../../../../../../components/atoms/forms/upload-file/UploadFile';
import RejectionForm from '../RejectionForm';
const programsApi = _endpoints.approver.getSubFlow;

const appealOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];
const RegistrarForm = ({ data, finalProcess }) => {
  const navigate = useNavigate();
  const [post] = usePostMutation();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [actionType, setActionType] = useState('');

  const { data: programData, isLoading: programIsLoading } = useGetQuery({
    api: `${programsApi}?RequestTypeId=0`,
  });

  const programsOptions = useMemo(
    () =>
      programData?.map((val) => ({
        value: val.id,
        label: val.flowName,
      })),
    [programData]
  );

  const getValidationSchema = (finalProcess, actionType) => {
    let baseSchema = {
      comment: Yup.string().required('Required'),
      transcript: Yup.string(),
      appealToPresident: Yup.string(),
    };
    if (!finalProcess && actionType == 'approve') {
      baseSchema.transcript = baseSchema.transcript.required('Required');
    } else {
      baseSchema.transcript = baseSchema.transcript.notRequired();
    }
    if (!finalProcess && actionType == 'approve') {
      baseSchema.appealToPresident =
        baseSchema.appealToPresident.required('Required');
    } else {
      baseSchema.appealToPresident = baseSchema.appealToPresident.notRequired();
    }

    return Yup.object().shape(baseSchema);
  };
  const formik = useFormik({
    initialValues: {
      comment: '',
      appealToPresident: '',
    },

    validationSchema: getValidationSchema(finalProcess, actionType),
    onSubmit: (values) => {
      if (actionType == 'approve') {
        handleApprove();
      } else if (actionType == 'reject') {
        setWarningModalOpen(true);
      }
    },
  });

  const handleApprove = async () => {
    setIsApproving(true);
    setActionType('approve');

    const payload = {
      RequestId: data?.request.id,
      Comments: formik.values.comment,
      SubFlow: formik.values.appealToPresident,
      Transcript: formik.values.transcript,
    };

    const formData = objectToFormData(payload);

    try {
      await post({
        apiUrl: _endpoints.approver.approveRequest,
        data: formData,
        type: 'formData',
      }).unwrap();
      setIsSuccessModalOpen(true);
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } catch (error) {
      errorMessage('Something went wrong!');
    } finally {
      setIsApproving(false);
    }
  };

  const handleRejection = async (reason) => {
    setIsRejecting(true);
    setActionType('reject');

    try {
      await post({
        apiUrl: _endpoints.approver.rejectRequest,
        data: {
          requestId: data?.request.id,
          comments: formik.values.comment,
          reason: reason,
        },
      }).unwrap();
      setIsSuccessModalOpen(true);
      setWarningModalOpen(false);
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } catch (error) {
      errorMessage('Something went wrong!');
    } finally {
      setIsRejecting(false);
    }
  };

  const closeModal = () => {
    setIsSuccessModalOpen(false);
    setWarningModalOpen(false);
  };
  return (
    <>
      <Container>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.validateForm().then(() => formik.handleSubmit());
          }}
          className={styles.form}
        >
          <div className={styles.application}>
            {!finalProcess && (
              <Select
                label="Assign to"
                type={appConstants.TYPE.APPEAL}
                placeholder="Select"
                name="appealToPresident"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.appealToPresident}
                error={
                  formik.touched.appealToPresident &&
                  formik.errors.appealToPresident
                }
                touched={formik.touched.appealToPresident}
                options={programsOptions}
              />
            )}

            <TextArea
              label={appConstants.LABEL.COMMENT}
              placeholder={appConstants.PLACEHOLDER.ENTER_COMMENT}
              type={appConstants.TYPE.NOTE}
              name="comment"
              value={formik.values.comment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.comment}
              touched={formik.touched.comment}
            />
            {!finalProcess && (
              <UploadFile
                title="Attach Transcript"
                subTitle="Supported: pdf, jpg, doc & docx"
                name="transcript"
                value={formik.values.transcript}
                onChange={(e) => {
                  formik.setFieldValue('transcript', e.target.value);
                }}
                onBlur={formik.handleBlur}
                error={formik.errors.transcript}
                touched={formik.touched.transcript}
                maxSize="45000000"
              />
            )}

            {finalProcess ? (
              <Button
                label="Process"
                type={appConstants.TYPE.CHECK}
                onClick={(e) => {
                  e.preventDefault(); // Prevent triggering the form's onSubmit
                  setActionType('approve');
                  formik.validateForm().then(() => formik.handleSubmit());
                }}
              />
            ) : (
              <div className={styles['button-container']}>
                <Button
                  label="Approve"
                  type="thumbsUp"
                  variant="green"
                  onClick={() => {
                    setActionType('approve');
                    formik.validateForm().then(() => formik.handleSubmit());
                  }}
                  isLoading={isApproving}
                />
                <Button
                  label={appConstants.LABEL.REJECT_REQUEST}
                  type={appConstants.TYPE.THUMB_DOWN}
                  variant={appConstants.VARIANT.DANGER}
                  onClick={() => {
                    setActionType('reject');
                    formik.validateForm().then(() => formik.handleSubmit());
                  }}
                />
              </div>
            )}
          </div>
        </form>
      </Container>

      <SuccessModal
        isOpen={isSuccessModalOpen}
        isSuccess
        text={
          actionType === 'approve' ? 'Request Approved' : 'Request Rejected'
        }
        timeout={1000}
        subText={
          actionType === 'approve'
            ? 'Request has been reviewed and given approval.'
            : 'Request has been reviewed and rejected.'
        }
      />
      <RejectionForm
        isWarningModalOpen={isWarningModalOpen}
        closeModal={closeModal}
        isRejecting={isRejecting}
        handleRejection={handleRejection}
      />
    </>
  );
};

export default RegistrarForm;
