import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';
import styles from './Select.module.scss';
import Label from '../../label/Label';
import arrowSvg from '../../../../../assets/icons/forms/arrow.svg';
import { getSvgByType } from '../../../../../app/helpers/forms/GetSvgByType';
import appConstants from '../../../../../app/constant/constants/appConstants';
import Loader from '../../../../molecules/table/atoms/loader/Loader';

const Select = ({
  // api,
  label,
  placeholder,
  name,
  value: initialValue,
  onChange,
  style,
  type,
  error,
  touched,
  // skip,
  disabled,
  isLoading,
  classname,
  options,
  onSelectLabel,
  isClear = true,
  additionalIcon,
}) => {
  // const skipApiCall = !api || skip || options;
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [dropdownStyle, setDropdownStyle] = useState({});
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const containerRef = useRef(null);

  const sourceOptions = options;
  const handleSelect = useCallback(
    (newValue) => {
      setIsOpen(false);
      setSearchTerm('');
      const selectedLabel = sourceOptions.find(
        (option) => option.value === newValue
      )?.label;

      if (onChange) {
        onChange({
          target: {
            name,
            value: newValue,
          },
        });
      }

      // Call onSelectLabel with the label of the selected option, if provided
      if (onSelectLabel && selectedLabel) {
        onSelectLabel(selectedLabel);
      }
    },
    [name, onChange, sourceOptions, onSelectLabel]
  );

  const filteredOptions = useMemo(
    () =>
      (sourceOptions || [])
        .filter((option) =>
          option?.label?.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .map((option) => ({
          value: option.value,
          label: option.label,
        })),
    [sourceOptions, searchTerm]
  );

  const selectedOption = useMemo(
    () => filteredOptions.find((option) => option.value === initialValue),
    [filteredOptions, initialValue]
  );

  useEffect(() => {
    if (isOpen && containerRef.current) {
      const { width, height, top, left } =
        containerRef.current.getBoundingClientRect();
      setDropdownStyle({
        width: `${width}px`,
        top: `${top + height + window.scrollY}px`,
        left: `${left + window.scrollX}px`,
      });
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !inputRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleScrollOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const handleResize = () => {
      setIsOpen(false);
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      window.addEventListener('scroll', handleScrollOutside, true);
      window.addEventListener('resize', handleResize);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScrollOutside, true);
      window.removeEventListener('resize', handleResize);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScrollOutside, true);
      window.removeEventListener('resize', handleResize);
    };
  }, [isOpen]);
  return (
    <div
      className={`${styles['select-container']} ${classname}`}
      style={style}
      ref={containerRef}
    >
      <div className={styles.labelCont}>
        {label && <Label text={label} htmlFor={`select-${name}`} />}
        {typeof additionalIcon === 'function'
          ? additionalIcon()
          : additionalIcon}
      </div>

      <div
        className={`${styles['select-wrapper']} ${
          disabled ? styles.disabled : ''
        }`}
        tabIndex={0}
        aria-controls={`select-dropdown-${name}`}
        aria-expanded={isOpen}
        onClick={disabled ? () => {} : () => setIsOpen(!isOpen)}
      >
        <div className={styles['select-display']}>
          {type && (
            <img
              className={styles.svg}
              src={getSvgByType(type)}
              alt={placeholder}
            />
          )}
          <input
            id={`select-${name}`}
            type="text"
            ref={inputRef}
            value={
              isOpen
                ? searchTerm
                : selectedOption
                ? selectedOption.label
                : placeholder
            }
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={placeholder}
            className={`${styles['select']} ${
              !selectedOption && !searchTerm
                ? styles['placeholder']
                : styles['has-value']
            }`}
            readOnly={!isOpen}
          />
          {isLoading ? (
            <div className={styles.loader}>
              <Loader style={{ width: '20px', height: '20px' }} isComponent />
            </div>
          ) : initialValue && isClear ? (
            <div className={styles['actionImg-container']}>
              <img
                className={styles.actionImg}
                src={getSvgByType('blackX')}
                alt="Action"
                onClick={(e) => {
                  e.stopPropagation();
                  if (onChange) {
                    onChange({
                      target: {
                        name,
                        value: '',
                      },
                    });
                  }
                }}
              />
            </div>
          ) : (
            <img className={styles.arrow} src={arrowSvg} alt={placeholder} />
          )}
        </div>

        {isOpen &&
          createPortal(
            <div
              className={`${styles['options']} ${styles['dropdown-animation']}`}
              style={dropdownStyle}
              ref={dropdownRef}
              onScroll={(e) => e.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
            >
              {filteredOptions.length > 0 ? (
                filteredOptions.map((option, index) => (
                  <div
                    key={index}
                    className={`${styles['option']} ${
                      option.value === initialValue ? styles['selected'] : ''
                    }`}
                    onClick={() => handleSelect(option.value)}
                  >
                    {option.label}
                  </div>
                ))
              ) : (
                <div className={styles['no-data']}>
                  {appConstants.DEFAULT_EMPTY_STATE}
                </div>
              )}
            </div>,
            document.body
          )}
      </div>
      {error && touched && (
        <div className={styles['error-message']}>{error}</div>
      )}
    </div>
  );
};

export default Select;
