import React from 'react';
import styles from './CourseDetails.module.scss';
import Container from '../../../../../hoc/container/Container';
import Button from '../../../../../atoms/forms/button/Button';
import appConstants from '../../../../../../app/constant/constants/appConstants';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
import { getSvgByType } from '../../../../../../app/helpers/forms/GetSvgByType';
import { differenceInCalendarDays } from 'date-fns';
import { daysInYear } from 'date-fns/constants';

const CourseDetails = ({
  selectedCourse,
  onBackClick,
  flag,
  requestType,
  selectedTerm,
}) => {
  const courseDetails = [
    { label: 'Course Code', value: selectedCourse?.course?.courseCode || '-' },
    {
      label: 'Course Description',
      value: selectedCourse?.course?.description || '-',
    },
    { label: 'Credit', value: selectedCourse?.course?.credit || '-' },
    {
      label: 'Instructor',
      value:
        selectedCourse?.instructorName ||
        selectedCourse?.[flag ? flag : 'section']?.instructorName ||
        '-',
    },
    {
      label: 'Section',
      value:
        selectedCourse?.sectionName ||
        selectedCourse?.[flag ? flag : 'section']?.sectionName ||
        '-',
    },
    {
      label: 'Room',
      value:
        selectedCourse?.room ||
        selectedCourse?.[flag ? flag : 'section']?.room ||
        '-',
    },
  ];
  const chunkedDetails = [];
  for (let i = 0; i < courseDetails.length; i += 2) {
    chunkedDetails.push(courseDetails.slice(i, i + 2));
  }
  const startDate = selectedTerm?.[0]?.startDate
    ? new Date(selectedTerm[0].startDate)
    : null;
  const now = new Date();

  const daysSinceStart = differenceInCalendarDays(now, startDate);

  return (
    <div>
      <div className={styles['course-wrapper']}>
        <Container>
          <div className={styles['course-detail-container']}>
            {chunkedDetails.map((chunk, index) => (
              <div key={index} className={styles['course-detail']}>
                {chunk.map((detail, idx) => (
                  <div key={idx}>
                    <p className={styles.title}>{detail.label}</p>
                    <p className={styles['sub-title']}>{detail.value}</p>
                  </div>
                ))}
              </div>
            ))}

            {requestType == 8 && daysSinceStart > 30 && (
              <div className={styles['attention']}>
                <img src={getSvgByType('bell')} alt="" />
                <p>
                  This request is beyond the time limit allowed for grade
                  appeal.
                </p>
              </div>
            )}

            <Button
              label="Change Course"
              type={appConstants.TYPE.BACK_ARROW}
              className={styles['button']}
              onClick={onBackClick}
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default CourseDetails;
