import React, { useState } from 'react';
import styles from './AppealModal.module.scss';
import appConstants from '../../../../../app/constant/constants/appConstants';
import AppealForm from './AppealForm';
import Modal from '../../../../../components/hoc/modal/Modal';
import Button from '../../../../../components/atoms/forms/button/Button';
const AppealModal = ({ isOpen, onClose, item, onSuccess }) => {
  const [isAppealForm, setIsAppealForm] = useState(false);

  const handleSubmit = () => {
    onClose();
    setIsAppealForm(true);
  };
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} title={'Re-appeal'}>
        <p className={styles.subText}>Are you sure you want to re-appeal?</p>
        <div className={styles.actions}>
          <Button label={appConstants.CANCEL} onClick={onClose} />
          <Button
            label="Re-appeal"
            variant="blue"
            type="check"
            onClick={handleSubmit}
          />
        </div>
      </Modal>
      <AppealForm
        isOpen={isAppealForm}
        item={item}
        onClose={() => setIsAppealForm(false)}
        onSuccess={onSuccess}
      />
    </>
  );
};

export default AppealModal;
