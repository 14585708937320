import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { format } from 'date-fns';
import styles from './Table.module.scss';
import Title from '../../atoms/title/Title';
import { TableFilters } from './factory/Filters';
import { RenderTableBody, RenderTableHeader } from './factory/Components';
import Paginator from '../paginator/Paginator';
import { useGetQuery, usePostMutation } from '../../../services/apiCall';
import debounce from 'lodash.debounce';
// import useFetchOnFocus from '../../../app/hooks/useFetchOnFocus';
// import useFetchOnReconnect from '../../../app/hooks/useFetchOnReconnect';
import { errorMessage, successMessage } from '../../hoc/toast/Toast';

import _endpoints from '../../../app/constant/endpoints/_endpoints';
import { getPermissionKeys } from '../../../app/globals/storeSlices/userSlice';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import PrintComponent from './PrintComponent';
import capitalizeFirstLetter from '../../../app/helpers/forms/capitalizedFirstLetter';

export const handleError = (error) => {
  if (error?.data?.message) {
    errorMessage(error.data.message);
  } else if (error?.status) {
    errorMessage('Server error occurred.');
  } else if (error?.request) {
    errorMessage('No response from server. Please try again later.');
  } else {
    errorMessage('An unexpected error occurred. Please try again.');
  }
};
const formatDate = (dateString, time) => {
  if (!dateString) {
    return '-';
  }
  try {
    const date = new Date(dateString);
    return format(date, time ? 'dd/MM/yyyy hh:mm a' : 'dd/MM/yyyy');
  } catch (e) {
    return '-';
  }
};

const NewTable = ({
  title,
  onRowClick,
  rightContainer,
  actions = {},
  classname,
  deleteApi,
  api,
  method = 'POST',
  refetch,
  extraQuery: externalQuery,
  apiConfig = { perPage: 10 },
  rowKey = 'id',
  headerTitle,
  dependsOn,
  form,
  isModalOpen,
  setIsModalOpen,
  modalConfig,
  rightContent,
  deleteMethod,
  data: externalData,
  hasCategoriesSlide,
  hasAdd = true,
  isTerm,
  deleteString,
  getApplication,
  activeApplication,
  getQuery,
  initialQuery,
  isAppeal,
  onDeleteSuccess,
  isRequest,
}) => {
  const permissionKeys = useSelector(getPermissionKeys);
  const printRef = useRef();
  const tableContainer_REF = useRef(null);
  const [extraQuery, setExtraQuery] = useState(null);
  const [post] = usePostMutation();
  const { data: getCallData } = useGetQuery(
    { api },
    { skip: method !== 'GET' || !api }
  );

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFiltering, setIsFiltering] = useState(
    initialQuery?.filterColumns ? true : false
  );
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [query, setQuery] = useState({
    pageSize: apiConfig?.perPage,
    pageNumber: 0,
    ...initialQuery,
  });
  useEffect(() => {
    setExtraQuery(externalQuery);
  }, [externalQuery]);

  const fetchData = useCallback(
    debounce(async (query) => {
      setIsLoading(true);

      if (tableContainer_REF.current) {
        tableContainer_REF.current.scrollTop = 0;
      }
      try {
        let apiUrl = api;

        if (
          extraQuery &&
          extraQuery.query &&
          extraQuery.id !== null &&
          extraQuery.id !== undefined
        ) {
          apiUrl = `${api}?${extraQuery.query}=${extraQuery.id}`;
        }
        if (query?.visibleColumns) {
          delete query.visibleColumns;
        }
        if (apiUrl === 'api/Request/GetRequestByType') return;
        if (apiUrl === 'api/Request/GetAssignedRequestByType') return;

        if (apiUrl === 'api/Approver/GetApprovalFlow') return;
        if (query.sortBy) {
          query.sortBy =
            query.sortBy.charAt(0).toUpperCase() + query.sortBy.slice(1);
        }

        let result;
        if (query?.filterColumns?.length === 0) {
          delete query.filterColumns;
        }
        let filterColumnsValues;
        if (query?.filterColumns) {
          filterColumnsValues = Object.keys(query?.filterColumns)
            .map((key) => {
              const value = query?.filterColumns[key];
              if (typeof value.columnValue === 'object' && value !== null) {
                const nestedKey = Object.keys(
                  query?.filterColumns[key].columnValue
                );

                return {
                  columnName: `${capitalizeFirstLetter(
                    query?.filterColumns[key].columnName
                  )}.${capitalizeFirstLetter(nestedKey[0])}`,
                  columnValue:
                    query?.filterColumns[key].columnValue[nestedKey[0]],
                };
              } else {
                return {
                  columnName: `${capitalizeFirstLetter(
                    query?.filterColumns[key].columnName
                  )}`,
                  columnValue: value?.columnValue,
                };
              }
            })
            .filter(({ columnValue }) => columnValue !== '');
        }
        let finalQuery = query;
        if (filterColumnsValues) {
          finalQuery = { ...query, filterColumns: filterColumnsValues };
        }
        if (finalQuery?.filterColumns?.length === 0) {
          delete finalQuery.filterColumns;
        }

        if (method === 'POST') {
          result = await post({
            apiUrl,
            data: finalQuery,
          }).unwrap();
        } else if (method === 'GET') {
          result = await getCallData;
        }

        if (result) {
          if (!result.items?.length && query.pageNumber > 0) {
            setQuery((prevQuery) => ({
              ...prevQuery,
              pageNumber: 0,
            }));
          } else {
            setData(result);
          }

          if (initialQuery?.visibleColumns) {
            setVisibleColumns(initialQuery?.visibleColumns);
          } else {
            setVisibleColumns(
              result?.tableView?.[0]?.fields
                ?.filter((field) => field.isVisible)
                ?.map((field) => field.fieldName) || []
            );
          }
        }
      } catch (error) {
        const errorMessage = error?.data?.message;
        handleError(errorMessage);
      } finally {
        if (isRequest && extraQuery === null) {
          setIsLoading(true);
        } else setIsLoading(false);
      }
    }, 500),
    [post, api, getCallData, method, extraQuery?.id]
  );

  // const handlePrint = useReactToPrint({
  //   contentRef: () => printRef.current,
  // });

  useEffect(() => {
    if (!isFiltering) {
      delete query.filterColumns;
    }
    if (api) fetchData(query);
  }, [query, refetch, api, extraQuery?.id]);

  useEffect(() => {
    if (externalData) {
      setData(externalData);
      const initialVisibleColumns =
        externalData?.tableView?.[0]?.fields
          ?.filter((field) => field.isVisible)
          ?.map((field) => field.fieldName) || [];
      setVisibleColumns(initialVisibleColumns);
    }
  }, [externalData]);

  useEffect(() => {
    if (initialQuery) {
      setQuery(initialQuery);
      if (initialQuery?.filterColumns) {
        setIsFiltering(true);
      }
      if (initialQuery?.visibleColumns) {
        setVisibleColumns(initialQuery?.visibleColumns);
      }
    }
  }, [initialQuery]);

  useEffect(() => {
    setQuery({
      pageSize: apiConfig?.perPage,
      pageNumber: 0,
      ...initialQuery,
    });
    setIsFiltering(Boolean(initialQuery?.filterColumns));
  }, [activeApplication, apiConfig?.perPage]);
  const tableHeaderData = useMemo(
    () =>
      data?.tableView?.[0]?.fields
        ?.filter((field) => {
          if (dependsOn === 0) {
            return field.relatedType === 0 || field.relatedType === null;
          } else if (dependsOn) {
            return (
              field.relatedType === dependsOn || field.relatedType === null
            );
          } else {
            return true;
          }
        })
        ?.map((field) => ({
          title: field.headerName,
          key: field.fieldName,
          isSorted: field.hasSort,
          isDate: field.isDatetime,
          isBold: field.isBold,
          isColored: field.isColoredLabel,
        })) || [],
    [data, dependsOn]
  );
  const tableData = useMemo(() => {
    if (tableHeaderData?.length === 0) {
      return [];
    }
    return (
      data?.items
        ?.map((item) => {
          let rowData = {};
          let hasData = false;
          tableHeaderData?.forEach((header) => {
            const fieldParts = header.key.split('.');
            let value = item;

            for (let part of fieldParts) {
              value = value?.[part];
            }

            if (header.isDate && isTerm) {
              value = formatDate(value);
            } else if (header.isDate) {
              value = formatDate(value, true);
            }
            if (value !== null) {
              hasData = true;
            }
            rowData[header.key] = value;
          });
          rowData[rowKey] = item[rowKey] || '';
          return hasData ? rowData : null;
        })
        .filter((row) => row !== null) || []
    );
  }, [data, tableHeaderData, rowKey]);
  const tableActions = { ...data?.tableView?.[0], ...actions } || actions;
  const handleFilterClick = () => {
    setIsFiltering(!isFiltering);
  };

  const handleColumnVisibilityChange = (columnKey) => {
    const key = columnKey.value;

    setVisibleColumns((prevVisibleColumns) =>
      prevVisibleColumns.includes(key)
        ? prevVisibleColumns.filter((existingKey) => existingKey !== key)
        : [...prevVisibleColumns, key]
    );
  };
  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      const allRowKeys = tableData?.map((row) => row[rowKey]);
      setSelectedItems(allRowKeys);
    } else {
      setSelectedItems([]);
    }
  };

  const handlePageChange = (page) => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      pageNumber: page,
    }));
  };

  useEffect(() => {
    if (isLoading) {
      const tableContainer = document.querySelector(
        `.${styles['table-container']} ${
          isLoading && styles['hide-overflow']
        } ${classname ?? ''}`
      );
      if (tableContainer) {
        tableContainer.scrollTo(0, 0);
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (getQuery) {
      if (visibleColumns?.length > 0) {
        const updatedQuery = { ...query, visibleColumns };
        getQuery(updatedQuery);
      } else {
        getQuery(query);
      }
    }
  }, [query, visibleColumns]);

  // useEffect(() => {
  //   if (getQuery) {
  //     getQuery(query);
  //   }
  // }, [query, getQuery]);
  const hasData = data?.items?.length > 0 || data?.totalCount;
  const totalPages = Math.ceil(data?.totalCount / apiConfig.perPage);
  const hasPaging = data?.tableView && data?.tableView[0]?.hasPaging;
  return (
    <div className={styles['table-wrapper']}>
      {headerTitle && <Title text={title} variant="md" />}

      <div className={styles['filters-container']}>
        <TableFilters
          api={api}
          filters={tableActions}
          rightContainer={rightContainer}
          handleFilterClick={handleFilterClick}
          isFiltering={isFiltering}
          visibleColumns={visibleColumns}
          onColumnVisibilityChange={handleColumnVisibilityChange}
          tableHeaderData={tableHeaderData}
          isLoading={isLoading}
          handleSearchChange={setQuery}
          query={query}
          setExtraQuery={setExtraQuery}
          extraQuery={extraQuery}
          hasData={hasData}
          form={form}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          modalConfig={modalConfig}
          rightContent={rightContent}
          hasCategoriesSlide={hasCategoriesSlide}
          hasAdd={hasAdd}
          permissionKeys={permissionKeys}
          actions={actions}
          getApplication={getApplication}
          activeApplication={activeApplication}
          getQuery={getQuery}
          // handlePrint={handlePrint}
        />
      </div>

      {title && !headerTitle && <Title text={title} />}
      <div
        ref={tableContainer_REF}
        className={`${styles['table-container']} ${
          isLoading ? styles['hidden-overflow'] : ''
        } ${!hasPaging ? styles['max-height-no-scroll'] : ''}`}
      >
        <table className={styles['table']}>
          <RenderTableHeader
            tableHeaderData={tableHeaderData}
            tableData={tableData}
            actions={tableActions}
            isFiltering={isFiltering}
            visibleColumns={visibleColumns}
            isLoading={isLoading}
            isTerm={isTerm}
            onSelectAll={handleSelectAll}
            isAllSelected={selectedItems?.length === tableData?.length}
            setQuery={setQuery}
            query={query}
            isAppeal={isAppeal}
          />

          <RenderTableBody
            tableHeaderData={tableHeaderData}
            tableData={tableData}
            data={data}
            onRowClick={onRowClick}
            actions={tableActions}
            isFiltering={isFiltering}
            visibleColumns={visibleColumns}
            isLoading={isLoading}
            query={query}
            setQuery={setQuery}
            api={api}
            rowKey={rowKey}
            deleteApi={deleteApi}
            deleteMethod={deleteMethod}
            fetchData={fetchData}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            haspaging={hasPaging}
            form={form}
            isTerm={isTerm}
            title={title}
            deleteString={deleteString}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            permissionKeys={permissionKeys}
            getQuery={getQuery}
            isAppeal={isAppeal}
            onDeleteSuccess={onDeleteSuccess}
          />
        </table>
      </div>

      {hasPaging && hasData ? (
        <Paginator
          currentPage={query.pageNumber}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          config={apiConfig}
          data={data}
        />
      ) : null}
    </div>
  );
};

export default NewTable;
