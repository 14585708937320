import React from 'react';
import styles from './Title.module.scss';
const getClassName = (variant) => {
  const className = styles[`page-title--${variant}`];
  return className || styles['page-title'];
};

const Title = ({ text, variant, classname, onClick }) => {
  const className = getClassName(variant);

  return (
    <p
      onClick={onClick}
      className={`${className} ${classname} ${onClick ? styles.hover : ''}`}
    >
      {text ?? ''}
    </p>
  );
};

export default Title;
