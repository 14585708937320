import React, { useState } from 'react';
import styles from './ApplicationCard.module.scss';
import appConstants from '../../../app/constant/constants/appConstants';
import { getSvgByType } from './factory/Factory';
import { useNavigate } from 'react-router-dom';
import Modal from '../../hoc/modal/Modal';
import Button from '../../atoms/forms/button/Button';
import SuccessModal from '../../hoc/success-modal/SuccessModals';
import { usePostMutation } from '../../../services/apiCall';
import _endpoints from '../../../app/constant/endpoints/_endpoints';
import Loader from '../table/atoms/loader/Loader';
import { handleError } from '../new-table/NewTable';
const allowedApi = _endpoints.student.AllowRequest;
const ApplicationCard = (props) => {
  const {
    type,
    desc,
    smallDescription,
    route,
    termsAndConditions,
    onModalSubmitroute,
    applicationImage,
    applicationName,
    FormComponent,
    disable,
    isAllowedToApply,
    id,
    url,
  } = props;
  const [post, { isLoading, isSuccess }] = usePostMutation();
  const navigate = useNavigate();
  const [response, setResponse] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const handleApplyNowClick = async () => {
    try {
      if (isAllowedToApply) {
        const response = await post({
          apiUrl: `${allowedApi}/${id}`,
        }).unwrap();
        if (response.allow === true) {
          if (route && !termsAndConditions) {
            navigate(route, { state: { isAllowed: true } });
          } else if (termsAndConditions) {
            setIsTermsModalOpen(true);
          }
        } else {
          setIsWarningModalOpen(true);
          setResponse(response);
        }
      } else {
        if (termsAndConditions) {
          setIsTermsModalOpen(true);
        } else {
          setIsModalOpen(true);
          setAgreedToTerms(true);
        }
      }
    } catch {
      handleError();
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeTermsModal = () => {
    setIsTermsModalOpen(false);
  };

  const handleAgreeToTerms = () => {
    if (!FormComponent) {
      if (route) {
        navigate(route, { state: { isAllowed: true } });
      } else {
        navigate(onModalSubmitroute, { state: { isAllowed: true } });
      }
    } else {
      setAgreedToTerms(true);
      setIsTermsModalOpen(false);
      setIsModalOpen(true);
    }
  };

  const handleFormSuccess = (data) => {
    if (onModalSubmitroute) {
      if (data) {
        navigate(onModalSubmitroute, { state: data });
      } else {
        navigate(onModalSubmitroute);
      }
    }
  };
  const handleCloseWarning = () => {
    setIsWarningModalOpen(false);
  };
  return (
    <div className={styles['application-card']}>
      {isLoading ? <Loader backdrop /> : null}
      {disable && <div className={styles['backdrop']} />}
      <div className={styles['image-container']}>
        <img src={getSvgByType(applicationImage)} alt={type} />
      </div>

      <div className={styles['content']}>
        <div className={styles['subContent']}>
          <h3 className={styles.title}>{applicationName}</h3>
          <p className={styles.desc}>{smallDescription}</p>
        </div>

        {url ? (
          <a
            className={`${styles.link} ${disable ? styles.disabled : ''}`}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className={`${disable ? styles['disabled-text'] : ''}`}>
              apply now
            </p>
          </a>
        ) : (
          <button
            className={`${styles.link} ${disable ? styles.disabled : ''}`}
            onClick={handleApplyNowClick}
            disabled={disable}
          >
            <p className={`${disable ? styles['disabled-text'] : ''}`}>
              apply now
            </p>
          </button>
        )}
      </div>

      {termsAndConditions && (
        <Modal
          isOpen={isTermsModalOpen}
          onClose={closeTermsModal}
          title={applicationName}
          classname={styles['application-modal']}
        >
          <div className={styles['terms-content']}>
            <div dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
          </div>

          <div className="form-footer">
            <Button
              onClick={closeTermsModal}
              label={appConstants.CANCEL}
              buttonType="button"
            />
            <Button
              onClick={handleAgreeToTerms}
              label="I agree"
              variant={appConstants.VARIANT.DANGER}
              type={appConstants.TYPE.CHECK}
              buttonType="submit"
            />
          </div>
        </Modal>
      )}

      <Modal
        isOpen={isModalOpen && agreedToTerms}
        onClose={closeModal}
        title={applicationName}
        classname={styles['application-modal']}
      >
        {FormComponent ? (
          <FormComponent
            onSuccess={handleFormSuccess}
            handleClose={closeModal}
          />
        ) : (
          <div>No form available.</div>
        )}
      </Modal>
      <SuccessModal
        isOpen={isWarningModalOpen}
        onClose={handleCloseWarning}
        text="You are not allowed to apply!"
        subText={response?.message}
        isSuccess={false}
      />
    </div>
  );
};

export default ApplicationCard;
