import React from 'react';
import styles from './RadioGroup.module.scss';
import Label from '../label/Label';

export const RadioButton = ({ label, name, value, checked, onChange }) => (
  <div className={styles['radio-button']}>
    <input
      type="radio"
      id={value}
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
    />
    <label htmlFor={value} className={styles['radio-label']}>
      {label}
    </label>
  </div>
);

const RadioGroup = ({
  name,
  selectedValue,
  onChange,
  label,
  options,
  error,
}) => (
  <div className={styles['radio-group']}>
    {label ? <Label text={label} /> : null}
    <div className={styles['options-wrapper']}>
      {options.map((option) => (
        <RadioButton
          key={option.value}
          label={option.label}
          name={name}
          value={option.value}
          checked={selectedValue === option.value}
          onChange={onChange}
        />
      ))}
    </div>
    <p className={styles['error-message']}>{error}</p>
  </div>
);

export default RadioGroup;
