import React from 'react';
import styles from './PendingRequestPopup.module.scss';
import Title from '../../../../../components/atoms/title/Title';
import Tag from '../../../../../components/atoms/tag/Tag';
import Accordion from '../../../../../components/molecules/accordion/Accordion';
import { getSvgByType } from '../../../../../app/helpers/forms/GetSvgByType';
import { formatDateTime } from '../../../../../app/helpers/forms/formatDate';
import appConstants from '../../../../../app/constant/constants/appConstants';
import _endpoints from '../../../../../app/constant/endpoints/_endpoints';
import { useGetQuery } from '../../../../../services/apiCall';
import Loader from '../../../../../components/molecules/new-table/atoms/loader/Loader';
import DocumentView from '../../../../../components/atoms/document-view/DocumentView';
const requestApi = _endpoints.student.getRequestById;

const PendingRequestPopup = ({ data }) => {
  const { data: requestData, isLoading: requestLoading } = useGetQuery(
    {
      api: requestApi,
      params: [data?.id],
    },
    { skip: !data?.id }
  );
  if (requestLoading) {
    return <Loader />;
  }

  const customizedTitles = [
    {
      key: requestData?.request?.course?.courseCode,
      title: 'Course Code',
    },
    {
      key: requestData?.request?.section?.sectionName,
      title: 'Section',
    },
    {
      key: requestData?.request?.termName,
      title: requestData?.request?.requestType == 0 ? 'Appeal' : 'Term',
    },
    {
      key: requestData?.section?.instructorName,
      title: 'Instructor',
    },

    {
      key: requestData?.request?.appealType,
      title: 'Nature of Appeal',
    },
    {
      key: requestData?.request?.mobileNumber,
      title: 'Mobile',
    },
    {
      key: requestData?.request?.newGrade,
      color: 'green',
      title: 'New Grade',
    },
    {
      key:
        requestData?.request?.requestType == 6
          ? requestData?.request?.otherReasons
          : null,
      color: 'red',
      title: 'Old Grade',
    },
    {
      key: requestData?.request?.numericGrade,
      color: 'red',
      title: 'Current Grade (Numeric)',
    },
    {
      key: requestData?.request?.numericGrade,
      color: 'red',
      title: 'Current Grade (Letter)',
    },
    {
      key: requestData?.request?.makeupDate,
      title: 'Make Exam Date and Time',
      dateTime: true,
    },
    {
      key: requestData?.request?.pendingCourseWork,
      title:
        requestData?.request?.requestType == 0
          ? 'Condition for next semester'
          : 'Pending Course Work',
    },
    {
      key: requestData?.request?.withdrawlReason,
      title: 'Withdrawal Reason',
    },

    {
      key:
        requestData?.request?.metInstructor == true
          ? 'Yes'
          : requestData?.request?.metInstructor == false
          ? 'No'
          : null,
      title: 'Have you met the student?',
    },
  ];

  const renderTitles = (titles) => {
    return titles
      .filter((item) => item.key)
      .map((item, index) => (
        <div
          key={index}
          style={{ color: item.color }}
          className={styles.customizedTitle}
        >
          <p>{item.title}</p>
          <span> {item.dateTime ? formatDateTime(item.key) : item.key}</span>
        </div>
      ));
  };
  return (
    <div className={styles['request-cont']}>
      <div className={styles['date-cont']}>
        <p>
          Date Created:
          <span> {formatDateTime(requestData?.request?.dateCreated)}</span>{' '}
        </p>
        <Tag
          label={requestData?.request?.statusName}
          variant={requestData?.request?.statusName}
        />
      </div>
      {renderTitles(customizedTitles)?.length > 0 && (
        <div className={styles.customizedTitle_cont}>
          {renderTitles(customizedTitles)}
        </div>
      )}
      {(requestData?.request?.comments ||
        requestData?.request?.otherReasons) && (
        <div className={styles['request-detail']}>
          <Title text={requestData?.request?.comments ? 'Comment' : 'Reason'} />
          <div className={styles['request-subcont']}>
            <img src={getSvgByType('reasonPaper')} />
            <p>
              {requestData?.request?.comments ||
                requestData?.request?.otherReasons}
            </p>
          </div>
        </div>
      )}{' '}
      {requestData?.withdrawalReasons?.length > 0 && (
        <div className={styles['request-detail']}>
          <Title text="Withdrawal Reason" />
          <div className={styles['request-subcont']}>
            <img src={getSvgByType('reasonPaper')} />

            <div>
              {requestData?.withdrawalReasons?.map((r) => {
                return <p>{r.reason}</p>;
              })}
            </div>
          </div>
        </div>
      )}{' '}
      {/* {requestData?.request?.makeupDate && (
        <div className={styles['request-detail']}>
          <Title text={'Makeup Date'} />
          <div className={styles['request-subcont']}>
            <img src={getSvgByType('reasonPaper')} />
            <p>{formatDateTime(requestData?.request?.makeupDate)}</p>
          </div>
        </div>
      )} */}
      {requestData?.files?.length > 0 ? (
        <div className={styles['request-detail']}>
          <Title text="Documents" />

          <div className={styles['documents-container']}>
            {requestData?.files?.map((doc) => {
              return (
                <DocumentView
                  fileName={doc.fileName}
                  fileType={doc.fileType}
                  fileId={doc.id}
                />
              );
            })}
          </div>
        </div>
      ) : (
        ''
      )}
      <div className={styles['request-cont']}>
        <Title text="Request Log" />
        {requestData?.approvalRequest?.map((details) => {
          return (
            details?.showStudent && (
              <Accordion
                title={details.message}
                type={details.approved ? 'approved' : 'notApproved'}
                className={styles['accordion_color']}
                date={details?.dateApproved}
              >
                <div>
                  <div className={styles.detailsCont}>
                    <img
                      src={getSvgByType(appConstants.TYPE.PROFILE_MALE)}
                      alt="profile"
                    />
                    <div>
                      <Title text={details.userName} />
                      <Title text={details.role} variant="sm-blue-bold" />
                    </div>
                  </div>
                  {details?.studentReason && (
                    <div className={styles.detailsCont}>
                      <Title text="Student Reason:" variant="sm" />
                      <Title text={details?.studentReason} variant="sm-blue" />
                    </div>
                  )}
                </div>
              </Accordion>
            )
          );
        })}
      </div>
    </div>
  );
};

export default PendingRequestPopup;
