import { useFormik } from 'formik';
import { usePostMutation } from '../../../../../../../../services/apiCall';
import { handleError } from '../../../../../../../molecules/table/Table';
import _endpoints from '../../../../../../../../app/constant/endpoints/_endpoints';
import { useNavigate } from 'react-router-dom';
import { reRenderHeader } from '../../../../../../../../app/globals/storeSlices/userSlice';
import { useDispatch } from 'react-redux';

const useForm = ({
  selectedCourse,
  termId,
  onSuccess,
  onError,
  getValidationSchema,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [postWithdraw, { isLoading, isSuccess }] = usePostMutation();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const payload = {
      apiUrl: _endpoints.application.withdrawCourse,
      data: {
        ...values,
        courseId: selectedCourse?.courseId,
        sectionId: selectedCourse?.section?.id,
        termId,
      },
    };
    try {
      const response = await postWithdraw(payload).unwrap();

      if (response?.allow) {
        resetForm();
        onSuccess({
          show: true,
          message: 'Submitted Successfully',
          subMessage: 'Your request needs to be verified by the registrar',
        });
        dispatch(reRenderHeader());
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      } else {
        onError({
          show: true,
          message: response?.message,
        });
      }
    } catch (error) {
      handleError(error);
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = {
    termId: '',
    withdrawalReasonId: [],
    reason: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(),
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  return { formik, isLoading, isSuccess };
};

export default useForm;
